export default {
    assemblerLists: 'assembler-lists',
    assembly: 'assembly',
    bazCometTekna: 'bazCometTekna',
    bazQuadra1: 'bazQuadra1',
    bazQuadra2: 'bazQuadra2',
    doors: 'doors',
    eassembly: 'eassembly',
    editUser: 'editUser',
    facades: 'facades',
    features: 'features',
    home: 'home',
    imprint: 'imprint',
    isomat: 'isomat',
    login: 'login',
    messages: 'messages',
    newUser: 'newUser',
    plastics: 'plastics',
    projectGroupDetails: 'projectGroupDetails',
    projectGroups: 'projectGroups',
    projects: 'projects',
    repairs: 'repairs',
    repairsAndProjectsNear: 'repairsAndProjectsNear',
    repairsArchive: 'repairsArchive',
    steel: 'steel',
    tin: 'tin',
    tutorials: 'tutorials',
    userManagement: 'userManagement',
    windows: 'windows',
};
