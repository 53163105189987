

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class InputSelect extends Vue {

    @Prop({ default: null })
    private value!: string | number | null;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: false })
    private error!: boolean;

    private val: string | number | null = null;

    private created() {
        this.val = this.value;
    }

    private emit() {
        this.$emit('input', this.val);
    }

}
