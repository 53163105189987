/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Upload
 */
export interface Upload {
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    projectId?: number;
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    projectGroupId?: number;
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    repairId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Upload
     */
    tutorial?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    size?: number;
    /**
     * 
     * @type {Date}
     * @memberof Upload
     */
    uploadedAt?: Date;
}

export function UploadFromJSON(json: any): Upload {
    return UploadFromJSONTyped(json, false);
}

export function UploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): Upload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectGroupId': !exists(json, 'projectGroupId') ? undefined : json['projectGroupId'],
        'repairId': !exists(json, 'repairId') ? undefined : json['repairId'],
        'tutorial': !exists(json, 'tutorial') ? undefined : json['tutorial'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'uploadedAt': !exists(json, 'uploaded_at') ? undefined : (new Date(json['uploaded_at'])),
    };
}

export function UploadToJSON(value?: Upload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'categoryId': value.categoryId,
        'projectId': value.projectId,
        'projectGroupId': value.projectGroupId,
        'repairId': value.repairId,
        'tutorial': value.tutorial,
        'name': value.name,
        'type': value.type,
        'size': value.size,
        'uploaded_at': value.uploadedAt === undefined ? undefined : (value.uploadedAt.toISOString()),
    };
}


