

import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseDialog from '@/components/dialogs/BaseDialog.vue';
import { InputResponse, Message } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import InputButton from '@/components/input/InputButton.vue';

@Component({
    components: {
        InputButton,
        Loading,
        BaseDialog,
    },
})
export default class MessageDialog extends Vue {

    @Prop({ default: '' })
    private projectName!: string;

    @Prop({ default: '' })
    private recipients!: string;

    @Prop({ required: true })
    private type!: string;

    private message: Message = {};
    private showErrors: boolean = false;
    private allErrors: string = '';

    private submitted: boolean = false;
    private success: boolean = false;

    private mounted() {
        this.message.done = false;
        this.message.to = this.recipients;
        this.message.projectName = this.projectName;
    }

    private async send() {
        try {
            this.submitted = true;
            this.showErrors = false;
            this.message.to = this.recipients;
            this.message.projectName = this.projectName;
            this.message.type = this.type;
            const response: InputResponse = await Api.omb.messagePost(this.message);
            if (response.error) {
                this.submitted = false;
                this.allErrors = '';
                // eslint-disable-next-line guard-for-in,no-restricted-syntax
                for (const problemsKey in response.problems) {
                    this.allErrors.concat(response.problems[problemsKey])
                        .concat('\n');
                }
                this.showErrors = true;
                this.$forceUpdate();
            } else {
                this.success = true;
                await this.delay(1000);
                this.$emit('close');
            }
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

}
