

import { Component, Prop, Vue } from 'vue-property-decorator';
import Collapsable from '@/components/Collapsable.vue';
import { Project, ProjectGroup } from '@/clients/omb-api';
import TableComponent from '@/components/TableComponent.vue';
import Api from '@/assets/ts/Api';
import { ROLE } from '@/assets/ts/enum/role';
import getRole from '@/plugins/roleHandler';
import Loading from '@/components/Loading.vue';

interface TableEntry {
    deleted?: boolean;
    project: Project;
}

@Component({
    components: {
        Loading,
        TableComponent,
        Collapsable,
    },
})
export default class ProjectGroupDetailsAssembly extends Vue {

    @Prop({ required: true })
    private projectGroup!: ProjectGroup;

    private loading = true;

    private projects: Project[] = [];
    private projectEntries: TableEntry[] = [];
    private role: ROLE = ROLE.GUEST;

    private get activeProjects(): Project[] {
        return this.projectEntries.filter((pe) => !pe.deleted)
            .map((pe) => pe.project);
    }

    private get deletedProjects(): Project[] {
        return this.projectEntries.filter((pe) => pe.deleted)
            .map((pe) => pe.project);
    }

    private get isAtLeastPlanner(): boolean {
        return this.role === ROLE.ADMINISTRATOR
            || this.role === ROLE.PLANNING;
    }

    private created() {
        this.loadData();
        this.$root.$on('updateProject', this.updateSingleProject);
    }

    private async loadData() {
        this.loading = true;

        this.role = await getRole();
        this.projects = await Api.omb.getAllProjectsByGroup(this.projectGroup.id || 0);
        this.filterProjects();

        this.loading = false;
        this.$emit('load');
    }

    private filterProjects() {
        let toFilter = this.projects;
        toFilter = toFilter.filter((p) => p.departments && p.departments.toLowerCase()
            .includes('montage'));

        if (!this.isAtLeastPlanner && this.role !== ROLE.PRODUCTION) {
            toFilter = toFilter.filter((p) => p.planned);
        }

        if (this.role === ROLE.EASSEMBLY) {
            toFilter = toFilter.filter((p) => p.departments && p.departments.toLowerCase()
                .includes('e-montage'));
        }

        toFilter = toFilter.filter((p) => !p.archivedAt);

        this.projectEntries = toFilter.map((p) => ({
            deleted: false,
            project: p,
        }));
    }

    private updateSingleProject(project: Project) {
        const index = this.projects.findIndex((p) => p.id === project.id);
        if (index >= 0) {
            this.projects[index] = project;
        } else {
            this.projects.push(project);
        }
        this.filterProjects();
    }

    private onArchive(project: Project) {
        this.filterProjects();
        this.$root.$emit('updateProject', project);
    }

    private onUpdate(project: Project) {
        this.filterProjects();
        this.$root.$emit('updateProject', project);
    }

    private onDelete(projectId: number) {
        const toDelete = this.projectEntries.find((p) => p.project.id === projectId);
        if (toDelete) toDelete.deleted = true;
    }

    private onRecover(project: Project) {
        const toRecover = this.projectEntries.find((p) => p.project.id === project.id);
        if (toRecover) toRecover.deleted = false;
    }

}
