

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class InputDefault extends Vue {

    @Prop({ default: '' })
    private value!: string | number | Date;

    @Prop({ default: false })
    private error!: boolean;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: 'text' })
    private type!: string;

    private get formattedDate(): string | number {
        if (typeof this.value !== 'object') return this.value;
        const date = this.value as Date;
        const year = date.getFullYear();
        const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        return `${year}-${month}-${day}`;
    }

    private onInput(event: Event) {
        const target = event.target as HTMLInputElement;
        if (!target) return;
        if (typeof this.value !== 'object') this.$emit('input', target.value);
        else this.$emit('input', target.valueAsDate);
    }

}
