

import { Component, Prop, Vue } from 'vue-property-decorator';
import { HistoryEntry } from '@/clients/omb-api';
import { HISTORY_EVENT } from '@/assets/ts/enum/history_event';

@Component({
    components: {},
})
export default class History extends Vue {

    @Prop({ default: [] })
    private entries!: HistoryEntry[];

    private HISTORY_EVENT = HISTORY_EVENT;

    private getDateString(historyEntry: HistoryEntry): string {
        if (!historyEntry.createdAt) return '';

        let day = `${historyEntry.createdAt.getDate()}`;
        day = day.length === 1 ? `0${day}` : day;

        let month = `${historyEntry.createdAt.getMonth() + 1}`;
        month = month.length === 1 ? `0${month}` : month;

        const year = historyEntry.createdAt.getFullYear();

        return `${day}.${month}.${year}`;
    }

}
