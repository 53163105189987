/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Repair,
    RepairFromJSON,
    RepairFromJSONTyped,
    RepairToJSON,
} from './';

/**
 * 
 * @export
 * @interface RepairSearchResult
 */
export interface RepairSearchResult {
    /**
     * 
     * @type {Array<Repair>}
     * @memberof RepairSearchResult
     */
    repairs: Array<Repair>;
    /**
     * 
     * @type {boolean}
     * @memberof RepairSearchResult
     */
    hasMore?: boolean;
}

export function RepairSearchResultFromJSON(json: any): RepairSearchResult {
    return RepairSearchResultFromJSONTyped(json, false);
}

export function RepairSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairs': ((json['repairs'] as Array<any>).map(RepairFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
    };
}

export function RepairSearchResultToJSON(value?: RepairSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repairs': ((value.repairs as Array<any>).map(RepairToJSON)),
        'hasMore': value.hasMore,
    };
}


