

import { Component, Vue } from 'vue-property-decorator';
import Api from '@/assets/ts/Api';
import availableRoutes from '@/plugins/availableRoutes';
import { Credentials } from '@/clients/omb-api';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import InputButton from '@/components/input/InputButton.vue';

@Component({
    components: { InputButton },
})
export default class Login extends Vue {

    private username: string = '';
    private password: string = '';
    private displayError: boolean = false;

    private created() {
        this.checkLogin();
    }

    private async checkLogin() {
        try {
            const role = await getRole();
            if ([ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION].includes(role)) {
                // already logged in
                this.$router.push({ name: availableRoutes.features });
            }
        } catch (e: any) {
            // do nothing as 401 or 403 is expected
        }
    }

    private async login() {
        try {
            const credentials: Credentials = {};
            credentials.username = this.username;
            credentials.password = this.password;
            const token: string = await Api.omb.loginPost(credentials);
            Api.initServices(token);
            this.$router.push({ name: availableRoutes.features });
        } catch (e: any) {
            if (e.status === 403 || e.status === 401) {
                this.displayError = true;
            }
            console.log(e);
        }
    }

    private goToImprint() {
        this.$router.push({ name: availableRoutes.imprint });
    }
}
