

import { Component, Prop, Vue } from 'vue-property-decorator';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import InputButton from '@/components/input/InputButton.vue';
import Loading from '@/components/Loading.vue';
import Collapsable from '@/components/Collapsable.vue';
import { Upload, UploadCategory } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Uploader from '@/assets/ts/uploader';
import UploadCard from '@/components/UploadCard.vue';

interface CategorizedUploads {
    category: string;
    uploads: { selected: boolean; upload: Upload }[];
}

@Component({
    components: {
        UploadCard,
        Collapsable,
        Loading,
        InputButton,
        InputCheckbox,
    },
})
export default class ProjectGroupDetailsDocuments extends Vue {

    @Prop({ default: [] })
    private uploads!: Upload[];

    @Prop({ required: true })
    private projectGroupName!: string;

    private ready = false;

    private uploader!: Uploader;

    private uploadCategories: UploadCategory[] = [];
    private categorizedUploads: CategorizedUploads[] = [];
    private generatingZip = false;

    private get selectedUploads(): Upload[] {
        return this.uploads.filter((pgu) => this.categorizedUploads.find((cu) => cu.uploads.find((u) => u.upload.id === pgu.id && u.selected)));
    }

    private async created() {
        this.uploader = await Uploader.create();
        this.uploadCategories = await Api.omb.getAllUploadCategories();
        this.loadCategorizedUploads();
        this.ready = true;
    }

    private loadCategorizedUploads() {
        this.categorizedUploads = [];

        this.uploadCategories.forEach((category) => {
            this.categorizedUploads.push({
                category: category.name || '',
                uploads: this.uploads.filter((u) => u.categoryId === category.id)
                    .map((u) => ({
                        selected: false,
                        upload: u,
                    })),
            });
        });

        this.categorizedUploads.push({
            category: 'Weitere Dokumente',
            uploads: this.uploads.filter((u) => !u.categoryId || !this.uploadCategories.find((c) => c.id === u.categoryId))
                .map((u) => ({
                    selected: false,
                    upload: u,
                })),
        });

        this.categorizedUploads = this.categorizedUploads.filter((tr) => tr.uploads.length);
    }

    private downloadSelected() {
        this.generatingZip = true;
        this.uploader.downloadMultiple(this.selectedUploads);
        this.unselectAllDownloads();
        this.generatingZip = false;
    }

    private selectAllDownloads() {
        this.categorizedUploads.forEach((cu) => cu.uploads.forEach((u) => u.selected = true));
    }

    private unselectAllDownloads() {
        this.categorizedUploads.forEach((cu) => cu.uploads.forEach((u) => u.selected = false));
    }

}
