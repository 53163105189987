/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectGroup,
    ProjectGroupFromJSON,
    ProjectGroupFromJSONTyped,
    ProjectGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectGroupSearchResult
 */
export interface ProjectGroupSearchResult {
    /**
     * 
     * @type {Array<ProjectGroup>}
     * @memberof ProjectGroupSearchResult
     */
    projectGroups: Array<ProjectGroup>;
    /**
     * 
     * @type {number}
     * @memberof ProjectGroupSearchResult
     */
    totalPages: number;
}

export function ProjectGroupSearchResultFromJSON(json: any): ProjectGroupSearchResult {
    return ProjectGroupSearchResultFromJSONTyped(json, false);
}

export function ProjectGroupSearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGroupSearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectGroups': ((json['projectGroups'] as Array<any>).map(ProjectGroupFromJSON)),
        'totalPages': json['totalPages'],
    };
}

export function ProjectGroupSearchResultToJSON(value?: ProjectGroupSearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectGroups': ((value.projectGroups as Array<any>).map(ProjectGroupToJSON)),
        'totalPages': value.totalPages,
    };
}


