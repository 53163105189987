

import { Component, Prop, Vue } from 'vue-property-decorator';
import clone from 'clone';

@Component({
    components: {},
})
export default class InputPriority extends Vue {

    @Prop({ default: 0 })
    private value!: number;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: false })
    private error!: boolean;

    @Prop()
    private errorMessage!: string;

    private val: number = this.value;
    private open = false;

    private options: { color: string; value: number }[] = [
        {
            color: 'gray-100',
            value: 0,
        },
        {
            color: 'success',
            value: 1,
        },
        {
            color: 'warning',
            value: 2,
        },
        {
            color: 'critical',
            value: 3,
        },
    ];

    private get colorByVal() {
        const option = this.options.find((o) => o.value === this.val);
        if (option) return option.color;
        return 'white';
    }

    private created() {
        this.val = clone(this.value);
    }

    private select(priority: number) {
        this.val = priority;
        this.open = false;
        this.emit();
    }

    private emit() {
        this.$emit('input', this.val);
    }

}
