/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Upload,
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectGroup
 */
export interface ProjectGroup {
    /**
     * 
     * @type {number}
     * @memberof ProjectGroup
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    city?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectGroup
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectGroup
     */
    lng?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    description?: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof ProjectGroup
     */
    managers?: Array<User>;
    /**
     * 
     * @type {Array<Upload>}
     * @memberof ProjectGroup
     */
    uploads?: Array<Upload>;
    /**
     * 
     * @type {Date}
     * @memberof ProjectGroup
     */
    createdAt?: Date;
}

export function ProjectGroupFromJSON(json: any): ProjectGroup {
    return ProjectGroupFromJSONTyped(json, false);
}

export function ProjectGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lng': !exists(json, 'lng') ? undefined : json['lng'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'managers': !exists(json, 'managers') ? undefined : ((json['managers'] as Array<any>).map(UserFromJSON)),
        'uploads': !exists(json, 'uploads') ? undefined : ((json['uploads'] as Array<any>).map(UploadFromJSON)),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function ProjectGroupToJSON(value?: ProjectGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'slug': value.slug,
        'name': value.name,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
        'lat': value.lat,
        'lng': value.lng,
        'description': value.description,
        'managers': value.managers === undefined ? undefined : ((value.managers as Array<any>).map(UserToJSON)),
        'uploads': value.uploads === undefined ? undefined : ((value.uploads as Array<any>).map(UploadToJSON)),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


