
import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import Api from '@/assets/ts/Api';
import { ProjectGroup, Repair } from '@/clients/omb-api';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import EditRepairDialog from '@/components/dialogs/EditRepairDialog.vue';
import RepairsTable from '@/views/repairs/RepairsTable.vue';
import clone from 'clone';
import InputButton from '@/components/input/InputButton.vue';
import ProjectGroupsAndRepairsMap from '@/components/ProjectGroupsAndRepairsMap.vue';

@Component({
    components: {
        ProjectGroupsAndRepairsMap,
        InputButton,
        RepairsTable,
        EditRepairDialog,
        Loading,
    },
})
export default class Repairs extends Vue {

    private ROLE = ROLE;
    private role = ROLE.GUEST;

    private isArchive = true;
    private searchTerm = '';
    private page = 0;
    private hasMore = false;

    private repairs: Repair[] = [];
    private repairsWithGeodata: Repair[] = [];
    private projectGroups: ProjectGroup[] = [];

    private title: string = '';
    private currentWeek: number = 0;
    private repairToEdit?: Repair;
    private editDialogVisible: boolean = false;
    private loading: boolean = true;
    private loadingMore = false;
    private mapView = false;

    @Watch('mapView')
    private onMapViewChange(): void {
        this.loadMap();
    }

    private async created() {
        await this.init();
    }

    private mounted() {
        this.$root.$on('openNewRepairDialog', this.showEditDialog);
    }

    private destroyed() {
        this.$root.$off('openNewRepairDialog', this.showEditDialog);
    }

    private async init() {
        this.loading = true;

        if (this.$route.meta?.label) this.title = this.$route.meta.label;
        this.isArchive = this.$route.name === availableRoutes.repairsArchive;

        this.currentWeek = moment()
            .isoWeek();
        await this.loadUserData();
        this.projectGroups = await Api.omb.getAllProjectGroups();

        this.loading = false;

        await this.loadRepairs();
    }

    private async loadUserData() {
        this.role = await getRole();
    }

    private search(): void {
        this.searchTerm = this.searchTerm.trim();
        this.repairs = [];
        this.page = 0;
        this.loadRepairs();
    }

    private async loadRepairs(reloadAll?: boolean) {
        this.loadingMore = true;

        try {
            if (reloadAll) {
                let repairs: Repair[] = [];
                let hasMore = false;
                for (let i = 0; i < this.page; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const searchResult = await Api.omb.searchRepairs(this.isArchive, this.searchTerm, i);
                    repairs = repairs.concat(searchResult.repairs);
                    hasMore = !!searchResult.hasMore;
                }
                this.repairs = repairs;
                this.hasMore = hasMore;
            } else {
                const searchResult = await Api.omb.searchRepairs(this.isArchive, this.searchTerm, this.page);
                searchResult.repairs.forEach((repair) => {
                    if (!this.repairs.find((r) => r.id === repair.id)) {
                        this.repairs.push(repair);
                    }
                });
                this.hasMore = !!searchResult.hasMore;
                this.page += 1;
            }
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        } finally {
            this.loadingMore = false;
        }
    }

    private async loadMap(): Promise<void> {
        this.loadingMore = true;
        this.page = 0;
        this.searchTerm = '';
        if (!this.repairsWithGeodata.length) {
            const allRepairs = await Api.omb.getAllRepairs(this.isArchive);
            this.repairsWithGeodata = allRepairs.filter((pg) => pg.lat && pg.lng);
        }
        this.loadingMore = false;
    }

    private onUpdateRepair(repair: Repair): void {
        this.repairs = this.repairs.map((r) => (r.id === repair.id ? repair : r));
        this.loadRepairs(true);
    }

    private showEditDialog(repair?: Repair) {
        this.repairToEdit = repair;
        this.editDialogVisible = true;
    }

    private hideEditDialog() {
        this.editDialogVisible = false;
        this.repairToEdit = undefined;
    }

    private async onDuplicateRepair(repair: Repair) {
        this.hideEditDialog();

        if (this.$route.name !== availableRoutes.repairs) {
            await this.$router.push({ name: availableRoutes.repairs });
            await this.init();
        }

        this.$nextTick(() => {
            const newRepair = clone(repair);
            newRepair.id = undefined;
            newRepair.parts = false;
            newRepair.processing = false;
            newRepair.completed = false;
            newRepair.history = [];
            newRepair.createdAt = undefined;
            newRepair.archive = false;
            newRepair.archivedAt = undefined;

            if (repair.uploads) {
                const newUploadPromises = repair.uploads.map((u) => Api.omb.duplicateUpload(u.id || ''));
                Promise.all(newUploadPromises)
                    .then((uploads) => {
                        newRepair.uploads = uploads;
                        this.showEditDialog(newRepair);
                    });
            } else {
                this.showEditDialog(repair);
            }
        });
    }

    private onRepairUpdate(repair: Repair): void {
        this.repairs = this.repairs.map((r) => (r.id === repair.id ? repair : r));
    }

    private removeRepairAndReloadCurrentPage(repair: Repair): void {
        this.repairs = this.repairs.filter((r) => r.id !== repair.id);
        this.loadRepairs(true);
    }

}
