

import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import Api from '@/assets/ts/Api';
import moment, { Moment } from 'moment';
import {
    HistoryEntry,
    InputResponse,
    Project,
    ProjectGroup,
    Upload, User,
} from '@/clients/omb-api';
import MessageDialog from '@/components/dialogs/MessageDialog.vue';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import { ROLE } from '@/assets/ts/enum/role';
import InputButton from '@/components/input/InputButton.vue';
import InputRadio from '@/components/input/InputRadio.vue';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import EditProjectDialog from '@/components/dialogs/EditProjectDialog.vue';
import clone from 'clone';
import History from '@/components/History.vue';
import SimpleUploadDownloader from '@/components/SimpleUploadDownloader.vue';
import AddUploadsDialog from '@/components/dialogs/AddUploadsDialog.vue';
import { MESSAGE_TYPE } from '@/assets/ts/enum/message_type';
import { v4 as uuidv4 } from 'uuid';

@Component({
    components: {
        AddUploadsDialog,
        SimpleUploadDownloader,
        History,
        EditProjectDialog,
        InputCheckbox,
        InputRadio,
        InputButton,
        Loading,
        MessageDialog,
    },
})
export default class TableComponent extends Vue {

    private ROLE = ROLE;
    private MESSAGE_TYPE = MESSAGE_TYPE;

    @Prop({ required: true })
    private projectGroups!: ProjectGroup[];

    @Prop({ required: true })
    private projects!: Project[];

    @Prop({ default: ROLE.GUEST })
    private role!: ROLE;

    @Prop({ default: false })
    private displaysDeletedProjects!: boolean;

    @Prop({ default: false })
    private departmentView!: boolean;

    @Prop({ default: false })
    private assemblyView!: boolean;

    @Prop({ default: false })
    private archive!: boolean;

    @Prop({ default: false })
    private assemblyCheckboxes!: boolean;

    @Prop({ default: false })
    private hideGroupName!: boolean;

    @Prop({ default: true })
    private stickyHeader!: boolean;

    private id = uuidv4();

    private projectToEdit: Project | null = null;
    private uploadsToEdit: Upload[] = [];
    private showEditDialog: boolean = false;

    private projectToAddUploads: Project | null = null;

    private errors: { projectId: number; key: string; message: string }[] = [];

    private currentScroll = 0;
    private currentMouse = 0;

    private histories: Map<number, HistoryEntry[]> = new Map<number, HistoryEntry[]>();
    private uploads: Map<number, Upload[]> = new Map<number, Upload[]>();

    private messageDialogVisible: boolean = false;
    private messageProjectName: string = '';
    private messageProjectManagers: string = '';

    private commentBeforeUpdate?: string;
    private commentUpdateSuccess = false;
    private commentUpdateError = false;

    private storagePlaceBeforeUpdate?: string;
    private storagePlaceUpdateSuccess = false;
    private storagePlaceUpdateError = false;

    private extended: number | null = null;
    private submitting: number[] = [];
    private success: number[] = [];

    private loading = true;

    private headerTranslateY = 0;

    private get isAssemblyOrEAssembly(): boolean {
        return this.role === ROLE.ASSEMBLY
            || this.role === ROLE.EASSEMBLY;
    }

    private get isAtLeastPlanner(): boolean {
        return this.role === ROLE.ADMINISTRATOR
            || this.role === ROLE.PLANNING;
    }

    private get canToggleOperating(): boolean {
        return this.role === ROLE.ADMINISTRATOR
            || this.role === ROLE.PLANNING
            || this.role === ROLE.EASSEMBLY;
    }

    private isProjectEditDisabled(project: Project): boolean {
        return !project.planned && !this.isAtLeastPlanner;
    }

    private isProductionProject(project: Project): boolean {
        return !!project.departments && project.departments.toLowerCase()
            .includes('abteilung');
    }

    private isProjectCompleted(project: Project): boolean {
        return ((!project.departments || !project.departments.toLowerCase()
                .includes('montage')) && project.completed)
            || (project.departments && project.departments.toLowerCase()
                .includes('e-montage') && project.assemblyComplete && project.operating)
            || (project.departments && project.departments.toLowerCase()
                .includes('montage') && !project.departments.toLowerCase()
                .includes('e-montage') && project.assemblyComplete) as boolean;
    }

    private getProjectColor(project: Project): string {
        if (project.planned && project.noMaterial && !this.isProjectCompleted(project)) return 'critical';
        if (project.planned && project.purchasedMaterial && !this.isProjectCompleted(project)) return 'warning';
        if (!project.planned && !this.isProjectCompleted(project)) return 'tertiary';
        if (this.isProjectCompleted(project)) return 'success';
        return '';
    }

    private mounted() {
        this.$emit('startedLoading');

        const appBody = document.getElementById('scrollableAppBody');
        if (appBody !== null) {
            appBody.addEventListener('scroll', this.onscroll);
        }
        this.$nextTick(() => {
            const table = document.getElementById(`project_table_${this.id}`);
            if (table !== null) {
                table.addEventListener('mousedown', (e) => {
                    this.handleMouseDown(e);
                });
            }
        });

        const textareas = document.querySelectorAll('.table-component textarea');
        textareas.forEach((ta) => this.setTextareaHeight(ta as HTMLTextAreaElement));

        this.loading = false;
        this.$emit('finishedLoading');
    }

    private destroyed() {
        const appBody = document.getElementById('scrollableAppBody');
        if (appBody) {
            appBody.removeEventListener('scroll', this.onscroll);
        }
        const table = document.getElementById(`project_table_${this.id}`);
        if (table) {
            table.removeEventListener('mousedown', (e) => {
                this.handleMouseDown(e);
            });
        }
    }

    private getMadeProgress(project: Project) {
        return project.cut || project.completed || project.connectionsComplete || project.glasComplete || project.assemblyComplete || project.operating;
    }

    private getProjectGroupName(project: Project) {
        if (!this.projectGroups) return `${project.projectGroupId} (ID)`;

        const projectGroup = this.projectGroups.find((g) => g.id === project.projectGroupId);
        return projectGroup ? projectGroup.name || '' : `${project.projectGroupId} (ID)`;
    }

    private getProjectGroupManagers(project: Project): User[] {
        if (!this.projectGroups) return [];

        const projectGroup = this.projectGroups.find((g) => g.id === project.projectGroupId);
        return projectGroup && projectGroup.managers ? projectGroup.managers : [];
    }

    private assemblyCheckboxesEnabled(project: Project) {
        return (!project.departments || !project.departments.toLowerCase()
                .includes('abteilung') || project.completed)
            && [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY].includes(this.role)
            && project.planned;
    }

    private getError(project: Project, key: string) {
        const error = this.errors.find((e) => e.projectId === project.id && e.key === key);
        if (error) return error.message;
        return undefined;
    }

    private edit(project: Project) {
        this.projectToEdit = clone(project);
        this.uploadsToEdit = this.uploads.get(project.id || 0) || [];
        this.showEditDialog = true;
    }

    private hideEditDialog() {
        this.showEditDialog = false;
    }

    private async deleteProject(projectId: number) {
        try {
            await Api.omb.projectIdDelete(projectId);
            this.$emit('deleted', projectId);
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private onUndoClick(project: Project) {
        if (this.displaysDeletedProjects) {
            this.recoverProject(project);
        } else {
            this.resetProject(project);
        }
    }

    private async recoverProject(project: Project) {
        try {
            if (project.id !== undefined) {
                await Api.omb.projectIdRecoverPut(project.id);
                this.$emit('recover', project);
            }
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private resetProject(project: Project) {
        if (!project.id) return;

        project.cut = false;
        project.completed = false;
        project.connectionsComplete = false;
        project.glasComplete = false;
        project.assemblyComplete = false;
        project.operating = false;
        project.durationInstallation = undefined;
        project.archivedAt = undefined;

        Api.omb.projectPost(project)
            .then((response) => {
                if (!response.error) {
                    this.$root.$emit('updateProject', project);
                }
            });
    }

    private calculateDurations(project: Project) {
        if (project.beginProduction && project.beginInstallation) {
            const beginProduction: Moment = moment();
            beginProduction.year(Number.parseInt(project.beginProduction.split('/')[1], 10));
            beginProduction.isoWeek(Number.parseInt(project.beginProduction.split('/')[0], 10));
            beginProduction.isoWeekday(1);

            const beginInstallation: Moment = moment();
            beginInstallation.year(Number.parseInt(project.beginInstallation.split('/')[1], 10));
            beginInstallation.isoWeek(Number.parseInt(project.beginInstallation.split('/')[0], 10));
            beginInstallation.isoWeekday(1);

            const now: Moment = moment();
            if (beginProduction.isValid() && beginInstallation.isValid() && project.beginProduction.split('/').length === 2 && project.beginInstallation.split('/').length === 2) {
                project.durationProduction = beginInstallation.diff(beginProduction, 'weeks');
                if (moment(beginInstallation)
                        .add(project.durationInstallation, 'weeks')
                        .isBefore(now)
                    && project.departments && project.departments.toLowerCase()
                        .includes('montage')) {
                    project.durationInstallation = now.diff(beginInstallation, 'weeks') + 1;
                }
            } else {
                project.durationProduction = 0;
                console.log('could not read beginProduction and/or beginInstallation, therefore setting durationProduction to 0.');
            }
        }
    }

    private async toggleExtendedRow(projectId: number) {
        this.commentUpdateSuccess = false;
        this.commentUpdateError = false;
        this.storagePlaceUpdateSuccess = false;
        this.storagePlaceUpdateError = false;

        await this.fetchHistory(projectId);
        await this.fetchUploads(projectId);

        if (this.extended === projectId) {
            this.extended = null;
        } else {
            this.extended = projectId;
        }
    }

    private onMaterialClick(project: Project, state: number) {
        const noMaterialBefore = project.noMaterial;
        const purchasedMaterialBefore = project.purchasedMaterial;
        const haveMaterialBefore = project.haveMaterial;

        project.noMaterial = false;
        project.purchasedMaterial = false;
        project.haveMaterial = false;
        if (state === 1) {
            project.noMaterial = true;
        } else if (state === 2) {
            project.purchasedMaterial = true;
        } else if (state === 3) {
            project.haveMaterial = true;
        }

        Api.omb.projectUpdateMaterial(project.id || 0, state)
            .catch(() => {
                project.noMaterial = noMaterialBefore;
                project.purchasedMaterial = purchasedMaterialBefore;
                project.haveMaterial = haveMaterialBefore;
            });
    }

    private onCoaterClick(project: Project, value: boolean) {
        const toCoaterBefore = project.toCoater;
        const fromCoaterBefore = project.fromCoater;

        let status = 0;
        if (!project.toCoater && value) {
            status = 1;
        } else if (!project.fromCoater && !value) {
            status = 2;
        }

        if (value) {
            project.fromCoater = false;
            project.toCoater = !project.toCoater;
        } else {
            project.toCoater = false;
            project.fromCoater = !project.fromCoater;
        }

        Api.omb.projectUpdateCoater(project.id || 0, status)
            .catch(() => {
                project.toCoater = toCoaterBefore;
                project.fromCoater = fromCoaterBefore;
            });
    }

    private onPlannedClick(project: Project) {
        project.planned = !project.planned;

        Api.omb.projectTogglePlanned(project.id || 0)
            .then(() => {
                this.$root.$emit('updateProject', project);
                this.fetchHistory(project.id || 0, true);
            })
            .catch(() => {
                project.planned = !project.planned;
            });
    }

    private async submitProject(project: Project, collapseRow: boolean) {
        if (!project) return;

        this.errors = this.errors.filter((e) => e.projectId !== project.id);
        this.submitting.push(project.id || 0);

        if (project.beginInstallation && project.beginInstallationNew && project.beginInstallation.includes('/') && project.beginInstallationNew.includes('/')) {
            const biWeek = parseInt(project.beginInstallation.split('/')[0], 10);
            const biYear = parseInt(project.beginInstallation.split('/')[1], 10);
            const biNewWeek = parseInt(project.beginInstallationNew.split('/')[0], 10);
            const biNewYear = parseInt(project.beginInstallationNew.split('/')[1], 10);
            if (biYear > biNewYear || (biYear === biNewYear && biWeek > biNewWeek)) {
                project.beginInstallationNew = project.beginInstallation;
            }
        }

        const response: InputResponse = await Api.omb.projectPost(project);
        if (response.error && response.problems !== undefined) {
            Object.keys(response.problems)
                .forEach((key) => {
                    if (!response.problems) return;
                    this.errors.push({
                        projectId: project.id || 0,
                        key: key,
                        message: response.problems[key] || '',
                    });
                });
        } else if (collapseRow) {
            this.success.push(project.id || 0);
            await this.delay(1000);
            this.success = this.success.filter((s) => s !== project.id);
            this.extended = null;
        }

        this.submitting = this.submitting.filter((s) => s !== project.id);

        this.$root.$emit('updateProject', project);
    }

    private handleMouseDown(e: MouseEvent) {
        const table = document.getElementById(`project_table_${this.id}`);
        if (table !== null) {
            this.currentScroll = table.scrollLeft;
        }
        this.currentMouse = e.clientX;

        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    private handleMouseMove(e: MouseEvent) {
        const table = document.getElementById(`project_table_${this.id}`);
        if (table !== null) {
            table.scrollLeft = this.currentScroll + (this.currentMouse - e.clientX);
        }
    }

    private handleMouseUp() {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
    }

    private toggleCompleted(project: Project) {
        Api.omb.projectToggleCompleted(project.id || 0)
            .then(() => {
                this.$root.$emit('updateProject', project);
                if (project.completed) {
                    this.$emit('toggleComplete', project);
                }
                this.fetchHistory(project.id || 0, true);
            })
            .catch(() => {
                project.completed = !project.completed;
            });
    }

    private toggleCut(project: Project) {
        Api.omb.projectToggleCut(project.id || 0)
            .then(() => {
                this.$root.$emit('updateProject', project);
                this.fetchHistory(project.id || 0, true);
            })
            .catch(() => {
                project.cut = !project.cut;
            });
    }

    private showMessageDialog(project: Project) {
        const projectGroupName = this.getProjectGroupName(project);
        const projectGroupManagers = this.getProjectGroupManagers(project)
            .map((m) => m.abbreviation)
            .join(', ');
        if (projectGroupName && projectGroupManagers) {
            this.messageProjectName = projectGroupName;
            this.messageProjectManagers = projectGroupManagers;
            this.messageDialogVisible = true;
        }
    }

    private onArchive(project: Project) {
        Api.omb.projectToArchive(project.id || 0)
            .then(() => {
                project.archivedAt = new Date();
                this.$emit('archive', project);
            })
            .catch(() => {
                project.archivedAt = undefined;
            });
    }

    private delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    private onTextareaInput(event: Event) {
        if (!event.target) return;

        const target = event.target as HTMLElement;
        this.setTextareaHeight(target);
    }

    private setTextareaHeight(textarea: HTMLElement): void {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }

    private setProjectColour(event: Event, project: Project) {
        if (!event.target) return;

        const target = event.target as HTMLDivElement;
        project.colour = target.innerText;
    }

    private onscroll() {
        this.calculateHeaderTranslateY();
    }

    private calculateHeaderTranslateY() {
        if (!this.stickyHeader) return;

        const table: HTMLElement | null = document.getElementById(`table_${this.id}`);
        const tableHeader: HTMLElement | null = document.getElementById(`table_header_${this.id}`);
        const siteHeader: HTMLElement | null = document.getElementById('header');
        if (table && tableHeader && siteHeader) {
            const translateY = siteHeader.getBoundingClientRect().bottom - table.getBoundingClientRect().top;
            const lowerLimit = 0;
            const upperLimit = table.getBoundingClientRect().height - tableHeader.getBoundingClientRect().height;
            this.headerTranslateY = Math.min(Math.max(translateY, lowerLimit), upperLimit);
        }
    }

    private async duplicateEdit(project: Project) {
        if (!project || !project.id) return;

        this.showEditDialog = false;

        const prj = clone(project);
        prj.id = undefined;

        await this.fetchUploads(project.id || 0);
        if (this.getProjectUploads(project.id || 0).length) {
            const newUploadPromises = this.getProjectUploads(project.id || 0).map((u) => Api.omb.duplicateUpload(u.id || ''));
            Promise.all(newUploadPromises)
                .then((uploads) => {
                    this.projectToEdit = prj;
                    this.uploadsToEdit = uploads as Upload[];
                    this.showEditDialog = true;
                });
        } else {
            this.projectToEdit = prj;
            this.uploadsToEdit = [];
            this.showEditDialog = true;
        }
    }

    private onConnectionsCompleteClick(project: Project) {
        Api.omb.projectToggleConnectionsComplete(project.id || 0)
            .then(() => {
                this.$root.$emit('updateProject', project);
                this.fetchHistory(project.id || 0, true);
            })
            .catch(() => {
                project.connectionsComplete = !project.connectionsComplete;
            });
    }

    private onGlasCompleteClick(project: Project) {
        Api.omb.projectToggleGlasComplete(project.id || 0)
            .then(() => {
                this.$root.$emit('updateProject', project);
                this.fetchHistory(project.id || 0, true);
            })
            .catch(() => {
                project.glasComplete = !project.glasComplete;
            });
    }

    private onAssemblyCompleteClick(project: Project) {
        Api.omb.projectToggleAssemblyComplete(project.id || 0)
            .then(() => {
                this.$root.$emit('updateProject', project);
                if (project.assemblyComplete) {
                    this.$emit('toggleAssemblyComplete', project);
                }
                this.fetchHistory(project.id || 0, true);
            })
            .catch(() => {
                project.assemblyComplete = !project.assemblyComplete;
            });
    }

    private onOperatingClick(project: Project) {
        Api.omb.projectToggleOperating(project.id || 0)
            .then(() => {
                this.$root.$emit('updateProject', project);
                if (project.operating) {
                    this.$emit('toggleOperating', project);
                }
                this.fetchHistory(project.id || 0, true);
            })
            .catch(() => {
                project.operating = !project.operating;
            });
    }

    private openFileUpload(project: Project) {
        this.projectToAddUploads = project;
    }

    private setProjectUploads(uploads: Upload[]) {
        if (!this.projectToAddUploads) return;

        const projectId = this.projectToAddUploads.id || 0;
        if (!projectId) return;

        const currentUploads = this.uploads.get(projectId) || [];
        const newUploads = currentUploads.concat(uploads);

        Api.omb.setProjectUploads(this.projectToAddUploads.id || 0, newUploads)
            .then((saved) => {
                this.uploads.set(projectId, saved);
                this.$forceUpdate();
            });
    }

    private onUpdate(project: Project, uploads: Upload[]) {
        this.$emit('update', project);
        this.uploads.set(project.id || 0, uploads);
        this.fetchHistory(project.id || 0, true);
    }

    private getProjectHistory(projectId: number): HistoryEntry[] {
        if (!this.histories.has(projectId)) return [];
        return this.histories.get(projectId) as HistoryEntry[];
    }

    private async fetchHistory(projectId: number, force?: boolean): Promise<void> {
        if (this.histories.has(projectId) && !force) return;

        const history = await Api.omb.getProjectHistory(projectId);
        history.reverse();
        this.histories.set(projectId, history);
        this.$forceUpdate();
    }

    private getProjectUploads(projectId: number): Upload[] {
        if (!this.uploads.has(projectId)) return [];
        return this.uploads.get(projectId) as Upload[];
    }

    private async fetchUploads(projectId: number): Promise<void> {
        if (this.uploads.has(projectId)) return;

        const uploads = await Api.omb.getProjectUploads(projectId);
        this.uploads.set(projectId, uploads);
    }

    private onCommentFocus(project: Project) {
        this.commentBeforeUpdate = project.comment;
    }

    private onCommentBlur(project: Project) {
        if (project.comment === this.commentBeforeUpdate) return;

        this.commentUpdateSuccess = false;
        this.commentUpdateError = false;
        Api.omb.projectUpdateComment(project.id || 0, project.comment || '')
            .then(() => {
                this.commentUpdateSuccess = true;
                setTimeout(() => this.commentUpdateSuccess = false, 2000);
            })
            .catch(() => {
                this.commentUpdateError = true;
                project.comment = this.commentBeforeUpdate;
            })
            .finally(() => this.commentBeforeUpdate = project.comment);
    }

    private onStoragePlaceFocus(project: Project) {
        this.storagePlaceBeforeUpdate = project.storagePlace;
    }

    private onStoragePlaceBlur(project: Project) {
        if (project.storagePlace === this.storagePlaceBeforeUpdate) return;

        this.storagePlaceUpdateSuccess = false;
        this.storagePlaceUpdateError = false;
        Api.omb.projectUpdateStoragePlace(project.id || 0, project.storagePlace || '')
            .then(() => {
                this.storagePlaceUpdateSuccess = true;
                setTimeout(() => this.storagePlaceUpdateSuccess = false, 2000);
            })
            .catch(() => {
                this.storagePlaceUpdateError = true;
                project.storagePlace = this.storagePlaceBeforeUpdate;
            })
            .finally(() => this.storagePlaceBeforeUpdate = project.storagePlace);
    }
}
