/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HistoryEntry,
    HistoryEntryFromJSON,
    HistoryEntryFromJSONTyped,
    HistoryEntryToJSON,
    Upload,
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Repair
 */
export interface Repair {
    /**
     * 
     * @type {number}
     * @memberof Repair
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Repair
     */
    projectGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    city?: string;
    /**
     * 
     * @type {number}
     * @memberof Repair
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof Repair
     */
    lng?: number;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof Repair
     */
    contactPhone?: string;
    /**
     * 
     * @type {Array<Upload>}
     * @memberof Repair
     */
    uploads?: Array<Upload>;
    /**
     * 
     * @type {Array<User>}
     * @memberof Repair
     */
    managers?: Array<User>;
    /**
     * 
     * @type {User}
     * @memberof Repair
     */
    assembler?: User;
    /**
     * 
     * @type {number}
     * @memberof Repair
     */
    priority?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Repair
     */
    parts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Repair
     */
    processing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Repair
     */
    completed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Repair
     */
    archive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Repair
     */
    deleted?: boolean;
    /**
     * 
     * @type {Array<HistoryEntry>}
     * @memberof Repair
     */
    history?: Array<HistoryEntry>;
    /**
     * 
     * @type {Date}
     * @memberof Repair
     */
    archivedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Repair
     */
    createdAt?: Date;
}

export function RepairFromJSON(json: any): Repair {
    return RepairFromJSONTyped(json, false);
}

export function RepairFromJSONTyped(json: any, ignoreDiscriminator: boolean): Repair {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectGroupId': !exists(json, 'projectGroupId') ? undefined : json['projectGroupId'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': json['name'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lng': !exists(json, 'lng') ? undefined : json['lng'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'contactName': !exists(json, 'contact_name') ? undefined : json['contact_name'],
        'contactPhone': !exists(json, 'contact_phone') ? undefined : json['contact_phone'],
        'uploads': !exists(json, 'uploads') ? undefined : ((json['uploads'] as Array<any>).map(UploadFromJSON)),
        'managers': !exists(json, 'managers') ? undefined : ((json['managers'] as Array<any>).map(UserFromJSON)),
        'assembler': !exists(json, 'assembler') ? undefined : UserFromJSON(json['assembler']),
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'parts': !exists(json, 'parts') ? undefined : json['parts'],
        'processing': !exists(json, 'processing') ? undefined : json['processing'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'archive': !exists(json, 'archive') ? undefined : json['archive'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'history': !exists(json, 'history') ? undefined : ((json['history'] as Array<any>).map(HistoryEntryFromJSON)),
        'archivedAt': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function RepairToJSON(value?: Repair | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectGroupId': value.projectGroupId,
        'number': value.number,
        'name': value.name,
        'scope': value.scope,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
        'lat': value.lat,
        'lng': value.lng,
        'description': value.description,
        'contact_name': value.contactName,
        'contact_phone': value.contactPhone,
        'uploads': value.uploads === undefined ? undefined : ((value.uploads as Array<any>).map(UploadToJSON)),
        'managers': value.managers === undefined ? undefined : ((value.managers as Array<any>).map(UserToJSON)),
        'assembler': UserToJSON(value.assembler),
        'priority': value.priority,
        'parts': value.parts,
        'processing': value.processing,
        'completed': value.completed,
        'archive': value.archive,
        'deleted': value.deleted,
        'history': value.history === undefined ? undefined : ((value.history as Array<any>).map(HistoryEntryToJSON)),
        'archived_at': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


