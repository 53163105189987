

import {
    Component,
    Prop,
    Ref,
    Vue,
} from 'vue-property-decorator';
import BaseDialog from '@/components/dialogs/BaseDialog.vue';
import { DailyWageReport, WeeklyReport } from '@/clients/omb-api';
import Loading from '@/components/Loading.vue';
import InputButton from '@/components/input/InputButton.vue';
import SignaturePad from 'signature_pad';
import Api from '@/assets/ts/Api';
import AlertErrorDetails from '@/components/AlertErrorDetails.vue';
import ErrorUtils from '@/assets/ts/error_utils';

@Component({
    components: {
        AlertErrorDetails,
        InputButton,
        Loading,
        BaseDialog,
    },
})
export default class ExportReportDialog extends Vue {

    @Prop()
    private weeklyReport?: WeeklyReport;

    @Prop()
    private dailyWageReport?: DailyWageReport;

    @Prop({ default: 'Bericht exportieren' })
    private title!: string;

    @Ref('signatureCanvas')
    private signatureCanvas!: HTMLCanvasElement;

    private signaturePad: SignaturePad | null = null;

    private generalError: any | null = null;
    private submitting: boolean = false;
    private success: boolean = false;

    private get hasSignature(): boolean {
        return !this.signaturePad || !this.signaturePad.isEmpty();
    }

    private mounted() {
        this.signaturePad = new SignaturePad(this.signatureCanvas, {
            minWidth: 2,
            maxWidth: 5,
        });
    }

    private close() {
        this.$emit('close');
    }

    private clearPad(): void {
        if (!this.signaturePad) return;
        this.signaturePad.clear();
    }

    private async exportReport(): Promise<void> {
        if (!this.hasSignature || (!this.weeklyReport && !this.dailyWageReport)) return;

        this.submitting = true;

        const successFunc = (report: WeeklyReport | DailyWageReport) => {
            this.success = true;
            this.$emit('update', report);
            this.close();
        };

        const errorFunc = (e: any) => {
            console.error(e);
            this.submitting = false;
            ErrorUtils.wrapError('Bericht exportieren', this.weeklyReport || this.dailyWageReport, e)
                .then((wrapped) => this.generalError = wrapped);
        };

        if (this.weeklyReport) {
            Api.omb.exportWeeklyReport(this.weeklyReport.id || 0, this.signaturePad?.toDataURL())
                .then(successFunc)
                .catch(errorFunc);
        } else if (this.dailyWageReport) {
            Api.omb.exportDailyWageReport(this.dailyWageReport.id || 0, this.signaturePad?.toDataURL())
                .then(successFunc)
                .catch(errorFunc);
        }
    }

}
