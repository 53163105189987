import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Login from '@/views/Login.vue';
import FeatureOverview from '@/views/FeatureOverview.vue';
import availableRoutes from '@/plugins/availableRoutes';
import Messages from '@/views/Messages.vue';
import UserManagement from '@/views/UserManagement.vue';
import Table from '@/views/TableView.vue';
import ImprintPage from '@/views/ImprintPage.vue';
import NewUser from '@/views/NewUser.vue';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import { DEPARTMENT } from '@/assets/ts/enum/department';
import ProjectGroups from '@/views/ProjectGroups.vue';
import ProjectGroupDetails from '@/views/ProjectGroupDetails.vue';
import Repairs from '@/views/Repairs.vue';
import RepairsAndProjectsNear from '@/views/RepairsAndProjectsNear.vue';
import Tutorials from '@/views/Tutorials.vue';
import AssemblerLists from '@/views/AssemblerLists.vue';

Vue.use(VueRouter);

export const routes: RouteConfig[] = [
    {
        path: '/login',
        name: availableRoutes.login,
        component: Login,
        meta: {
            hideHeader: true,
        },
    },
    {
        path: '/funktionen',
        name: availableRoutes.features,
        component: FeatureOverview,
        meta: {
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/',
        name: availableRoutes.home,
        component: Login,
        meta: {
            hideHeader: true,
        },
    },
    {
        path: '/nachrichten',
        name: availableRoutes.messages,
        component: Messages,
        meta: {
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/benutzerverwaltung',
        name: availableRoutes.userManagement,
        component: UserManagement,
        meta: {
            label: 'Benutzerverwaltung',
            feature: true,
            featureIcons: ['users_icon'],
            roles: [ROLE.ADMINISTRATOR],
        },
    },
    {
        path: '/bauvorhaben',
        name: availableRoutes.projectGroups,
        component: ProjectGroups,
        meta: {
            label: 'Bauvorhaben',
            feature: true,
            featureIcons: ['pie_line_graph_desktop_icon'],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/bauvorhaben/:slug',
        name: availableRoutes.projectGroupDetails,
        component: ProjectGroupDetails,
        meta: {
            label: 'Bauvorhaben Detailseite',
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/gesamtplanung',
        name: availableRoutes.projects,
        component: Table,
        meta: {
            label: 'Gesamtplanung',
            feature: true,
            featureIcons: ['plan_icon'],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING],
        },
    },
    {
        path: '/kunststoffabteilung',
        name: availableRoutes.plastics,
        component: Table,
        meta: {
            label: 'Kunststoffabteilung',
            feature: true,
            featureIcons: ['plastics_icon'],
            departments: [DEPARTMENT.PLASTICS],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/stahlabteilung',
        name: availableRoutes.steel,
        component: Table,
        meta: {
            label: 'Stahlabteilung',
            feature: true,
            featureIcons: ['steel_icon'],
            departments: [DEPARTMENT.STEEL],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/tuerenabteilung',
        name: availableRoutes.doors,
        component: Table,
        meta: {
            label: 'Türenabteilung',
            feature: true,
            featureIcons: ['door_icon'],
            departments: [DEPARTMENT.DOORS],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/fassadenabteilung',
        name: availableRoutes.facades,
        component: Table,
        meta: {
            label: 'Fassadenabteilung',
            feature: true,
            featureIcons: ['facade_icon'],
            departments: [DEPARTMENT.FACADES],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/fensterabteilung',
        name: availableRoutes.windows,
        component: Table,
        meta: {
            label: 'Fensterabteilung',
            feature: true,
            featureIcons: ['window_icon'],
            departments: [DEPARTMENT.WINDOWS],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/blechabteilung',
        name: availableRoutes.tin,
        component: Table,
        meta: {
            label: 'Blechabteilung',
            feature: true,
            featureIcons: ['tin_icon'],
            departments: [DEPARTMENT.TIN],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/baz-quadra-1',
        name: availableRoutes.bazQuadra1,
        component: Table,
        meta: {
            label: 'BAZ - Quadra 1',
            feature: true,
            featureIcons: ['door_icon', 'window_icon', 'facade_icon'],
            departments: [DEPARTMENT.DOORS, DEPARTMENT.WINDOWS, DEPARTMENT.FACADES],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/baz-quadra-2',
        name: availableRoutes.bazQuadra2,
        component: Table,
        meta: {
            label: 'BAZ - Quadra 2',
            feature: true,
            featureIcons: ['door_icon', 'window_icon', 'plastics_icon'],
            departments: [DEPARTMENT.DOORS, DEPARTMENT.WINDOWS, DEPARTMENT.PLASTICS],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/baz-comet-tekna',
        name: availableRoutes.bazCometTekna,
        component: Table,
        meta: {
            label: 'BAZ Comet/TeKna',
            feature: true,
            featureIcons: ['steel_icon', 'window_icon', 'facade_icon'],
            departments: [DEPARTMENT.STEEL, DEPARTMENT.WINDOWS, DEPARTMENT.PLASTICS],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/isomat',
        name: availableRoutes.isomat,
        component: Table,
        meta: {
            label: 'Isomat',
            feature: true,
            featureIcons: ['door_icon', 'window_icon', 'facade_icon'],
            departments: [DEPARTMENT.DOORS, DEPARTMENT.WINDOWS, DEPARTMENT.FACADES],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.PRODUCTION],
        },
    },
    {
        path: '/montage',
        name: availableRoutes.assembly,
        component: Table,
        meta: {
            label: 'Montage',
            feature: true,
            featureIcons: ['wrench_icon'],
            departments: [DEPARTMENT.ASSEMBLY],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING],
        },
    },
    {
        path: '/e-montage',
        name: availableRoutes.eassembly,
        component: Table,
        meta: {
            label: 'E-Montage',
            feature: true,
            featureIcons: ['robot_icon'],
            departments: [DEPARTMENT.EASSEMBLY],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/reparaturen',
        name: availableRoutes.repairs,
        component: Repairs,
        meta: {
            label: 'Reparaturen',
            feature: true,
            featureIcons: ['project_blueprint_home_icon'],
            roles: [ROLE.ADMINISTRATOR, ROLE.PRODUCTION, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/reparaturen-archiv',
        name: availableRoutes.repairsArchive,
        component: Repairs,
        meta: {
            label: 'Reparaturenarchiv',
            feature: true,
            featureIcons: ['project_notes_house_icon'],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/in-der-naehe',
        name: availableRoutes.repairsAndProjectsNear,
        component: RepairsAndProjectsNear,
        meta: {
            label: 'Reparaturen & Bauvorhaben in der Nähe',
            feature: false,
            roles: [ROLE.ADMINISTRATOR, ROLE.PRODUCTION, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/impressum',
        name: availableRoutes.imprint,
        component: ImprintPage,
        meta: {
            hideHeader: true,
        },
    },
    {
        path: '/neuer-benutzer',
        name: availableRoutes.newUser,
        component: NewUser,
        meta: {
            roles: [ROLE.ADMINISTRATOR],
        },
    },
    {
        path: '/benutzer-bearbeiten/:userId',
        name: availableRoutes.editUser,
        component: NewUser,
        meta: {
            roles: [ROLE.ADMINISTRATOR],
        },
    },
    {
        path: '/anleitungen',
        name: availableRoutes.tutorials,
        component: Tutorials,
        meta: {
            label: 'Montage-Videos und Anleitungen',
            feature: true,
            featureIcons: ['video_settings'],
            roles: [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
    {
        path: '/monteurliste',
        name: availableRoutes.assemblerLists,
        component: AssemblerLists,
        meta: {
            label: 'Monteurliste',
            feature: true,
            featureIcons: ['task_list_sync_icon'],
            outlineIcons: true,
            roles: [ROLE.ADMINISTRATOR, ROLE.PRODUCTION, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY],
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(async (to: Route, from: Route, next) => {
    const role = await getRole();

    if (to.meta?.roles && !to.meta.roles.includes(role)) {
        if (role === ROLE.GUEST) {
            next({ name: availableRoutes.login });
        } else {
            next({ name: availableRoutes.features });
        }
    }

    next();
});

export default router;
