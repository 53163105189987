

import { Component, Vue } from 'vue-property-decorator';
import availableRoutes from '@/plugins/availableRoutes';

@Component({
    components: {},
})
export default class ImprintPage extends Vue {

    private availableRoutes = availableRoutes;

}
