

import { Component, Vue } from 'vue-property-decorator';
import TutorialsFiles from '@/views/tutorials/TutorialsFiles.vue';

@Component({
    components: {
        TutorialsFiles,
    },
})
export default class Tutorials extends Vue {

    private get title(): string {
        return this.$route.meta?.label || '';
    }

}
