
import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';

@Component({})
export default class Pagination extends Vue {

    @Prop({ default: 1 })
    private maxPageProp!: number;

    @Prop({ default: 1 })
    private currentPageProp!: number;

    private maxPage: number = 1;
    private currentPage: number = 1;

    private mounted() {
        this.maxPage = this.maxPageProp;
        this.currentPage = this.currentPageProp;
    }

    private updated() {
        this.maxPage = this.maxPageProp;
        this.currentPage = this.currentPageProp;
    }

    private previousPage() {
        this.currentPage -= 1;
        this.$emit('previousPage');
    }

    private nextPage() {
        this.currentPage += 1;
        this.$emit('nextPage');
    }

    private loadPage(page: number) {
        this.currentPage = page;
        this.$emit('loadPage', page);
    }
}
