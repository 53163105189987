
import { Component, Vue, Watch } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import CookieMessage from '@/components/CookieMessage.vue';

@Component({
    components: {
        CookieMessage,
        Header,
    },
})
export default class App extends Vue {

    private cookieConsent: boolean = false;

    @Watch('$route')
    private async onRouteChange() {
        const element1 = document.getElementById('scrollableAppBodyNoHeader');
        const element2 = document.getElementById('scrollableAppBody');
        if (element1 !== null) {
            element1.scrollTop = 0;
        }
        if (element2 !== null) {
            element2.scrollTop = 0;
        }
    }

    private created() {
        this.onRouteChange();
        document.title = 'Oltmanns Metallbau';
        if (localStorage.getItem('OMB_COOKIE_CONSENT') === 'TRUE') {
            this.cookieConsent = true;
        }
        this.evaluateBodyScrollbar();
        window.addEventListener('resize', this.evaluateBodyScrollbar);
    }

    private evaluateBodyScrollbar() {
        const body = document.getElementById('scrollableAppBodyNoHeader');
        if (body !== null) {
            if ((body.scrollHeight - 6) > window.innerHeight) {
                body.style.overflowY = 'auto';
            } else {
                body.style.overflowY = 'hidden';
            }
        }
    }
}
