/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Credentials,
    CredentialsFromJSON,
    CredentialsToJSON,
    DailyWageReport,
    DailyWageReportFromJSON,
    DailyWageReportToJSON,
    HistoryEntry,
    HistoryEntryFromJSON,
    HistoryEntryToJSON,
    InputResponse,
    InputResponseFromJSON,
    InputResponseToJSON,
    Message,
    MessageFromJSON,
    MessageToJSON,
    Project,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectGroup,
    ProjectGroupFromJSON,
    ProjectGroupToJSON,
    ProjectGroupSearchResult,
    ProjectGroupSearchResultFromJSON,
    ProjectGroupSearchResultToJSON,
    Repair,
    RepairFromJSON,
    RepairToJSON,
    RepairSearchResult,
    RepairSearchResultFromJSON,
    RepairSearchResultToJSON,
    Upload,
    UploadFromJSON,
    UploadToJSON,
    UploadAssemblerList,
    UploadAssemblerListFromJSON,
    UploadAssemblerListToJSON,
    UploadCategory,
    UploadCategoryFromJSON,
    UploadCategoryToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserPage,
    UserPageFromJSON,
    UserPageToJSON,
    WeeklyReport,
    WeeklyReportFromJSON,
    WeeklyReportToJSON,
} from '../models';

export interface AddTutorialsUploadsRequest {
    upload: Array<Upload>;
}

export interface AddUploadRequest {
    file?: Blob;
}

export interface AddUploadAssemblerListRequest {
    week: number;
    year: number;
    file?: Blob;
}

export interface DeleteDailyWageReportRequest {
    reportId: number;
}

export interface DeleteProjectGroupRequest {
    slug: string;
}

export interface DeleteTutorialEntryRequest {
    id: string;
}

export interface DeleteUploadAssemblerListRequest {
    uploadId: string;
}

export interface DeleteWeeklyReportRequest {
    reportId: number;
}

export interface DuplicateUploadRequest {
    id: string;
}

export interface ExportDailyWageReportRequest {
    reportId: number;
    body?: string;
}

export interface ExportWeeklyReportRequest {
    reportId: number;
    body?: string;
}

export interface GetAllDailyWageReportsByProjectGroupRequest {
    id: number;
}

export interface GetAllDailyWageReportsByRepairRequest {
    repairId: number;
}

export interface GetAllProjectsByGroupRequest {
    id: number;
}

export interface GetAllRepairsRequest {
    isArchived?: boolean;
}

export interface GetAllWeeklyReportsByProjectGroupRequest {
    id: number;
}

export interface GetProjectGroupBySlugRequest {
    slug: string;
}

export interface GetProjectGroupsNearRequest {
    lat: number;
    lng: number;
}

export interface GetProjectHistoryRequest {
    projectId: number;
}

export interface GetProjectUploadsRequest {
    projectId: number;
}

export interface GetProjectsByGroupsRequest {
    requestBody?: Array<number>;
}

export interface GetRepairRequest {
    id: number;
}

export interface GetRepairsNearRequest {
    lat: number;
    lng: number;
}

export interface GetUploadFileRequest {
    id: string;
}

export interface LoginPostRequest {
    credentials?: Credentials;
}

export interface MessagePostRequest {
    message?: Message;
}

export interface PatchMessageDoneRequest {
    id: number;
}

export interface ProjectDepartmentGetRequest {
    department: string;
}

export interface ProjectIdDeleteRequest {
    id: number;
}

export interface ProjectIdRecoverPutRequest {
    id: number;
}

export interface ProjectPostRequest {
    project?: Project;
}

export interface ProjectToArchiveRequest {
    id: number;
}

export interface ProjectToggleAssemblyCompleteRequest {
    id: number;
}

export interface ProjectToggleCompletedRequest {
    id: number;
}

export interface ProjectToggleConnectionsCompleteRequest {
    id: number;
}

export interface ProjectToggleCutRequest {
    id: number;
}

export interface ProjectToggleGlasCompleteRequest {
    id: number;
}

export interface ProjectToggleOperatingRequest {
    id: number;
}

export interface ProjectTogglePlannedRequest {
    id: number;
}

export interface ProjectUpdateCoaterRequest {
    id: number;
    body: number;
}

export interface ProjectUpdateCommentRequest {
    id: number;
    body: string;
}

export interface ProjectUpdateMaterialRequest {
    id: number;
    body: number;
}

export interface ProjectUpdateStoragePlaceRequest {
    id: number;
    body: string;
}

export interface RepairAssignSelfRequest {
    id: number;
}

export interface RepairToArchiveRequest {
    id: number;
}

export interface RepairToggleCompletedRequest {
    id: number;
}

export interface RepairTogglePartsRequest {
    id: number;
}

export interface RepairToggleProcessingRequest {
    id: number;
}

export interface RepairUpdateDescriptionRequest {
    id: number;
    body: string;
}

export interface RepairUpdateUploadsRequest {
    id: number;
    upload: Array<Upload>;
}

export interface SaveAssemblerListRequest {
    uploadId: string;
}

export interface SaveDailyWageReportRequest {
    dailyWageReport?: DailyWageReport;
}

export interface SaveProjectGroupRequest {
    projectGroup?: ProjectGroup;
}

export interface SaveRepairRequest {
    repair?: Repair;
}

export interface SaveWeeklyReportRequest {
    weeklyReport?: WeeklyReport;
}

export interface SearchProjectGroupsRequest {
    isSelfAssigned?: boolean;
    searchTerm?: string;
    page?: number;
}

export interface SearchRepairsRequest {
    isArchived?: boolean;
    searchTerm?: string;
    page?: number;
}

export interface SetProjectUploadsRequest {
    projectId: number;
    upload: Array<Upload>;
}

export interface UserGetRequest {
    page?: number;
    entiresPerPage?: number;
    searchTerm?: string;
}

export interface UserIdDeleteRequest {
    id: number;
}

export interface UserIdGetRequest {
    id: number;
}

export interface UserPostRequest {
    user?: User;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * add new tutorial uploads
     */
    async addTutorialsUploadsRaw(requestParameters: AddTutorialsUploadsRequest): Promise<runtime.ApiResponse<Array<Upload>>> {
        if (requestParameters.upload === null || requestParameters.upload === undefined) {
            throw new runtime.RequiredError('upload','Required parameter requestParameters.upload was null or undefined when calling addTutorialsUploads.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tutorials/uploads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.upload.map(UploadToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadFromJSON));
    }

    /**
     * add new tutorial uploads
     */
    async addTutorialsUploads(upload: Array<Upload>): Promise<Array<Upload>> {
        const response = await this.addTutorialsUploadsRaw({ upload: upload });
        return await response.value();
    }

    /**
     * Upload a file
     */
    async addUploadRaw(requestParameters: AddUploadRequest): Promise<runtime.ApiResponse<Upload>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadFromJSON(jsonValue));
    }

    /**
     * Upload a file
     */
    async addUpload(file?: Blob): Promise<Upload> {
        const response = await this.addUploadRaw({ file: file });
        return await response.value();
    }

    /**
     * Upload a assembler list file
     */
    async addUploadAssemblerListRaw(requestParameters: AddUploadAssemblerListRequest): Promise<runtime.ApiResponse<UploadAssemblerList>> {
        if (requestParameters.week === null || requestParameters.week === undefined) {
            throw new runtime.RequiredError('week','Required parameter requestParameters.week was null or undefined when calling addUploadAssemblerList.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling addUploadAssemblerList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.week !== undefined) {
            queryParameters['week'] = requestParameters.week;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/upload/assembler-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadAssemblerListFromJSON(jsonValue));
    }

    /**
     * Upload a assembler list file
     */
    async addUploadAssemblerList(week: number, year: number, file?: Blob): Promise<UploadAssemblerList> {
        const response = await this.addUploadAssemblerListRaw({ week: week, year: year, file: file });
        return await response.value();
    }

    /**
     * returns a list of all knwon abbreviations
     */
    async allAbbreviationsGetRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allAbbreviations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * returns a list of all knwon abbreviations
     */
    async allAbbreviationsGet(): Promise<Array<string>> {
        const response = await this.allAbbreviationsGetRaw();
        return await response.value();
    }

    /**
     * deletes a daily wage report
     */
    async deleteDailyWageReportRaw(requestParameters: DeleteDailyWageReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling deleteDailyWageReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/daily-wage-report/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deletes a daily wage report
     */
    async deleteDailyWageReport(reportId: number): Promise<void> {
        await this.deleteDailyWageReportRaw({ reportId: reportId });
    }

    /**
     * marks a project group and all of its projects as deleted
     */
    async deleteProjectGroupRaw(requestParameters: DeleteProjectGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling deleteProjectGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/group/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * marks a project group and all of its projects as deleted
     */
    async deleteProjectGroup(slug: string): Promise<void> {
        await this.deleteProjectGroupRaw({ slug: slug });
    }

    /**
     * deletes a tutorial entry
     */
    async deleteTutorialEntryRaw(requestParameters: DeleteTutorialEntryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTutorialEntry.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tutorials/uploads/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deletes a tutorial entry
     */
    async deleteTutorialEntry(id: string): Promise<void> {
        await this.deleteTutorialEntryRaw({ id: id });
    }

    /**
     * deletes an assembler list upload
     */
    async deleteUploadAssemblerListRaw(requestParameters: DeleteUploadAssemblerListRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
            throw new runtime.RequiredError('uploadId','Required parameter requestParameters.uploadId was null or undefined when calling deleteUploadAssemblerList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/upload/assembler-list/{uploadId}`.replace(`{${"uploadId"}}`, encodeURIComponent(String(requestParameters.uploadId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deletes an assembler list upload
     */
    async deleteUploadAssemblerList(uploadId: string): Promise<void> {
        await this.deleteUploadAssemblerListRaw({ uploadId: uploadId });
    }

    /**
     * deletes a weekly report
     */
    async deleteWeeklyReportRaw(requestParameters: DeleteWeeklyReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling deleteWeeklyReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/weekly-report/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deletes a weekly report
     */
    async deleteWeeklyReport(reportId: number): Promise<void> {
        await this.deleteWeeklyReportRaw({ reportId: reportId });
    }

    /**
     * Duplicates an upload serverside
     */
    async duplicateUploadRaw(requestParameters: DuplicateUploadRequest): Promise<runtime.ApiResponse<Upload>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling duplicateUpload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/upload/duplicate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadFromJSON(jsonValue));
    }

    /**
     * Duplicates an upload serverside
     */
    async duplicateUpload(id: string): Promise<Upload> {
        const response = await this.duplicateUploadRaw({ id: id });
        return await response.value();
    }

    /**
     * finalizes a daily wage report and triggers the pdf export
     */
    async exportDailyWageReportRaw(requestParameters: ExportDailyWageReportRequest): Promise<runtime.ApiResponse<DailyWageReport>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling exportDailyWageReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/plain';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/daily-wage-report/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyWageReportFromJSON(jsonValue));
    }

    /**
     * finalizes a daily wage report and triggers the pdf export
     */
    async exportDailyWageReport(reportId: number, body?: string): Promise<DailyWageReport> {
        const response = await this.exportDailyWageReportRaw({ reportId: reportId, body: body });
        return await response.value();
    }

    /**
     * finalizes a weekly report and triggers the pdf export
     */
    async exportWeeklyReportRaw(requestParameters: ExportWeeklyReportRequest): Promise<runtime.ApiResponse<WeeklyReport>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling exportWeeklyReport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/plain';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/weekly-report/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WeeklyReportFromJSON(jsonValue));
    }

    /**
     * finalizes a weekly report and triggers the pdf export
     */
    async exportWeeklyReport(reportId: number, body?: string): Promise<WeeklyReport> {
        const response = await this.exportWeeklyReportRaw({ reportId: reportId, body: body });
        return await response.value();
    }

    /**
     * returns all users of role assembler or e-assembler
     */
    async getAllAssemblersRaw(): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/assemblers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * returns all users of role assembler or e-assembler
     */
    async getAllAssemblers(): Promise<Array<User>> {
        const response = await this.getAllAssemblersRaw();
        return await response.value();
    }

    /**
     * returns all daily wage reports of a project group
     */
    async getAllDailyWageReportsByProjectGroupRaw(requestParameters: GetAllDailyWageReportsByProjectGroupRequest): Promise<runtime.ApiResponse<Array<DailyWageReport>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllDailyWageReportsByProjectGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/group/{id}/daily-wage-report`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DailyWageReportFromJSON));
    }

    /**
     * returns all daily wage reports of a project group
     */
    async getAllDailyWageReportsByProjectGroup(id: number): Promise<Array<DailyWageReport>> {
        const response = await this.getAllDailyWageReportsByProjectGroupRaw({ id: id });
        return await response.value();
    }

    /**
     * returns all daily wage reports of a repair
     */
    async getAllDailyWageReportsByRepairRaw(requestParameters: GetAllDailyWageReportsByRepairRequest): Promise<runtime.ApiResponse<Array<DailyWageReport>>> {
        if (requestParameters.repairId === null || requestParameters.repairId === undefined) {
            throw new runtime.RequiredError('repairId','Required parameter requestParameters.repairId was null or undefined when calling getAllDailyWageReportsByRepair.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{repairId}/daily-wage-report`.replace(`{${"repairId"}}`, encodeURIComponent(String(requestParameters.repairId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DailyWageReportFromJSON));
    }

    /**
     * returns all daily wage reports of a repair
     */
    async getAllDailyWageReportsByRepair(repairId: number): Promise<Array<DailyWageReport>> {
        const response = await this.getAllDailyWageReportsByRepairRaw({ repairId: repairId });
        return await response.value();
    }

    /**
     * returns a list of all active project groups
     */
    async getAllProjectGroupsRaw(): Promise<runtime.ApiResponse<Array<ProjectGroup>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/group/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectGroupFromJSON));
    }

    /**
     * returns a list of all active project groups
     */
    async getAllProjectGroups(): Promise<Array<ProjectGroup>> {
        const response = await this.getAllProjectGroupsRaw();
        return await response.value();
    }

    /**
     * returns all projects of a group
     */
    async getAllProjectsByGroupRaw(requestParameters: GetAllProjectsByGroupRequest): Promise<runtime.ApiResponse<Array<Project>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllProjectsByGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/group/{id}/project`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectFromJSON));
    }

    /**
     * returns all projects of a group
     */
    async getAllProjectsByGroup(id: number): Promise<Array<Project>> {
        const response = await this.getAllProjectsByGroupRaw({ id: id });
        return await response.value();
    }

    /**
     * returns a list of all active repairs
     */
    async getAllRepairsRaw(requestParameters: GetAllRepairsRequest): Promise<runtime.ApiResponse<Array<Repair>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.isArchived !== undefined) {
            queryParameters['isArchived'] = requestParameters.isArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairFromJSON));
    }

    /**
     * returns a list of all active repairs
     */
    async getAllRepairs(isArchived?: boolean): Promise<Array<Repair>> {
        const response = await this.getAllRepairsRaw({ isArchived: isArchived });
        return await response.value();
    }

    /**
     * Get all tutorial categories
     */
    async getAllTutorialCategoriesRaw(): Promise<runtime.ApiResponse<Array<UploadCategory>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tutorials/category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadCategoryFromJSON));
    }

    /**
     * Get all tutorial categories
     */
    async getAllTutorialCategories(): Promise<Array<UploadCategory>> {
        const response = await this.getAllTutorialCategoriesRaw();
        return await response.value();
    }

    /**
     * returns all tutorial uploads
     */
    async getAllTutorialUploadsRaw(): Promise<runtime.ApiResponse<Array<Upload>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tutorials/uploads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadFromJSON));
    }

    /**
     * returns all tutorial uploads
     */
    async getAllTutorialUploads(): Promise<Array<Upload>> {
        const response = await this.getAllTutorialUploadsRaw();
        return await response.value();
    }

    /**
     * Get all upload categories
     */
    async getAllUploadCategoriesRaw(): Promise<runtime.ApiResponse<Array<UploadCategory>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/upload/category`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadCategoryFromJSON));
    }

    /**
     * Get all upload categories
     */
    async getAllUploadCategories(): Promise<Array<UploadCategory>> {
        const response = await this.getAllUploadCategoriesRaw();
        return await response.value();
    }

    /**
     * returns all weekly reports of a project group
     */
    async getAllWeeklyReportsByProjectGroupRaw(requestParameters: GetAllWeeklyReportsByProjectGroupRequest): Promise<runtime.ApiResponse<Array<WeeklyReport>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllWeeklyReportsByProjectGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/group/{id}/weekly-report`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WeeklyReportFromJSON));
    }

    /**
     * returns all weekly reports of a project group
     */
    async getAllWeeklyReportsByProjectGroup(id: number): Promise<Array<WeeklyReport>> {
        const response = await this.getAllWeeklyReportsByProjectGroupRaw({ id: id });
        return await response.value();
    }

    /**
     * returns the currently logged in user
     */
    async getCurrentUserRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * returns the currently logged in user
     */
    async getCurrentUser(): Promise<User> {
        const response = await this.getCurrentUserRaw();
        return await response.value();
    }

    /**
     * returns a single project group
     */
    async getProjectGroupBySlugRaw(requestParameters: GetProjectGroupBySlugRequest): Promise<runtime.ApiResponse<ProjectGroup>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling getProjectGroupBySlug.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/group/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGroupFromJSON(jsonValue));
    }

    /**
     * returns a single project group
     */
    async getProjectGroupBySlug(slug: string): Promise<ProjectGroup> {
        const response = await this.getProjectGroupBySlugRaw({ slug: slug });
        return await response.value();
    }

    /**
     * returns project groups near the specified location
     */
    async getProjectGroupsNearRaw(requestParameters: GetProjectGroupsNearRequest): Promise<runtime.ApiResponse<Array<ProjectGroup>>> {
        if (requestParameters.lat === null || requestParameters.lat === undefined) {
            throw new runtime.RequiredError('lat','Required parameter requestParameters.lat was null or undefined when calling getProjectGroupsNear.');
        }

        if (requestParameters.lng === null || requestParameters.lng === undefined) {
            throw new runtime.RequiredError('lng','Required parameter requestParameters.lng was null or undefined when calling getProjectGroupsNear.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/location/{lat}/{lng}/project-groups-near`.replace(`{${"lat"}}`, encodeURIComponent(String(requestParameters.lat))).replace(`{${"lng"}}`, encodeURIComponent(String(requestParameters.lng))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectGroupFromJSON));
    }

    /**
     * returns project groups near the specified location
     */
    async getProjectGroupsNear(lat: number, lng: number): Promise<Array<ProjectGroup>> {
        const response = await this.getProjectGroupsNearRaw({ lat: lat, lng: lng });
        return await response.value();
    }

    /**
     * returns the history of a project
     */
    async getProjectHistoryRaw(requestParameters: GetProjectHistoryRequest): Promise<runtime.ApiResponse<Array<HistoryEntry>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectHistory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{projectId}/history`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HistoryEntryFromJSON));
    }

    /**
     * returns the history of a project
     */
    async getProjectHistory(projectId: number): Promise<Array<HistoryEntry>> {
        const response = await this.getProjectHistoryRaw({ projectId: projectId });
        return await response.value();
    }

    /**
     * returns all uploads of a project
     */
    async getProjectUploadsRaw(requestParameters: GetProjectUploadsRequest): Promise<runtime.ApiResponse<Array<Upload>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectUploads.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{projectId}/uploads`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadFromJSON));
    }

    /**
     * returns all uploads of a project
     */
    async getProjectUploads(projectId: number): Promise<Array<Upload>> {
        const response = await this.getProjectUploadsRaw({ projectId: projectId });
        return await response.value();
    }

    /**
     * returns all projects, that belong to given project groups
     */
    async getProjectsByGroupsRaw(requestParameters: GetProjectsByGroupsRequest): Promise<runtime.ApiResponse<Array<Project>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/by-project-groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectFromJSON));
    }

    /**
     * returns all projects, that belong to given project groups
     */
    async getProjectsByGroups(requestBody?: Array<number>): Promise<Array<Project>> {
        const response = await this.getProjectsByGroupsRaw({ requestBody: requestBody });
        return await response.value();
    }

    /**
     * returns one specified repair
     */
    async getRepairRaw(requestParameters: GetRepairRequest): Promise<runtime.ApiResponse<Repair>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRepair.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairFromJSON(jsonValue));
    }

    /**
     * returns one specified repair
     */
    async getRepair(id: number): Promise<Repair> {
        const response = await this.getRepairRaw({ id: id });
        return await response.value();
    }

    /**
     * returns repairs near the specified location
     */
    async getRepairsNearRaw(requestParameters: GetRepairsNearRequest): Promise<runtime.ApiResponse<Array<Repair>>> {
        if (requestParameters.lat === null || requestParameters.lat === undefined) {
            throw new runtime.RequiredError('lat','Required parameter requestParameters.lat was null or undefined when calling getRepairsNear.');
        }

        if (requestParameters.lng === null || requestParameters.lng === undefined) {
            throw new runtime.RequiredError('lng','Required parameter requestParameters.lng was null or undefined when calling getRepairsNear.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/location/{lat}/{lng}/repairs-near`.replace(`{${"lat"}}`, encodeURIComponent(String(requestParameters.lat))).replace(`{${"lng"}}`, encodeURIComponent(String(requestParameters.lng))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairFromJSON));
    }

    /**
     * returns repairs near the specified location
     */
    async getRepairsNear(lat: number, lng: number): Promise<Array<Repair>> {
        const response = await this.getRepairsNearRaw({ lat: lat, lng: lng });
        return await response.value();
    }

    /**
     * returns the role for the requesting user
     */
    async getRoleGetRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/getRole`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * returns the role for the requesting user
     */
    async getRoleGet(): Promise<string> {
        const response = await this.getRoleGetRaw();
        return await response.value();
    }

    /**
     * returns all current assembler list uploads
     */
    async getUploadAssemblerListsRaw(): Promise<runtime.ApiResponse<Array<UploadAssemblerList>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/upload/assembler-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadAssemblerListFromJSON));
    }

    /**
     * returns all current assembler list uploads
     */
    async getUploadAssemblerLists(): Promise<Array<UploadAssemblerList>> {
        const response = await this.getUploadAssemblerListsRaw();
        return await response.value();
    }

    /**
     * Returns a requested upload file resource
     */
    async getUploadFileRaw(requestParameters: GetUploadFileRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUploadFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/upload/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Returns a requested upload file resource
     */
    async getUploadFile(id: string): Promise<Blob> {
        const response = await this.getUploadFileRaw({ id: id });
        return await response.value();
    }

    /**
     * Returns the upload limit in bytes
     */
    async getUploadSizeLimitRaw(): Promise<runtime.ApiResponse<number>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/upload/limit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Returns the upload limit in bytes
     */
    async getUploadSizeLimit(): Promise<number> {
        const response = await this.getUploadSizeLimitRaw();
        return await response.value();
    }

    /**
     * perfroms a login for a user
     */
    async loginPostRaw(requestParameters: LoginPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsToJSON(requestParameters.credentials),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * perfroms a login for a user
     */
    async loginPost(credentials?: Credentials): Promise<string> {
        const response = await this.loginPostRaw({ credentials: credentials });
        return await response.value();
    }

    /**
     * performs a logout for a user
     */
    async logoutPostRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * performs a logout for a user
     */
    async logoutPost(): Promise<void> {
        await this.logoutPostRaw();
    }

    /**
     * returns a full list of all messages for the requesting user
     */
    async messageGetRaw(): Promise<runtime.ApiResponse<Array<Message>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/message`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageFromJSON));
    }

    /**
     * returns a full list of all messages for the requesting user
     */
    async messageGet(): Promise<Array<Message>> {
        const response = await this.messageGetRaw();
        return await response.value();
    }

    /**
     * creates a new message
     */
    async messagePostRaw(requestParameters: MessagePostRequest): Promise<runtime.ApiResponse<InputResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageToJSON(requestParameters.message),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InputResponseFromJSON(jsonValue));
    }

    /**
     * creates a new message
     */
    async messagePost(message?: Message): Promise<InputResponse> {
        const response = await this.messagePostRaw({ message: message });
        return await response.value();
    }

    /**
     * used to mark a message as done
     */
    async patchMessageDoneRaw(requestParameters: PatchMessageDoneRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchMessageDone.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/message/{id}/done`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * used to mark a message as done
     */
    async patchMessageDone(id: number): Promise<void> {
        await this.patchMessageDoneRaw({ id: id });
    }

    /**
     * returns a list of all active projects for the specified department
     */
    async projectDepartmentGetRaw(requestParameters: ProjectDepartmentGetRequest): Promise<runtime.ApiResponse<Array<Project>>> {
        if (requestParameters.department === null || requestParameters.department === undefined) {
            throw new runtime.RequiredError('department','Required parameter requestParameters.department was null or undefined when calling projectDepartmentGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{department}`.replace(`{${"department"}}`, encodeURIComponent(String(requestParameters.department))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectFromJSON));
    }

    /**
     * returns a list of all active projects for the specified department
     */
    async projectDepartmentGet(department: string): Promise<Array<Project>> {
        const response = await this.projectDepartmentGetRaw({ department: department });
        return await response.value();
    }

    /**
     * returns a list with all active projects
     */
    async projectGetRaw(): Promise<runtime.ApiResponse<Array<Project>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectFromJSON));
    }

    /**
     * returns a list with all active projects
     */
    async projectGet(): Promise<Array<Project>> {
        const response = await this.projectGetRaw();
        return await response.value();
    }

    /**
     * marks a project as deleted
     */
    async projectIdDeleteRaw(requestParameters: ProjectIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * marks a project as deleted
     */
    async projectIdDelete(id: number): Promise<void> {
        await this.projectIdDeleteRaw({ id: id });
    }

    /**
     * removes the deleted mark from a project
     */
    async projectIdRecoverPutRaw(requestParameters: ProjectIdRecoverPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectIdRecoverPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/recover`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * removes the deleted mark from a project
     */
    async projectIdRecoverPut(id: number): Promise<void> {
        await this.projectIdRecoverPutRaw({ id: id });
    }

    /**
     * adds a new Project
     */
    async projectPostRaw(requestParameters: ProjectPostRequest): Promise<runtime.ApiResponse<InputResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectToJSON(requestParameters.project),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InputResponseFromJSON(jsonValue));
    }

    /**
     * adds a new Project
     */
    async projectPost(project?: Project): Promise<InputResponse> {
        const response = await this.projectPostRaw({ project: project });
        return await response.value();
    }

    /**
     * set a project\'s archive flag
     */
    async projectToArchiveRaw(requestParameters: ProjectToArchiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectToArchive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * set a project\'s archive flag
     */
    async projectToArchive(id: number): Promise<void> {
        await this.projectToArchiveRaw({ id: id });
    }

    /**
     * Toggles the assembly_complete flag of a project
     */
    async projectToggleAssemblyCompleteRaw(requestParameters: ProjectToggleAssemblyCompleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectToggleAssemblyComplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/assembly_complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggles the assembly_complete flag of a project
     */
    async projectToggleAssemblyComplete(id: number): Promise<void> {
        await this.projectToggleAssemblyCompleteRaw({ id: id });
    }

    /**
     * Toggles the completed flag of a project
     */
    async projectToggleCompletedRaw(requestParameters: ProjectToggleCompletedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectToggleCompleted.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/completed`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggles the completed flag of a project
     */
    async projectToggleCompleted(id: number): Promise<void> {
        await this.projectToggleCompletedRaw({ id: id });
    }

    /**
     * Toggles the connections_complete flag of a project
     */
    async projectToggleConnectionsCompleteRaw(requestParameters: ProjectToggleConnectionsCompleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectToggleConnectionsComplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/connections_complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggles the connections_complete flag of a project
     */
    async projectToggleConnectionsComplete(id: number): Promise<void> {
        await this.projectToggleConnectionsCompleteRaw({ id: id });
    }

    /**
     * Toggles the cut flag of a project
     */
    async projectToggleCutRaw(requestParameters: ProjectToggleCutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectToggleCut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/cut`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggles the cut flag of a project
     */
    async projectToggleCut(id: number): Promise<void> {
        await this.projectToggleCutRaw({ id: id });
    }

    /**
     * Toggles the glas_complete flag of a project
     */
    async projectToggleGlasCompleteRaw(requestParameters: ProjectToggleGlasCompleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectToggleGlasComplete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/glas_complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggles the glas_complete flag of a project
     */
    async projectToggleGlasComplete(id: number): Promise<void> {
        await this.projectToggleGlasCompleteRaw({ id: id });
    }

    /**
     * Toggles the operating flag of a project
     */
    async projectToggleOperatingRaw(requestParameters: ProjectToggleOperatingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectToggleOperating.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/operating`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggles the operating flag of a project
     */
    async projectToggleOperating(id: number): Promise<void> {
        await this.projectToggleOperatingRaw({ id: id });
    }

    /**
     * Toggles the planned flag of a project
     */
    async projectTogglePlannedRaw(requestParameters: ProjectTogglePlannedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectTogglePlanned.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/planned`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Toggles the planned flag of a project
     */
    async projectTogglePlanned(id: number): Promise<void> {
        await this.projectTogglePlannedRaw({ id: id });
    }

    /**
     * Updates a project\'s coater fields
     */
    async projectUpdateCoaterRaw(requestParameters: ProjectUpdateCoaterRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectUpdateCoater.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling projectUpdateCoater.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/coater`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a project\'s coater fields
     */
    async projectUpdateCoater(id: number, body: number): Promise<void> {
        await this.projectUpdateCoaterRaw({ id: id, body: body });
    }

    /**
     * updates a projects\'s comment
     */
    async projectUpdateCommentRaw(requestParameters: ProjectUpdateCommentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectUpdateComment.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling projectUpdateComment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/plain';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/comment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updates a projects\'s comment
     */
    async projectUpdateComment(id: number, body: string): Promise<void> {
        await this.projectUpdateCommentRaw({ id: id, body: body });
    }

    /**
     * Updates a project\'s material field
     */
    async projectUpdateMaterialRaw(requestParameters: ProjectUpdateMaterialRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectUpdateMaterial.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling projectUpdateMaterial.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/material`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a project\'s material field
     */
    async projectUpdateMaterial(id: number, body: number): Promise<void> {
        await this.projectUpdateMaterialRaw({ id: id, body: body });
    }

    /**
     * updates a projects\'s storage place field
     */
    async projectUpdateStoragePlaceRaw(requestParameters: ProjectUpdateStoragePlaceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectUpdateStoragePlace.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling projectUpdateStoragePlace.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/plain';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{id}/storage_place`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updates a projects\'s storage place field
     */
    async projectUpdateStoragePlace(id: number, body: string): Promise<void> {
        await this.projectUpdateStoragePlaceRaw({ id: id, body: body });
    }

    /**
     * assign repair to requesting assembly user
     */
    async repairAssignSelfRaw(requestParameters: RepairAssignSelfRequest): Promise<runtime.ApiResponse<Repair>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling repairAssignSelf.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{id}/assign`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairFromJSON(jsonValue));
    }

    /**
     * assign repair to requesting assembly user
     */
    async repairAssignSelf(id: number): Promise<Repair> {
        const response = await this.repairAssignSelfRaw({ id: id });
        return await response.value();
    }

    /**
     * set a repair\'s archive flag
     */
    async repairToArchiveRaw(requestParameters: RepairToArchiveRequest): Promise<runtime.ApiResponse<Repair>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling repairToArchive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairFromJSON(jsonValue));
    }

    /**
     * set a repair\'s archive flag
     */
    async repairToArchive(id: number): Promise<Repair> {
        const response = await this.repairToArchiveRaw({ id: id });
        return await response.value();
    }

    /**
     * toggles a repair\'s completed flag
     */
    async repairToggleCompletedRaw(requestParameters: RepairToggleCompletedRequest): Promise<runtime.ApiResponse<Repair>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling repairToggleCompleted.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{id}/completed`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairFromJSON(jsonValue));
    }

    /**
     * toggles a repair\'s completed flag
     */
    async repairToggleCompleted(id: number): Promise<Repair> {
        const response = await this.repairToggleCompletedRaw({ id: id });
        return await response.value();
    }

    /**
     * toggles a repair\'s parts flag
     */
    async repairTogglePartsRaw(requestParameters: RepairTogglePartsRequest): Promise<runtime.ApiResponse<Repair>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling repairToggleParts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{id}/parts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairFromJSON(jsonValue));
    }

    /**
     * toggles a repair\'s parts flag
     */
    async repairToggleParts(id: number): Promise<Repair> {
        const response = await this.repairTogglePartsRaw({ id: id });
        return await response.value();
    }

    /**
     * toggles a repair\'s processing flag
     */
    async repairToggleProcessingRaw(requestParameters: RepairToggleProcessingRequest): Promise<runtime.ApiResponse<Repair>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling repairToggleProcessing.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{id}/processing`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairFromJSON(jsonValue));
    }

    /**
     * toggles a repair\'s processing flag
     */
    async repairToggleProcessing(id: number): Promise<Repair> {
        const response = await this.repairToggleProcessingRaw({ id: id });
        return await response.value();
    }

    /**
     * updates a repair\'s description
     */
    async repairUpdateDescriptionRaw(requestParameters: RepairUpdateDescriptionRequest): Promise<runtime.ApiResponse<Repair>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling repairUpdateDescription.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling repairUpdateDescription.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/plain';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{id}/description`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairFromJSON(jsonValue));
    }

    /**
     * updates a repair\'s description
     */
    async repairUpdateDescription(id: number, body: string): Promise<Repair> {
        const response = await this.repairUpdateDescriptionRaw({ id: id, body: body });
        return await response.value();
    }

    /**
     * updates a repair\'s uploads
     */
    async repairUpdateUploadsRaw(requestParameters: RepairUpdateUploadsRequest): Promise<runtime.ApiResponse<Repair>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling repairUpdateUploads.');
        }

        if (requestParameters.upload === null || requestParameters.upload === undefined) {
            throw new runtime.RequiredError('upload','Required parameter requestParameters.upload was null or undefined when calling repairUpdateUploads.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair/{id}/uploads`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.upload.map(UploadToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairFromJSON(jsonValue));
    }

    /**
     * updates a repair\'s uploads
     */
    async repairUpdateUploads(id: number, upload: Array<Upload>): Promise<Repair> {
        const response = await this.repairUpdateUploadsRaw({ id: id, upload: upload });
        return await response.value();
    }

    /**
     * saves an existing assembler list upload
     */
    async saveAssemblerListRaw(requestParameters: SaveAssemblerListRequest): Promise<runtime.ApiResponse<UploadAssemblerList>> {
        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
            throw new runtime.RequiredError('uploadId','Required parameter requestParameters.uploadId was null or undefined when calling saveAssemblerList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/upload/assembler-list/{uploadId}`.replace(`{${"uploadId"}}`, encodeURIComponent(String(requestParameters.uploadId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadAssemblerListFromJSON(jsonValue));
    }

    /**
     * saves an existing assembler list upload
     */
    async saveAssemblerList(uploadId: string): Promise<UploadAssemblerList> {
        const response = await this.saveAssemblerListRaw({ uploadId: uploadId });
        return await response.value();
    }

    /**
     * saves a new or existing daily wage report
     */
    async saveDailyWageReportRaw(requestParameters: SaveDailyWageReportRequest): Promise<runtime.ApiResponse<DailyWageReport>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/daily-wage-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DailyWageReportToJSON(requestParameters.dailyWageReport),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DailyWageReportFromJSON(jsonValue));
    }

    /**
     * saves a new or existing daily wage report
     */
    async saveDailyWageReport(dailyWageReport?: DailyWageReport): Promise<DailyWageReport> {
        const response = await this.saveDailyWageReportRaw({ dailyWageReport: dailyWageReport });
        return await response.value();
    }

    /**
     * saves a new or existing project group
     */
    async saveProjectGroupRaw(requestParameters: SaveProjectGroupRequest): Promise<runtime.ApiResponse<InputResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectGroupToJSON(requestParameters.projectGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InputResponseFromJSON(jsonValue));
    }

    /**
     * saves a new or existing project group
     */
    async saveProjectGroup(projectGroup?: ProjectGroup): Promise<InputResponse> {
        const response = await this.saveProjectGroupRaw({ projectGroup: projectGroup });
        return await response.value();
    }

    /**
     * saves a new or existing repair
     */
    async saveRepairRaw(requestParameters: SaveRepairRequest): Promise<runtime.ApiResponse<InputResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepairToJSON(requestParameters.repair),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InputResponseFromJSON(jsonValue));
    }

    /**
     * saves a new or existing repair
     */
    async saveRepair(repair?: Repair): Promise<InputResponse> {
        const response = await this.saveRepairRaw({ repair: repair });
        return await response.value();
    }

    /**
     * saves a new or existing weekly report
     */
    async saveWeeklyReportRaw(requestParameters: SaveWeeklyReportRequest): Promise<runtime.ApiResponse<WeeklyReport>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/weekly-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WeeklyReportToJSON(requestParameters.weeklyReport),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WeeklyReportFromJSON(jsonValue));
    }

    /**
     * saves a new or existing weekly report
     */
    async saveWeeklyReport(weeklyReport?: WeeklyReport): Promise<WeeklyReport> {
        const response = await this.saveWeeklyReportRaw({ weeklyReport: weeklyReport });
        return await response.value();
    }

    /**
     * search for project groups
     */
    async searchProjectGroupsRaw(requestParameters: SearchProjectGroupsRequest): Promise<runtime.ApiResponse<ProjectGroupSearchResult>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.isSelfAssigned !== undefined) {
            queryParameters['isSelfAssigned'] = requestParameters.isSelfAssigned;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/group`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectGroupSearchResultFromJSON(jsonValue));
    }

    /**
     * search for project groups
     */
    async searchProjectGroups(isSelfAssigned?: boolean, searchTerm?: string, page?: number): Promise<ProjectGroupSearchResult> {
        const response = await this.searchProjectGroupsRaw({ isSelfAssigned: isSelfAssigned, searchTerm: searchTerm, page: page });
        return await response.value();
    }

    /**
     * search for repairs
     */
    async searchRepairsRaw(requestParameters: SearchRepairsRequest): Promise<runtime.ApiResponse<RepairSearchResult>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.isArchived !== undefined) {
            queryParameters['isArchived'] = requestParameters.isArchived;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairSearchResultFromJSON(jsonValue));
    }

    /**
     * search for repairs
     */
    async searchRepairs(isArchived?: boolean, searchTerm?: string, page?: number): Promise<RepairSearchResult> {
        const response = await this.searchRepairsRaw({ isArchived: isArchived, searchTerm: searchTerm, page: page });
        return await response.value();
    }

    /**
     * updates a project\'s uploads
     */
    async setProjectUploadsRaw(requestParameters: SetProjectUploadsRequest): Promise<runtime.ApiResponse<Array<Upload>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling setProjectUploads.');
        }

        if (requestParameters.upload === null || requestParameters.upload === undefined) {
            throw new runtime.RequiredError('upload','Required parameter requestParameters.upload was null or undefined when calling setProjectUploads.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/project/{projectId}/uploads`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.upload.map(UploadToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadFromJSON));
    }

    /**
     * updates a project\'s uploads
     */
    async setProjectUploads(projectId: number, upload: Array<Upload>): Promise<Array<Upload>> {
        const response = await this.setProjectUploadsRaw({ projectId: projectId, upload: upload });
        return await response.value();
    }

    /**
     * returns a page of Users
     */
    async userGetRaw(requestParameters: UserGetRequest): Promise<runtime.ApiResponse<UserPage>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.entiresPerPage !== undefined) {
            queryParameters['entiresPerPage'] = requestParameters.entiresPerPage;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPageFromJSON(jsonValue));
    }

    /**
     * returns a page of Users
     */
    async userGet(page?: number, entiresPerPage?: number, searchTerm?: string): Promise<UserPage> {
        const response = await this.userGetRaw({ page: page, entiresPerPage: entiresPerPage, searchTerm: searchTerm });
        return await response.value();
    }

    /**
     * deletes a User from the system
     */
    async userIdDeleteRaw(requestParameters: UserIdDeleteRequest): Promise<runtime.ApiResponse<InputResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InputResponseFromJSON(jsonValue));
    }

    /**
     * deletes a User from the system
     */
    async userIdDelete(id: number): Promise<InputResponse> {
        const response = await this.userIdDeleteRaw({ id: id });
        return await response.value();
    }

    /**
     * returns a single user with the given id
     */
    async userIdGetRaw(requestParameters: UserIdGetRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * returns a single user with the given id
     */
    async userIdGet(id: number): Promise<User> {
        const response = await this.userIdGetRaw({ id: id });
        return await response.value();
    }

    /**
     * saves a new or modified user to the database
     */
    async userPostRaw(requestParameters: UserPostRequest): Promise<runtime.ApiResponse<InputResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InputResponseFromJSON(jsonValue));
    }

    /**
     * saves a new or modified user to the database
     */
    async userPost(user?: User): Promise<InputResponse> {
        const response = await this.userPostRaw({ user: user });
        return await response.value();
    }

    /**
     * returns all project managers
     */
    async userProjectManagersGetRaw(): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/projectManagers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * returns all project managers
     */
    async userProjectManagersGet(): Promise<Array<User>> {
        const response = await this.userProjectManagersGetRaw();
        return await response.value();
    }

}
