

import { Component, Vue } from 'vue-property-decorator';
import { Message } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import InputButton from '@/components/input/InputButton.vue';
import { MESSAGE_TYPE } from '@/assets/ts/enum/message_type';

@Component({
    components: {
        InputButton,
        Loading,
    },
})
export default class Messages extends Vue {

    private MESSAGE_TYPE = MESSAGE_TYPE;

    private messages: Message[] = [];
    private loading: boolean = true;

    private mounted() {
        this.loadMessages();
    }

    private async loadMessages() {
        this.loading = true;
        try {
            this.messages = await Api.omb.messageGet();
        } catch (e: any) {
            if (e.status === 403) {
                this.$router.push({ name: availableRoutes.features });
            } else if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
        this.loading = false;
        this.$forceUpdate();
    }

    private async markAsDone(message: Message) {
        try {
            if (message.id !== undefined) {
                await Api.omb.patchMessageDone(message.id);
            }
            this.messages = this.messages.filter((m) => m.id !== message.id);
        } catch (e: any) {
            if (e.status === 403) {
                this.$router.push({ name: availableRoutes.features });
            } else if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

}
