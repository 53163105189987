

import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    Project,
    ProjectGroup,
} from '@/clients/omb-api';
import { ROLE } from '@/assets/ts/enum/role';
import availableRoutes from '@/plugins/availableRoutes';
import LocationUtils from '@/assets/ts/location_utils';

@Component({
    components: {},
})
export default class ProjectGroupsTable extends Vue {

    private ROLE = ROLE;

    @Prop({ required: true })
    private projectGroups!: ProjectGroup[];

    @Prop({ required: true })
    private projects!: Project[];

    @Prop({ default: false })
    private preview!: boolean;

    @Prop({ default: 0 })
    private distanceFromLat!: number;

    @Prop({ default: 0 })
    private distanceFromLng!: number;

    private mounted() {
        this.$root.$on('print', this.print);
    }

    private destroyed() {
        this.$root.$off('print', this.print);
    }

    private getDistanceString(projectGroup: ProjectGroup) {
        if (!this.distanceFromLat || !this.distanceFromLng || !projectGroup.lat || !projectGroup.lng) {
            return '-';
        }

        const distance = LocationUtils.getDistance(this.distanceFromLat, this.distanceFromLng, projectGroup.lat, projectGroup.lng);

        return `${Math.round(distance)}km`;
    }

    private getProjectGroupAddress(projectGroup: ProjectGroup) {
        if (!projectGroup.street && !projectGroup.zipCode && !projectGroup.city) return '-';
        return `${projectGroup.street ? `${projectGroup.street},` : ''} ${projectGroup.zipCode || ''} ${projectGroup.city || ''}`;
    }

    private getAmountOfProjects(projectGroup: ProjectGroup) {
        return this.projects.filter((p) => p.projectGroupId === projectGroup.id).length;
    }

    private getProjectGroupManagers(projectGroup: ProjectGroup) {
        return projectGroup.managers ? projectGroup.managers.map((pg) => pg.abbreviation)
            .join(', ') : '-';
    }

    private print() {
        const printer = window.open('', '', 'left=0,top=0,width=0,height=0,toolbar=0,scrollbars=0,status=0');
        if (printer !== null) {
            printer.document.write('<table><thead><th>Nr.</th><th>Name des Bauvorhabens</th><th>Adresse</th><th>Anzahl Bauabschnitte</th><th>Projektleiterkürzel</th></thead><tbody>');
            this.projectGroups.forEach((projectGroup) => {
                printer.document.write(`<tr><td>${projectGroup.number}</td><td>${projectGroup.name}</td><td>${this.getProjectGroupAddress(projectGroup)}</td><td>${this.getAmountOfProjects(projectGroup)}</td><td>${this.getProjectGroupManagers(projectGroup)}</td></tr>`);
            });
            printer.document.write('</tbody></table><style>\n'
                + 'table {font-family: "PT Sans"; border-collapse: collapse} \n'
                + 'tbody tr:first-child {border-top: solid 1px;} \n'
                + 'tbody tr:nth-child(odd) {background: #EAEAEA; -webkit-print-color-adjust: exact; box-shadow: inset 0 0 0 10000px #EAEAEA}\n'
                + 'tbody tr:last-child {border-bottom: solid 1px}\n'
                + 'th {font-weight: normal; border-right: solid 1px; border-left: solid 1px; border-top: solid 1px; text-align: left; padding: 0 4px;}\n'
                + 'td {border-right: solid 1px; border-left: solid 1px; padding: 0 4px;}\n'
                + 'td:first-child {white-space: nowrap}\n'
                + '</style>');
            printer.document.close();
            printer.focus();
            printer.print();
        }
    }

    private openDetails(projectGroup: ProjectGroup) {
        this.$router.push({
            name: availableRoutes.projectGroupDetails,
            params: { slug: projectGroup.slug || '' },
            query: this.$route.query,
        });
    }

    private showRepairsAndProjectsNear(projectGroup: ProjectGroup) {
        this.$router.push({
            name: availableRoutes.repairsAndProjectsNear,
            query: { projectGroup: `${projectGroup.slug}` },
        });
    }

}
