

import { Component, Vue, Watch } from 'vue-property-decorator';
import availableRoutes from '@/plugins/availableRoutes';
import Api from '@/assets/ts/Api';
import { Message } from '@/clients/omb-api';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import InputButton from '@/components/input/InputButton.vue';

@Component({
    components: { InputButton },
})
export default class Header extends Vue {

    private ROLE = ROLE;

    private routeToFeatures: string = availableRoutes.features;
    private routeToMessages: string = availableRoutes.messages;
    private messageAmount: number = 0;
    private messages: Message[] = [];
    private role = ROLE.GUEST;

    private get hasPrintButton(): boolean {
        const a = availableRoutes;
        const routeNames = [
            a.projects,
            a.projectGroups,
            a.plastics,
            a.steel,
            a.doors,
            a.facades,
            a.windows,
            a.tin,
            a.bazQuadra1,
            a.bazQuadra2,
            a.bazCometTekna,
            a.isomat,
            a.assembly,
        ];
        return routeNames.includes(this.$route.name || '');
    }

    private get hasRefreshButton(): boolean {
        const a = availableRoutes;
        const routeNames = [
            a.projects,
            a.projectGroups,
            a.plastics,
            a.steel,
            a.doors,
            a.facades,
            a.windows,
            a.tin,
            a.bazQuadra1,
            a.bazQuadra2,
            a.bazCometTekna,
            a.isomat,
            a.assembly,
            a.projectGroupDetails,
        ];
        return routeNames.includes(this.$route.name || '');
    }

    private get hasCreateProjectButton(): boolean {
        const routeNames = [
            availableRoutes.projects,
            availableRoutes.projectGroupDetails,
        ];
        return routeNames.includes(this.$route.name || '') && [ROLE.ADMINISTRATOR, ROLE.PLANNING].includes(this.role);
    }

    private get hasCreateProjectGroupButton(): boolean {
        return this.$route.name === availableRoutes.projectGroups && [ROLE.ADMINISTRATOR, ROLE.PLANNING].includes(this.role);
    }

    private get hasCreateRepairButton(): boolean {
        return this.$route.name === availableRoutes.repairs && [ROLE.ADMINISTRATOR, ROLE.PLANNING].includes(this.role);
    }

    private mounted() {
        this.loadMessages();
        this.loadRole();
    }

    private async logout() {
        await Api.omb.logoutPost();
        Api.initServices('');
        this.$router.push({ name: availableRoutes.login });
    }

    private async loadMessages() {
        try {
            this.messages = await Api.omb.messageGet();
            this.messageAmount = this.messages.length;
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private async loadRole() {
        this.role = await getRole();
    }

    @Watch('$route', {
        immediate: true,
        deep: true,
    })
    onUrlChange(): void {
        this.loadMessages();
    }

    private print() {
        this.$root.$emit('print');
    }

    private reload() {
        this.$router.go(0);
    }

    private openNewProjectDialog() {
        this.$root.$emit('openNewProjectDialog');
    }

    private openNewProjectGroupDialog() {
        this.$root.$emit('openNewProjectGroupDialog');
    }

    private openNewRepairDialog() {
        this.$root.$emit('openNewRepairDialog');
    }

}
