

import { Component, Prop, Vue } from 'vue-property-decorator';
import InputButton from '@/components/input/InputButton.vue';
import Loading from '@/components/Loading.vue';
import Collapsable from '@/components/Collapsable.vue';
import {
    DailyWageReport,
    ProjectGroup,
    User,
    WeeklyReport,
} from '@/clients/omb-api';
import EditWeeklyReportDialog from '@/components/dialogs/EditWeeklyReportDialog.vue';
import ReportCard from '@/components/ReportCard.vue';
import Api from '@/assets/ts/Api';
import clone from 'clone';
import EditDailyWageReportDialog from '@/components/dialogs/EditDailyWageReportDialog.vue';

@Component({
    components: {
        EditDailyWageReportDialog,
        ReportCard,
        EditWeeklyReportDialog,
        Collapsable,
        Loading,
        InputButton,
    },
})
export default class ProjectGroupDetailsReports extends Vue {

    @Prop({ required: true })
    private projectGroup!: ProjectGroup;

    @Prop({ required: true })
    private currentUser!: User;

    private weeklyReports: WeeklyReport[] = [];
    private dailyWageReports: DailyWageReport[] = [];

    private ready = false;

    private weeklyReportDialogVisible = false;
    private weeklyReportToEdit: WeeklyReport | null = null;

    private dailyWageReportDialogVisible = false;
    private dailyWageReportToEdit: DailyWageReport | null = null;

    private get hasWeeklyReportDraft(): boolean {
        return !!this.weeklyReports.find((report) => !report.fileId && report.createdBy === this.currentUser.id);
    }

    private get hasDailyWageReportDraft(): boolean {
        return !!this.dailyWageReports.find((report) => !report.fileId && report.createdBy === this.currentUser.id);
    }

    private async created() {
        await this.fetchReports();
        this.ready = true;
    }

    private async fetchReports() {
        this.weeklyReports = await Api.omb.getAllWeeklyReportsByProjectGroup(this.projectGroup.id || 0);
        this.dailyWageReports = await Api.omb.getAllDailyWageReportsByProjectGroup(this.projectGroup.id || 0);
    }

    private showNewWeeklyReportDialog() {
        this.weeklyReportDialogVisible = true;
    }

    private editWeeklyReport(report: WeeklyReport) {
        this.weeklyReportToEdit = clone(report);
        this.weeklyReportDialogVisible = true;
    }

    private hideWeeklyReportDialog() {
        this.weeklyReportDialogVisible = false;
        this.weeklyReportToEdit = null;
    }

    private showNewDailyWageReportDialog() {
        this.dailyWageReportDialogVisible = true;
    }

    private editDailyWageReport(report: DailyWageReport) {
        this.dailyWageReportToEdit = clone(report);
        this.dailyWageReportDialogVisible = true;
    }

    private hideDailyWageReportDialog() {
        this.dailyWageReportDialogVisible = false;
        this.dailyWageReportToEdit = null;
    }

    private onWeeklyReportUpdate(report: WeeklyReport) {
        if (this.weeklyReports.find((r) => r.id === report.id)) {
            this.weeklyReports = this.weeklyReports.map((r) => (r.id === report.id ? report : r));
        } else {
            this.weeklyReports.push(report);
        }
    }

    private onWeeklyReportDelete(reportId: number | undefined) {
        this.weeklyReportDialogVisible = false;
        this.weeklyReportToEdit = null;
        this.weeklyReports = this.weeklyReports.filter((report) => report.id !== reportId);
    }

    private onDailyWageReportUpdate(report: DailyWageReport) {
        if (this.dailyWageReports.find((r) => r.id === report.id)) {
            this.dailyWageReports = this.dailyWageReports.map((r) => (r.id === report.id ? report : r));
        } else {
            this.dailyWageReports.push(report);
        }
    }

    private onDailyWageReportDelete(reportId: number | undefined) {
        this.dailyWageReportDialogVisible = false;
        this.dailyWageReportToEdit = null;
        this.dailyWageReports = this.dailyWageReports.filter((report) => report.id !== reportId);
    }

}
