

import { Component, Vue } from 'vue-property-decorator';
import availableRoutes from '@/plugins/availableRoutes';
import { User } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Loading from '@/components/Loading.vue';
import DeleteUserDialog from '@/components/dialogs/DeleteUserDialog.vue';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import InputButton from '@/components/input/InputButton.vue';
import InputSelect from '@/components/input/InputSelect.vue';
import InputDefault from '@/components/input/InputDefault.vue';

@Component({
    components: {
        InputDefault,
        InputSelect,
        InputButton,
        DeleteUserDialog,
        Loading,
    },
})
export default class NewUser extends Vue {

    private ROLE = ROLE;

    private showRoles: boolean = false;
    private newUser: boolean = true;
    private passwordCheck: string = '';
    private displayedRole: string = '';

    private user: User = {};

    private showErrors: boolean = false;
    private errors: { [key: string]: string } = {};
    private loading: boolean = true;

    private routeBack: string = availableRoutes.userManagement;

    private deleteUserDialogVisible: boolean = false;
    private knownAbbreviations: string[] = [];
    private manualAbbreviation: boolean = false;
    private manualLogin: boolean = false;

    private submitted: boolean = false;
    private success: boolean = false;

    private roleInputKey: number = 0;

    private mounted() {
        this.checkRole();
        if (this.$route.params.userId !== undefined) {
            this.newUser = false;
            this.loadExistingUser(Number.parseInt(this.$route.params.userId, 10));
        } else {
            this.user.abbreviation = '';
            this.user.active = true;
            this.user.password = '';
            this.loading = false;
        }
        this.loadKnownAbbreviations();
        this.$forceUpdate();
    }

    private async saveUser() {
        this.submitted = true;
        this.showErrors = false;
        this.errors = {};
        if (this.user.password !== this.passwordCheck) {
            this.showErrors = true;
            this.submitted = false;
            this.errors.passwordCheck = 'Die Passwörter stimmen nicht überein.';
        } else {
            try {
                const result = await Api.omb.userPost(this.user);
                if (result.error) {
                    console.log('the server returned an error message: ', result);
                    if (result.problems !== undefined) {
                        this.submitted = false;
                        this.showErrors = true;
                        this.errors = result.problems;
                    }
                } else {
                    this.success = true;
                    await this.delay(1000);
                    this.$router.push({ name: availableRoutes.userManagement });
                }
            } catch (e: any) {
                if (e.status === 403) {
                    this.$router.push({ name: availableRoutes.features });
                } else if (e.status === 401) {
                    Api.initServices('');
                    this.$router.push({ name: availableRoutes.login });
                }
            }
        }
    }

    private updateAbbreviation() {
        switch (this.user.role) {
            case ROLE.ADMINISTRATOR:
            case ROLE.PLANNING:
            case ROLE.ASSEMBLY:
            case ROLE.EASSEMBLY:
                if (!this.user.abbreviation || this.user.abbreviation === '-') {
                    this.fillAbbreviationAndLogin();
                }
                break;
            default:
                this.user.abbreviation = '';
        }
    }

    private async loadExistingUser(userId: number) {
        try {
            this.user = await Api.omb.userIdGet(userId);
        } catch (e: any) {
            if (e.status === 403) {
                this.$router.push({ name: availableRoutes.features });
            } else if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
        this.updateAbbreviation();
        this.loading = false;
    }

    private async lockUser() {
        try {
            if (this.user.id !== undefined) {
                const serverUser: User = await Api.omb.userIdGet(this.user.id);
                serverUser.active = false;
                const response = await Api.omb.userPost(serverUser);
                if (response.error && response.problems !== undefined) {
                    this.errors = response.problems;
                    this.showErrors = true;
                } else {
                    this.user.active = false;
                }
            }
        } catch (e: any) {
            if (e.status === 403) {
                this.$router.push({ name: availableRoutes.features });
            } else if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private async unlockUser() {
        try {
            if (this.user.id !== undefined) {
                const serverUser: User = await Api.omb.userIdGet(this.user.id);
                serverUser.active = true;
                const response = await Api.omb.userPost(serverUser);
                if (response.error && response.problems !== undefined) {
                    console.log('the server returned an error message: ', response);
                    this.errors = response.problems;
                    this.showErrors = true;
                } else {
                    this.user.active = true;
                }
            }
        } catch (e: any) {
            if (e.status === 403) {
                this.$router.push({ name: availableRoutes.features });
            } else if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private showDeleteUserDialog() {
        this.deleteUserDialogVisible = true;
    }

    private hideDeleteUserDialog() {
        this.deleteUserDialogVisible = false;
    }

    private async loadKnownAbbreviations() {
        this.knownAbbreviations = await Api.omb.allAbbreviationsGet();
        this.knownAbbreviations.forEach((anAbbreviation: string, index: number) => {
            if (this.knownAbbreviations[index] !== null) {
                this.knownAbbreviations[index] = this.knownAbbreviations[index].toLowerCase();
            }
        });
        // filter out current abbreviation so autofill does not generate a new one
        if (!this.newUser && this.user.abbreviation !== undefined && this.knownAbbreviations.indexOf(this.user.abbreviation.toLowerCase()) !== -1) {
            this.knownAbbreviations.splice(this.knownAbbreviations.indexOf(this.user.abbreviation.toLowerCase()), 1);
        }
    }

    private fillAbbreviationAndLogin() {
        if (!this.manualAbbreviation && this.user.firstName !== undefined && this.user.firstName !== '' && this.user.name !== undefined && this.user.name !== ''
            && [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY].includes(this.user.role as ROLE)) {
            let newAbbreviation: string = this.user.firstName.charAt(0) + this.user.name.charAt(0);
            newAbbreviation = newAbbreviation.toUpperCase();
            let appendedNumber: number = 2;
            while (this.knownAbbreviations.indexOf(newAbbreviation.toLowerCase()) >= 0) {
                newAbbreviation = newAbbreviation.charAt(0) + newAbbreviation.charAt(1) + appendedNumber;
                appendedNumber += 1;
            }
            this.user.abbreviation = newAbbreviation;
            this.$forceUpdate();
        }
        if (!this.manualLogin && this.user.firstName !== undefined && this.user.firstName !== '' && this.user.name !== undefined && this.user.name !== '' && this.newUser) {
            const editedFirstName: string = this.user.firstName.replaceAll('-', ' ')
                .split(' ')[0].toLowerCase();
            const editedLastName: string = this.user.name.replaceAll('-', ' ')
                .split(' ')[0].toLowerCase();
            this.user.login = `${editedFirstName}.${editedLastName}`;
            this.$forceUpdate();
        }
    }

    private delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    private async checkRole() {
        const role = await getRole();
        if (role !== ROLE.ADMINISTRATOR) {
            this.$router.push({ name: availableRoutes.features });
        }
    }

    private setShowRoles(value: boolean) {
        this.showRoles = value;
        this.$nextTick(() => {
            this.roleInputKey += 1;
        });
    }
}
