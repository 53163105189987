

import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Project, ProjectGroup, Upload } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import moment, { Moment } from 'moment';
import Loading from '@/components/Loading.vue';
import InputButton from '@/components/input/InputButton.vue';
import InputSelect from '@/components/input/InputSelect.vue';
import UploadManager from '@/components/UploadManager.vue';
import EditProjectGroupDialog from '@/components/dialogs/EditProjectGroupDialog.vue';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import InputDefault from '@/components/input/InputDefault.vue';
import AlertError from '@/components/AlertError.vue';
import BaseDialog from './BaseDialog.vue';

@Component({
    components: {
        AlertError,
        InputDefault,
        InputCheckbox,
        EditProjectGroupDialog,
        UploadManager,
        InputSelect,
        InputButton,
        Loading,
        BaseDialog,
    },
})
export default class EditProjectDialog extends Vue {

    @Prop()
    private projectGroups?: ProjectGroup[];

    @Prop({ default: null })
    private givenProject?: Project | null;

    @Prop({ default: () => [] })
    private givenUploads!: Upload[];

    private groups: ProjectGroup[] = [];
    private project: Project = {};
    private uploads: Upload[] = [];

    private ready = false;

    private steel: boolean = false;
    private plastics: boolean = false;
    private doors: boolean = false;
    private facades: boolean = false;
    private windows: boolean = false;
    private tin: boolean = false;
    private assembly: boolean = false;
    private eassembly: boolean = false;

    private showErrors: boolean = false;
    private errors: { [key: string]: string } = {};
    private submitted: boolean = false;
    private success: boolean = false;
    private generalError = false;

    private toggleToUpdate: boolean = false;

    private newProjectGroupDialogVisible = false;

    @Watch('assembly')
    private onAssemblyChange() {
        if (!this.ready) return;

        if (this.assembly && !this.eassembly) {
            this.project.durationInstallation = 1;
        } else if (!this.assembly && !this.eassembly) {
            this.project.durationInstallation = 0;
        }
    }

    @Watch('eassembly')
    private onEassemblyChange() {
        if (!this.ready) return;

        if (!this.assembly && this.eassembly) {
            this.project.durationInstallation = 1;
        } else if (!this.assembly && !this.eassembly) {
            this.project.durationInstallation = 0;
        }
    }

    private get madeProgress() {
        return this.project.cut || this.project.completed || this.project.connectionsComplete || this.project.glasComplete || this.project.assemblyComplete || this.project.operating;
    }

    private get displayedDurationInstallation() {
        if (!this.assembly && !this.eassembly) {
            return '';
        }
        this.toggleToUpdate = !this.toggleToUpdate;
        return this.project.durationInstallation;
    }

    private get title(): string {
        if (this.project.id) return 'Bauabschnitt bearbeiten';
        return 'Neuen Bauabschnitt erstellen';
    }

    private async created() {
        if (this.projectGroups) {
            this.groups = this.projectGroups;
        } else {
            await this.loadProjectGroups();
        }

        if (this.givenProject) {
            this.project = this.givenProject;
            if (!this.givenUploads.length && this.project.id) {
                this.uploads = await Api.omb.getProjectUploads(this.project.id);
            } else {
                this.uploads = this.givenUploads;
            }
        } else {
            this.assembly = true;
            this.project.durationInstallation = 0;
            this.project.beginInstallation = '0';
            this.project.beginProduction = '0';
        }
        this.project.planned = this.project.id ? this.project.planned : true;

        this.adjustDepartmentsToExistingValue();

        this.$nextTick(() => this.ready = true);
    }

    private async loadProjectGroups() {
        this.groups = await Api.omb.getAllProjectGroups();
    }

    private onPlannedChange(planned: boolean) {
        this.project.planned = !planned;
    }

    private close() {
        this.$emit('close');
    }

    private async submit() {
        this.submitted = true;
        this.showErrors = false;
        this.generalError = false;

        this.project.departments = '';
        if (this.steel) {
            this.project.departments = this.project.departments.concat('Stahlabteilung');
        }
        if (this.plastics) {
            if (this.project.departments !== '') {
                this.project.departments = this.project.departments.concat(', ');
            }
            this.project.departments = this.project.departments.concat('Kunststoffabteilung');
        }
        if (this.doors) {
            if (this.project.departments !== '') {
                this.project.departments = this.project.departments.concat(', ');
            }
            this.project.departments = this.project.departments.concat('Türenabteilung');
        }
        if (this.facades) {
            if (this.project.departments !== '') {
                this.project.departments = this.project.departments.concat(', ');
            }
            this.project.departments = this.project.departments.concat('Fassadenabteilung');
        }
        if (this.windows) {
            if (this.project.departments !== '') {
                this.project.departments = this.project.departments.concat(', ');
            }
            this.project.departments = this.project.departments.concat('Fensterabteilung');
        }
        if (this.tin) {
            if (this.project.departments !== '') {
                this.project.departments = this.project.departments.concat(', ');
            }
            this.project.departments = this.project.departments.concat('Blechabteilung');
        }
        if (this.assembly) {
            if (this.project.departments !== '') {
                this.project.departments = this.project.departments.concat(', ');
            }
            this.project.departments = this.project.departments.concat('Montage');
        }
        if (this.eassembly) {
            if (this.project.departments !== '') {
                this.project.departments = this.project.departments.concat(', ');
            }
            this.project.departments = this.project.departments.concat('E-Montage');
        }

        this.project.toCoater = false;
        this.project.fromCoater = false;

        try {
            const response = await Api.omb.projectPost(this.project);
            if (!response.error) {
                const updatedProject = response.data ? response.data as Project : this.project;
                const savedUploads = await Api.omb.setProjectUploads(updatedProject.id || 0, this.uploads);
                this.success = true;
                this.$emit('update', updatedProject, savedUploads);
                this.close();
            } else if (response.problems !== undefined) {
                this.submitted = false;
                this.errors = response.problems;
                this.showErrors = true;
                this.scrollUp();
            }
        } catch (e) {
            console.error(e);
            this.submitted = false;
            this.success = false;
            this.generalError = true;
        }
    }

    private adjustDepartmentsToExistingValue() {
        if (this.project.departments !== undefined) {
            if (this.project.departments?.indexOf('Stahlabteilung') > -1) {
                this.steel = true;
            }
            if (this.project.departments?.indexOf('Kunststoffabteilung') > -1) {
                this.plastics = true;
            }
            if (this.project.departments?.indexOf('Türenabteilung') > -1) {
                this.doors = true;
            }
            if (this.project.departments?.indexOf('Fassadenabteilung') > -1) {
                this.facades = true;
            }
            if (this.project.departments?.indexOf('Fensterabteilung') > -1) {
                this.windows = true;
            }
            if (this.project.departments?.indexOf('Blechabteilung') > -1) {
                this.tin = true;
            }
            if (this.project.departments && (this.project.departments.toLowerCase()
                .includes(', montage') || this.project.departments.toLowerCase()
                .startsWith('montage'))) {
                this.assembly = true;
            }
            if (this.project.departments?.indexOf('E-Montage') > -1) {
                this.eassembly = true;
            }
        }
    }

    private calculateDurations() {
        if (this.project !== undefined) {
            const { project } = this;
            if (project && project.beginProduction && project.beginInstallationNew) {
                const beginProduction: Moment = moment();
                beginProduction.year(Number.parseInt(project.beginProduction.split('/')[1], 10));
                beginProduction.isoWeek(Number.parseInt(project.beginProduction.split('/')[0], 10));
                beginProduction.isoWeekday(1);
                const beginInstallation: Moment = moment();
                beginInstallation.year(Number.parseInt(project.beginInstallationNew.split('/')[1], 10));
                beginInstallation.isoWeek(Number.parseInt(project.beginInstallationNew.split('/')[0], 10));
                beginInstallation.isoWeekday(1);
                if (beginProduction.isValid() && beginInstallation.isValid() && project.beginProduction.charAt(2) === '/' && project.beginInstallationNew.charAt(2) === '/') {
                    this.project.durationProduction = beginInstallation.diff(beginProduction, 'weeks');
                } else {
                    this.project.durationProduction = 0;
                    console.log('could not read beginProduction and/or beginInstallationNew, therefore setting durationProduction to 0.');
                }
            }
            this.reinitializeDurationInstallation();
        }
    }

    private onBeginInstallationChange(): void {
        this.project.beginInstallationNew = this.project.beginInstallation;
        this.calculateDurations();
        this.updateDurationInstallation();
    }

    private duplicateProject() {
        this.$emit('duplicate', this.project);
    }

    private scrollUp() {
        const element = document.getElementById('scrollContainer');
        if (element) {
            element.scrollTo(0, 0);
        }
    }

    private updateDurationInstallation() {
        const element = document.querySelector('#newProjectDurationInstallation');
        if (!!element && this.instanceOfHTMLInputElement(element)) {
            this.project.durationInstallation = Number.parseInt(element.value, 10);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private instanceOfHTMLInputElement(object: any): object is HTMLInputElement {
        return 'value' in object;
    }

    private reinitializeDurationInstallation() {
        if (this.project.durationInstallation === 0 || this.project.durationInstallation === undefined || this.project.durationInstallation === 1 || Number.isNaN(this.project.durationInstallation)) {
            if (!this.assembly && !this.eassembly) {
                this.project.durationInstallation = 0;
            } else {
                this.project.durationInstallation = 1;
            }
            this.toggleToUpdate = !this.toggleToUpdate;
        }
    }

    private showNewProjectGroupDialog() {
        this.newProjectGroupDialogVisible = true;
    }

    private hideNewProjectGroupDialog() {
        this.newProjectGroupDialogVisible = false;
    }
}
