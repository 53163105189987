

import { Component, Prop, Vue } from 'vue-property-decorator';
import Collapsable from '@/components/Collapsable.vue';
import { ProjectGroup } from '@/clients/omb-api';

@Component({
    components: {
        Collapsable,
    },
})
export default class ProjectGroupDetailsTopInfo extends Vue {

    @Prop({ required: true })
    private projectGroup!: ProjectGroup;

}
