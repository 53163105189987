
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectGroup, Repair } from '@/clients/omb-api';

declare let google: any;

@Component
export default class ProjectGroupsAndRepairsMap extends Vue {

    @Prop()
    private projectGroups!: ProjectGroup[];

    @Prop()
    private repairs!: Repair[];

    @Prop({ default: true })
    private legend!: boolean;

    @Prop()
    private currentLocation!: { lat: number; lng: number; };

    private mapId = `${Math.random()}`.replace('.', '');

    private markers: any[] = [];

    private projectGroupPin = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.749,7.3,12.286,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Zm6.97,9.672A.5.5,0,0,1,18.5,10h-1a.5.5,0,0,0-.5.5V15a.5.5,0,0,1-.5.5H14a.5.5,0,0,1-.5-.5V12.75a1.5,1.5,0,0,0-3,0V15a.5.5,0,0,1-.5.5H7.5A.5.5,0,0,1,7,15V10.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.323-.882l6.5-5.5a.5.5,0,0,1,.647,0l6.5,5.5A.5.5,0,0,1,18.97,9.672Z"/></svg>';

    private repairPin = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.748,7.3,12.285,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Zm1.811,7.982L15.189,6.6a.5.5,0,0,0,0-.708l-.043-.042a.506.506,0,0,1-.144-.4.5.5,0,0,1,.221-.367l1.5-1a.5.5,0,0,1,.631.062l1,1a.5.5,0,0,1,.062.631l-1,1.5a.5.5,0,0,1-.367.221L17,7.5a.5.5,0,0,1-.354-.146L16.6,7.311a.5.5,0,0,0-.708,0L14.518,8.689a.5.5,0,0,1-.707-.707Zm-2.872,5.786L9.164,15.543a1.561,1.561,0,0,1-2.207-2.207l1.775-1.775a.5.5,0,0,1,.707,0l1.5,1.5a.5.5,0,0,1,0,.707ZM18.4,14.776a.5.5,0,0,1-.836.225L16.28,13.72a.768.768,0,0,0-1.06,0,.748.748,0,0,0,0,1.06l1.28,1.282a.5.5,0,0,1-.225.836A3,3,0,0,1,12.5,14a2.83,2.83,0,0,1,.038-.458.5.5,0,0,0-.139-.436l-3-3a.5.5,0,0,0-.437-.14A3,3,0,0,1,5.6,6.224.5.5,0,0,1,6.438,6L7.72,7.28a.768.768,0,0,0,1.06,0,.748.748,0,0,0,0-1.06L7.5,4.938A.5.5,0,0,1,7.725,4.1,3,3,0,0,1,11.5,7a2.83,2.83,0,0,1-.038.458.5.5,0,0,0,.139.436l3,3a.5.5,0,0,0,.437.139A3,3,0,0,1,18.4,14.776Z"/></svg>';

    private multipleEntriesPin = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,0C6.21,0,1.5,4.262,1.5,9.5c0,5.749,7.3,12.286,9.54,14.152a1.5,1.5,0,0,0,1.921,0C15.2,21.784,22.5,15.246,22.5,9.5,22.5,4.262,17.79,0,12,0Zm6.97,9.672A.5.5,0,0,1,18.5,10h-1a.5.5,0,0,0-.5.5V15a.5.5,0,0,1-.5.5H14a.5.5,0,0,1-.5-.5V12.75a1.5,1.5,0,0,0-3,0V15a.5.5,0,0,1-.5.5H7.5A.5.5,0,0,1,7,15V10.5a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,1-.323-.882l6.5-5.5a.5.5,0,0,1,.647,0l6.5,5.5A.5.5,0,0,1,18.97,9.672Z"/></svg>';

    private repairColors: string[] = [
        'gray-400',
        'success',
        'warning',
        'critical',
    ];

    private mounted(): void {
        const map = new google.maps.Map(
            document.getElementById('map') as HTMLElement,
            {
                mapId: this.mapId,
                streetViewControl: false,
                mapTypeControl: false,
                gestureHandling: 'cooperative',
            },
        );

        const styledMapType = new google.maps.StyledMapType(
            [
                {
                    'featureType': 'poi',
                    'stylers': [
                        {
                            'visibility': 'off',
                        },
                    ],
                },
                {
                    'featureType': 'transit',
                    'stylers': [
                        {
                            'visibility': 'off',
                        },
                    ],
                },
            ],
            { name: 'Styled Map' },
        );
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');

        const currentLocationMarker = this.loadCurrentLocationMarker(map);

        let entries: (ProjectGroup | Repair)[] = [];
        if (this.projectGroups) entries = entries.concat(this.projectGroups);
        if (this.repairs) entries = entries.concat(this.repairs);

        const markerGroups = this.groupProjectGroupsAndRepairs(entries);
        markerGroups.forEach((mg) => {
            this.markers.push(this.loadMarkerFromGroup(map, mg));
        });

        const bounds = new google.maps.LatLngBounds();
        if (currentLocationMarker) bounds.extend(currentLocationMarker.position);
        this.markers.forEach((m) => {
            bounds.extend(m.position);
        });
        map.fitBounds(bounds);
    }

    private loadCurrentLocationMarker(map: any): any {
        if (!this.currentLocation) return undefined;

        const markerHtml = document.createElement('div');
        markerHtml.setAttribute('class', 'w-6 h-6 rounded-full bg-red-600 transform translate-y-1/2');

        return new google.maps.marker.AdvancedMarkerView({
            map: map,
            position: {
                lat: this.currentLocation.lat,
                lng: this.currentLocation.lng,
            },
            content: markerHtml,
            title: 'Ausgangspunkt',
        });
    }

    private groupProjectGroupsAndRepairs(entries: (ProjectGroup | Repair)[]): (ProjectGroup | Repair)[][] {
        const grouped: (ProjectGroup | Repair)[][] = [];
        entries.forEach((e) => {
            if (!e.lat || !e.lng) return;

            let found = false;
            for (let i = 0; i < grouped.length; i += 1) {
                const group = grouped[i];
                if (group.length && group[0].lat === e.lat && group[0].lng === e.lng) {
                    grouped[i].push(e);
                    found = true;
                }
            }

            if (!found) {
                grouped.push([e]);
            }
        });

        return grouped;
    }

    private loadMarkerFromGroup(map: any, entries: (ProjectGroup | Repair)[]): any {
        const markerHtml = this.getMarkerPinFromGroup(entries);
        const markerContent = document.createElement('div');
        markerContent.innerHTML = markerHtml;

        const marker = new google.maps.marker.AdvancedMarkerView({
            map: map,
            position: {
                lat: entries[0].lat,
                lng: entries[0].lng,
            },
            content: markerContent,
            title: this.getMarkerTitleFromGroup(entries),
        });

        const infoWindow = this.getInfoWindowFromGroup(entries);

        const openInfoWindowFunc = () => {
            infoWindow.open({
                anchor: marker,
                map,
            });
        };

        const touchStartFunc = (event: TouchEvent) => {
            const target = event.target as HTMLElement;
            target.setAttribute('touchStartX', `${event.changedTouches[0].clientX}`);
            target.setAttribute('touchStartY', `${event.changedTouches[0].clientY}`);
        };

        const touchEndFunc = (event: TouchEvent) => {
            const target = event.target as HTMLElement;
            const touchStartX = parseInt(target.getAttribute('touchStartX') || '0', 10);
            const touchStartY = parseInt(target.getAttribute('touchStartY') || '0', 10);
            target.removeAttribute('touchStartX');
            target.removeAttribute('touchStartY');
            if (Math.abs(touchStartX - event.changedTouches[0].clientX) < 50 && Math.abs(touchStartY - event.changedTouches[0].clientY) < 50) {
                openInfoWindowFunc();
            }
        };

        marker.addEventListener('click', openInfoWindowFunc);
        marker.addEventListener('touchstart', touchStartFunc);
        marker.addEventListener('touchend', touchEndFunc);

        return marker;
    }

    private getMarkerPinFromGroup(group: (ProjectGroup | Repair)[]): string {
        const markerHtml = document.createElement('div');
        markerHtml.setAttribute('class', 'relative pin-bg pointer-events-none');

        if (group.length > 1) {
            markerHtml.innerHTML = this.multipleEntriesPin;

            const counter = document.createElement('div');
            counter.setAttribute('class', 'inline-flex absolute bg-primary rounded-full items-center justify-center text-white text-lg');
            counter.setAttribute('style', 'content: "";\n'
                + '    top: 4px;\n'
                + '    bottom: 8px;\n'
                + '    left: 4px;\n'
                + '    right: 4px;\n');
            counter.innerHTML = `${group.length}`;

            markerHtml.append(counter);

            const pin = markerHtml.querySelector('svg') as SVGElement;
            pin.setAttribute('class', 'w-10 h-10 overflow-visible fill-primary');

            return markerHtml.outerHTML;
        }

        if ((group[0] as ProjectGroup).number) {
            markerHtml.innerHTML = this.projectGroupPin;

            const pin = markerHtml.querySelector('svg') as SVGElement;
            pin.setAttribute('class', 'w-10 h-10 overflow-visible fill-neutral-600');

            return markerHtml.outerHTML;
        }

        markerHtml.innerHTML = this.repairPin;

        const pin = markerHtml.querySelector('svg') as SVGElement;
        pin.setAttribute('class', `w-10 h-10 overflow-visible fill-${this.repairColors[(group[0] as Repair).priority || 0]}`);

        return markerHtml.outerHTML;

    }

    private getMarkerTitleFromGroup(group: (ProjectGroup | Repair)[]): string {
        if (group.length > 1) {
            return 'Mehrere Einträge';
        }

        if ((group[0] as ProjectGroup).number) {
            return `[Bauvorhaben] ${group[0].name}`;
        }

        return `[Reparatur] ${group[0].name}`;
    }

    private getInfoWindowFromGroup(group: (ProjectGroup | Repair)[]): any {
        const spacer = '<div class="w-full border-t-default border-black my-2"></div>';

        const infoTexts: string[] = [];
        group.forEach((g) => {
            infoTexts.push(this.getSingleInfoText(g));
        });

        const content = infoTexts.join(spacer);

        return new google.maps.InfoWindow({
            content: content,
            ariaLabel: '',
        });
    }

    private getSingleInfoText(target: Repair | ProjectGroup): string {
        const info = document.createElement('div');

        const tag = document.createElement('div');
        tag.setAttribute('class', `rounded-sm inline-block px-1 py-px mb-px text-white bg-${(target as ProjectGroup).number ? 'gray-600' : this.repairColors[(target as Repair).priority || 0]}`);
        tag.innerHTML = (target as ProjectGroup).number ? 'Bauvorhaben' : 'Reparatur';

        const parts: string[] = [];
        parts.push(tag.outerHTML);
        if ((target as ProjectGroup).number) parts.push((target as ProjectGroup).number || '');
        parts.push(target.name || '');
        if ((target as Repair).scope) parts.push((target as Repair).scope || '');
        parts.push(`${target.zipCode || ''} ${target.city || ''}`);

        info.innerHTML = parts.join('<br>');

        return info.outerHTML;
    }

}
