

import { Component, Prop, Vue } from 'vue-property-decorator';
import Api from '@/assets/ts/Api';
import availableRoutes from '@/plugins/availableRoutes';
import InputButton from '@/components/input/InputButton.vue';
import BaseDialog from './BaseDialog.vue';

@Component({
    components: { InputButton, BaseDialog },
})
export default class DeleteUserDialog extends Vue {

    @Prop({ default: '' })
    private username!: string;

    @Prop({ default: 0 })
    private userId!: number

    private showErrors: boolean = false;
    private errors: { [key: string]: string } = {};

    private async deleteUser() {
        try {
            const response = await Api.omb.userIdDelete(this.userId);
            if (response.error && response.problems !== undefined) {
                this.errors = response.problems;
                this.showErrors = true;
            } else {
                this.$router.push({ name: availableRoutes.userManagement });
            }
        } catch (e: any) {
            if (e.status === 403) {
                this.$router.push({ name: availableRoutes.features });
            } else if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }
}
