import Vue from 'vue';

Vue.component('arrow_down_icon', () => import('../assets/icons/arrow_down.svg'));
Vue.component('bin_icon', () => import('../assets/icons/bin.svg'));
Vue.component('camera_icon', () => import('../assets/icons/camera.svg'));
Vue.component('check_icon', () => import('../assets/icons/check.svg'));
Vue.component('checkbox_checked_icon', () => import('../assets/icons/checkbox_checked.svg'));
Vue.component('checkbox_unchecked_icon', () => import('../assets/icons/checkbox_unchecked.svg'));
Vue.component('chevron_down_icon', () => import('../assets/icons/chevron_down.svg'));
Vue.component('clock_icon', () => import('../assets/icons/clock.svg'));
Vue.component('collapse_icon', () => import('../assets/icons/collapse.svg'));
Vue.component('current_location_christmas_pin_icon', () => import('../assets/icons/current_location_christmas_pin.svg'));
Vue.component('current_location_pin_icon', () => import('../assets/icons/current_location_pin.svg'));
Vue.component('delete_icon', () => import('../assets/icons/delete.svg'));
Vue.component('door_icon', () => import('../assets/icons/door.svg'));
Vue.component('download_icon', () => import('../assets/icons/download_bottom.svg'));
Vue.component('edit_icon', () => import('../assets/icons/edit.svg'));
Vue.component('edit_square_icon', () => import('../assets/icons/edit_square.svg'));
Vue.component('extend_icon', () => import('../assets/icons/extend.svg'));
Vue.component('facade_icon', () => import('../assets/icons/facade.svg'));
Vue.component('file_empty_icon', () => import('../assets/icons/file_empty.svg'));
Vue.component('file_image_icon', () => import('../assets/icons/file_image.svg'));
Vue.component('file_text_icon', () => import('../assets/icons/file_text.svg'));
Vue.component('file_video_icon', () => import('../assets/icons/file_video.svg'));
Vue.component('file_zip_icon', () => import('../assets/icons/file_zip.svg'));
Vue.component('filter_icon', () => import('../assets/icons/filter.svg'));
Vue.component('info_circle_icon', () => import('../assets/icons/info_circle.svg'));
Vue.component('info_pointer_icon', () => import('../assets/icons/info_pointer.svg'));
Vue.component('location_icon', () => import('../assets/icons/location.svg'));
Vue.component('lock_icon', () => import('../assets/icons/lock.svg'));
Vue.component('login-background', () => import('../assets/icons/login_background.svg'));
Vue.component('login_picture', () => import('../assets/icons/login_picture.svg'));
Vue.component('logo-white', () => import('../assets/icons/logo_white.svg'));
Vue.component('logo-with-text', () => import('../assets/icons/logo_with_text.svg'));
Vue.component('logout_icon', () => import('../assets/icons/logout_icon.svg'));
Vue.component('mail_icon', () => import('../assets/icons/mail_icon.svg'));
Vue.component('pie_line_graph_desktop_icon', () => import('../assets/icons/pie-line-graph-desktop.svg'));
Vue.component('plan_icon', () => import('../assets/icons/plan.svg'));
Vue.component('plastics_icon', () => import('../assets/icons/plastics.svg'));
Vue.component('plus_icon', () => import('../assets/icons/plus.svg'));
Vue.component('print_icon', () => import('../assets/icons/print.svg'));
Vue.component('project_blueprint_home_icon', () => import('../assets/icons/project-blueprint-home.svg'));
Vue.component('project_group_pin_icon', () => import('../assets/icons/project_group_pin.svg'));
Vue.component('project_notes_house_icon', () => import('../assets/icons/project-notes-house.svg'));
Vue.component('radiobutton_checked_icon', () => import('../assets/icons/radiobutton_checked.svg'));
Vue.component('radiobutton_unchecked_icon', () => import('../assets/icons/radiobutton_unchecked.svg'));
Vue.component('reload_icon', () => import('../assets/icons/reload.svg'));
Vue.component('repair_pin_icon', () => import('../assets/icons/repair_pin.svg'));
Vue.component('robot_icon', () => import('../assets/icons/robot.svg'));
Vue.component('search_icon', () => import('../assets/icons/search.svg'));
Vue.component('signature_icon', () => import('../assets/icons/pen_write.svg'));
Vue.component('steel_icon', () => import('../assets/icons/steel.svg'));
Vue.component('task_list_sync_icon', () => import('../assets/icons/task-list-sync.svg'));
Vue.component('tin_icon', () => import('../assets/icons/tin.svg'));
Vue.component('undo_circle_icon', () => import('../assets/icons/undo_circle.svg'));
Vue.component('undo_icon', () => import('../assets/icons/undo.svg'));
Vue.component('upload_icon', () => import('../assets/icons/upload.svg'));
Vue.component('users_icon', () => import('../assets/icons/users.svg'));
Vue.component('video_settings', () => import('../assets/icons/video-settings.svg'));
Vue.component('window_icon', () => import('../assets/icons/window.svg'));
Vue.component('wrench_icon', () => import('../assets/icons/wrench.svg'));
Vue.component('x_icon', () => import('../assets/icons/x.svg'));
