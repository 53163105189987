import {
    Configuration as DefaultApiConfig,
    DefaultApi,
} from '../../clients/omb-api';

export default class Api {
    public static apiURL: string = process.env.VUE_APP_API_URL || '';
    public static omb: DefaultApi = new DefaultApi(new DefaultApiConfig({
        accessToken: Api.getToken(),
        basePath: Api.apiURL,
    }));

    public static initServices(token: string): void {
        Api.resetRole();
        localStorage.setItem('OMB_TOKEN', token);
        this.omb = new DefaultApi(new DefaultApiConfig({
            accessToken: token,
            basePath: Api.apiURL,
        }));
    }

    public static getToken(): string {
        const token = localStorage.getItem('OMB_TOKEN');
        if (token !== null) {
            return token;
        }
        return '';
    }

    public static getApiUrl(): string {
        return Api.apiURL;
    }

    private static resetRole(): void {
        localStorage.removeItem('OMB_ROLE');
    }
}
