// eslint-disable-next-line import/prefer-default-export
export enum HISTORY_EVENT {

    PROJECT_PRODUCTION_COMPLETE = 'project_production_complete',
    PROJECT_ASSEMBLY_COMPLETE = 'project_assembly_complete',
    PROJECT_OPERATING = 'project_operating',
    REPAIR_CREATED = 'repair_created',
    REPAIR_PROCESSING = 'repair_processing',
    REPAIR_COMPLETE = 'repair_complete',

}
