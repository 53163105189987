

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Collapsable extends Vue {

    @Prop({ default: '' })
    private title!: string;

    @Prop({ default: false })
    private open!: boolean;

    @Prop({ default: false })
    private smaller!: boolean;

    @Prop({ default: 1 })
    private border!: number;

    private ready = false;
    private expanded = this.open;
    private relative = this.open;
    private expandedContentHeight = -1;
    private currentContentHeight = 0;

    private resizing = false;
    private resizeTimeout?: any;

    private mounted() {
        window.addEventListener('resize', this.getContentHeight);
        this.getContentHeight();
        setTimeout(() => this.ready = true, 200);
    }

    private getContentHeight() {
        this.resizing = true;
        this.expandedContentHeight = -1;
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            const content = this.$refs.content as HTMLDivElement;
            if (content) {
                this.expandedContentHeight = content.clientHeight;
            }
            if (this.expanded) this.currentContentHeight = this.expandedContentHeight;
            this.resizing = false;
        }, 100);
    }

    private toggle() {
        if (!this.expanded) {
            this.currentContentHeight = this.expandedContentHeight;
            setTimeout(() => this.relative = true, 500);
        } else {
            const content = this.$refs.content as HTMLDivElement;
            if (content) {
                this.expandedContentHeight = content.clientHeight;
            }
            if (this.expanded) this.currentContentHeight = this.expandedContentHeight;
            this.relative = false;
            setTimeout(() => this.currentContentHeight = 0, 100);
        }
        this.expanded = !this.expanded;
    }

}
