

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Loading extends Vue {

    // page, button
    @Prop({ default: 'page' })
    private size!: string;

    // primary, white
    @Prop({ default: 'primary' })
    private colour!: string;

    @Prop({ default: false })
    private completed!: boolean

}
