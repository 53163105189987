

import { Component, Prop, Vue } from 'vue-property-decorator';
import AlertError from '@/components/AlertError.vue';
import { WrappedError } from '@/types/WrappedError';

@Component({
    components: { AlertError },
})
export default class AlertErrorDetails extends Vue {

    @Prop()
    private error?: WrappedError;

    private errorDetailsVisible = false;

}
