

import { Component, Vue } from 'vue-property-decorator';
import { User, UserPage } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Pagination from '@/components/Pagination.vue';
import availableRoutes from '@/plugins/availableRoutes';
import Loading from '@/components/Loading.vue';
import { ROLE } from '@/assets/ts/enum/role';
import InputButton from '@/components/input/InputButton.vue';

@Component({
    components: {
        InputButton,
        Loading,
        Pagination,
    },
})
export default class UserManagement extends Vue {

    private ROLE = ROLE;

    private currentPage: number = 1;
    private entriesPerPage: number = 10;
    private totalPages: number = 1;
    private searchTerm: string = '';
    private users: User[] = [];
    private loading: boolean = true;

    private mounted() {
        this.search();
    }

    private async search() {
        try {
            const response: UserPage = await Api.omb.userGet(this.currentPage - 1, this.entriesPerPage, this.searchTerm);
            if (response.totalPages !== undefined && response.content !== undefined) {
                console.log('the server returned an error message: ', response);
                this.users = response.content;
                this.totalPages = response.totalPages;
            }
        } catch (e: any) {
            if (e.status === 403) {
                this.$router.push({ name: availableRoutes.features });
            } else if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
        this.loading = false;
        this.$forceUpdate();
    }

    private goToNewUser() {
        this.$router.push({ name: availableRoutes.newUser });
    }

    private goToEditUser(userId: number) {
        const userIdString = userId.toString();
        this.$router.push({
            name: availableRoutes.editUser,
            params: { userId: userIdString },
        });
    }

    private goToPreviousPage() {
        if (this.currentPage > 0) {
            this.currentPage -= 1;
            this.search();
        }
    }

    private goToNextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage += 1;
            this.search();
        }
    }

    private goToPage(page: number) {
        this.currentPage = page;
        this.search();
    }

}
