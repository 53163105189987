/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DailyWageReportLine,
    DailyWageReportLineFromJSON,
    DailyWageReportLineFromJSONTyped,
    DailyWageReportLineToJSON,
} from './';

/**
 * 
 * @export
 * @interface DailyWageReport
 */
export interface DailyWageReport {
    /**
     * 
     * @type {number}
     * @memberof DailyWageReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReport
     */
    projectGroupId?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReport
     */
    repairId?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReport
     */
    number?: string;
    /**
     * 
     * @type {Date}
     * @memberof DailyWageReport
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DailyWageReport
     */
    end?: Date;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReport
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReport
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReport
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReport
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReport
     */
    manager?: string;
    /**
     * 
     * @type {Array<DailyWageReportLine>}
     * @memberof DailyWageReport
     */
    lines?: Array<DailyWageReportLine>;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReport
     */
    drivingDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReport
     */
    drivingDistance?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReport
     */
    material?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReport
     */
    fileId?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReport
     */
    createdBy?: number;
}

export function DailyWageReportFromJSON(json: any): DailyWageReport {
    return DailyWageReportFromJSONTyped(json, false);
}

export function DailyWageReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyWageReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectGroupId': !exists(json, 'projectGroupId') ? undefined : json['projectGroupId'],
        'repairId': !exists(json, 'repairId') ? undefined : json['repairId'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'manager': !exists(json, 'manager') ? undefined : json['manager'],
        'lines': !exists(json, 'lines') ? undefined : ((json['lines'] as Array<any>).map(DailyWageReportLineFromJSON)),
        'drivingDuration': !exists(json, 'drivingDuration') ? undefined : json['drivingDuration'],
        'drivingDistance': !exists(json, 'drivingDistance') ? undefined : json['drivingDistance'],
        'material': !exists(json, 'material') ? undefined : json['material'],
        'fileId': !exists(json, 'fileId') ? undefined : json['fileId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
    };
}

export function DailyWageReportToJSON(value?: DailyWageReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectGroupId': value.projectGroupId,
        'repairId': value.repairId,
        'number': value.number,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'name': value.name,
        'street': value.street,
        'zipCode': value.zipCode,
        'city': value.city,
        'manager': value.manager,
        'lines': value.lines === undefined ? undefined : ((value.lines as Array<any>).map(DailyWageReportLineToJSON)),
        'drivingDuration': value.drivingDuration,
        'drivingDistance': value.drivingDistance,
        'material': value.material,
        'fileId': value.fileId,
        'createdBy': value.createdBy,
    };
}


