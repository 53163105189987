
import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import { DailyWageReport, WeeklyReport } from '@/clients/omb-api';

@Component({
    components: { InputCheckbox },
})
export default class ReportCard extends Vue {

    @Prop()
    private weeklyReport?: WeeklyReport;

    @Prop()
    private dailyWageReport?: DailyWageReport;

    @Prop()
    private title?: string;

    @Prop()
    private action?: () => void;

    private get computedTitle(): string {
        if (this.title) return this.title;
        return this.weeklyReport?.week || this.dailyWageReport?.number || '-';
    }

    private doAction() {
        if (this.action) {
            this.action();
        } else {
            this.$emit('action');
        }
    }

}
