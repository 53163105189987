

import { Component, Prop, Vue } from 'vue-property-decorator';
import {
    DailyWageReport,
    Repair,
    User,
} from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import ReportCard from '@/components/ReportCard.vue';
import clone from 'clone';
import EditDailyWageReportDialog from '@/components/dialogs/EditDailyWageReportDialog.vue';
import EditWeeklyReportDialog from '@/components/dialogs/EditWeeklyReportDialog.vue';
import Loading from '@/components/Loading.vue';
import { ROLE } from '@/assets/ts/enum/role';

@Component({
    components: {
        Loading,
        EditWeeklyReportDialog,
        EditDailyWageReportDialog,
        ReportCard,
    },
})
export default class RepairDailyWageReports extends Vue {

    @Prop({ required: true })
    private currentUser!: User;

    @Prop({ required: true })
    private repair!: Repair;

    private loading = true;
    private dailyWageReports: DailyWageReport[] = [];

    private dailyWageReportDialogVisible = false;
    private dailyWageReportToEdit: DailyWageReport | null = null;

    private get hasDailyWageReportDraft(): boolean {
        return !!this.dailyWageReports?.find((report) => !report.fileId && report.createdBy === this.currentUser.id);
    }

    private get canEdit(): boolean {
        return !!this.currentUser.id && [ROLE.ADMINISTRATOR, ROLE.PLANNING, ROLE.ASSEMBLY, ROLE.EASSEMBLY].includes(this.currentUser.role as ROLE);
    }

    private async created(): Promise<void> {
        this.dailyWageReports = await Api.omb.getAllDailyWageReportsByRepair(this.repair.id || 0);
        this.loading = false;
    }

    private showNewDailyWageReportDialog() {
        this.dailyWageReportDialogVisible = true;
    }

    private editDailyWageReport(report: DailyWageReport) {
        this.dailyWageReportToEdit = clone(report);
        this.dailyWageReportDialogVisible = true;
    }

    private hideDailyWageReportDialog() {
        this.dailyWageReportDialogVisible = false;
        this.dailyWageReportToEdit = null;
    }

    private onDailyWageReportUpdate(report: DailyWageReport) {
        if (this.dailyWageReports.find((r) => r.id === report.id)) {
            this.dailyWageReports = this.dailyWageReports.map((r) => (r.id === report.id ? report : r));
        } else {
            this.dailyWageReports.push(report);
        }
    }

    private onDailyWageReportDelete(reportId: number | undefined) {
        this.dailyWageReportDialogVisible = false;
        this.dailyWageReportToEdit = null;
        this.dailyWageReports = this.dailyWageReports.filter((report) => report.id !== reportId);
    }

}
