

import {
    Component,
    Prop,
    Vue,
    Watch,
} from 'vue-property-decorator';
import { WeeklyReportLine } from '@/clients/omb-api';
import { WeeklyReportRole } from '@/assets/ts/enum/weekly_report_role';

@Component({
    components: {},
})
export default class WeeklyReportTableLine extends Vue {

    @Prop({ required: true })
    private value!: WeeklyReportLine;

    @Prop({ default: false })
    private disabled!: boolean;

    @Watch('value')
    private onValueChange() {
        this.$emit('input', this.value);
    }

    private onInputChange() {
        this.$emit('update');
    }

    private roles = Object.values(WeeklyReportRole);

    private deleteLine() {
        this.$emit('remove');
    }

}
