

import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import getRole from '@/plugins/roleHandler';
import { RouteRecordPublic } from 'vue-router';
import { ROLE } from '@/assets/ts/enum/role';
import UploadManager from '@/components/UploadManager.vue';

@Component({
    components: {
        UploadManager,
        Loading,
    },
})
export default class FeatureOverview extends Vue {

    private ROLE = ROLE;

    private role = ROLE.GUEST;

    private loading = true;

    private get featureRoutes(): RouteRecordPublic[] {
        const featureRoutes = this.$router.getRoutes()
            .filter((r) => r.meta?.feature);
        return featureRoutes.filter((r) => !r.meta || !r.meta.roles || r.meta.roles.includes(this.role));
    }

    private created() {
        this.loadRole();
    }

    private async loadRole() {
        this.role = await getRole();
        this.loading = false;
    }

}
