

import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { InputResponse, ProjectGroup, User } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import InputButton from '@/components/input/InputButton.vue';
import InputSelect from '@/components/input/InputSelect.vue';
import InputMultiSelect from '@/components/input/InputMultiSelect.vue';
import UploadManager from '@/components/UploadManager.vue';
import AlertError from '@/components/AlertError.vue';
import clone from 'clone';
import InputDefault from '@/components/input/InputDefault.vue';
import BaseDialog from './BaseDialog.vue';

@Component({
    components: {
        InputDefault,
        AlertError,
        UploadManager,
        InputMultiSelect,
        InputSelect,
        InputButton,
        Loading,
        BaseDialog,
    },
})
export default class EditProjectGroupDialog extends Vue {

    @Prop({ default: null })
    private existing!: ProjectGroup | null;

    private ready = false;
    private projectGroup: ProjectGroup = {
        managers: [],
    };

    private managers: User[] = [];

    private showErrors: boolean = false;
    private errors: { [key: string]: string } = {};
    private submitted: boolean = false;
    private success: boolean = false;

    private get title(): string {
        if (this.projectGroup.id) return 'Bauvorhaben bearbeiten';
        return 'Neues Bauvorhaben erstellen';
    }

    private async created() {
        if (this.existing) {
            this.projectGroup = clone(this.existing);
        }
        if (!this.projectGroup.id) {
            this.projectGroup.number = `${new Date().getFullYear()}-`;
        }

        await this.loadManagers();
        this.ready = true;
    }

    private async loadManagers() {
        try {
            this.managers = await Api.omb.userProjectManagersGet();
        } catch (e: any) {
            if (e.status === 403) {
                this.close();
            } else if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private close() {
        this.$emit('close');
    }

    private async submit() {
        this.submitted = true;
        this.showErrors = false;

        const response: InputResponse = await Api.omb.saveProjectGroup(this.projectGroup);
        if (!response.error) {
            this.success = true;
            this.$emit('update', this.projectGroup);
            this.close();
        } else if (response.problems !== undefined) {
            this.submitted = false;
            this.errors = response.problems;
            this.showErrors = true;
        }
    }

    private duplicateProjectGroup() {
        this.$router.push({ name: availableRoutes.projectGroups });
        this.$root.$once('projectGroupsReady', () => this.$root.$emit('duplicateProjectGroup', this.projectGroup));
    }

    private onManagersInput(id: number[]) {
        this.projectGroup.managers = this.managers.filter((m) => id.includes(m.id || 0));
    }

}
