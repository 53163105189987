

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class InputRadio extends Vue {

    @Prop({ default: false })
    private value!: boolean;

    @Prop({ default: 'primary' })
    private color!: string;

    @Prop({ default: false })
    private disabled!: boolean;

}
