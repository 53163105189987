/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeeklyReportLine
 */
export interface WeeklyReportLine {
    /**
     * 
     * @type {number}
     * @memberof WeeklyReportLine
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReportLine
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReportLine
     */
    day?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReportLine
     */
    names?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReportLine
     */
    totalHours?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReportLine
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReportLine
     */
    more?: string;
}

export function WeeklyReportLineFromJSON(json: any): WeeklyReportLine {
    return WeeklyReportLineFromJSONTyped(json, false);
}

export function WeeklyReportLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeeklyReportLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'names': !exists(json, 'names') ? undefined : json['names'],
        'totalHours': !exists(json, 'totalHours') ? undefined : json['totalHours'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'more': !exists(json, 'more') ? undefined : json['more'],
    };
}

export function WeeklyReportLineToJSON(value?: WeeklyReportLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'role': value.role,
        'day': value.day,
        'names': value.names,
        'totalHours': value.totalHours,
        'description': value.description,
        'more': value.more,
    };
}


