

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class InputTextarea extends Vue {

    @Prop({ default: '' })
    private value!: string | number | Date;

    @Prop({ default: false })
    private error!: boolean;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: false })
    private borderless!: boolean;

    @Prop({ default: false })
    private autoResize!: boolean;

    private onInput(event: Event) {
        const target = event.target as HTMLTextAreaElement;
        this.$emit('input', target.value);
        this.setTextareaHeight();
    }

    private setTextareaHeight() {
        if (!this.autoResize) return;

        const textarea = this.$el as HTMLTextAreaElement;
        if (!textarea) return;

        textarea.style.height = `${textarea.scrollHeight}px`;
    }

    private mounted() {
        this.setTextareaHeight();
    }

}
