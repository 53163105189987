/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    abbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    login?: string;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'abbreviation': !exists(json, 'abbreviation') ? undefined : json['abbreviation'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'login': !exists(json, 'login') ? undefined : json['login'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firstName': value.firstName,
        'abbreviation': value.abbreviation,
        'role': value.role,
        'active': value.active,
        'id': value.id,
        'password': value.password,
        'login': value.login,
    };
}


