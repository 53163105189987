/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    done?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    projectName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    createdAt?: Date;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'done': !exists(json, 'done') ? undefined : json['done'],
        'projectName': !exists(json, 'projectName') ? undefined : json['projectName'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'from': value.from,
        'to': value.to,
        'type': value.type,
        'content': value.content,
        'done': value.done,
        'projectName': value.projectName,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


