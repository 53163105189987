// eslint-disable-next-line import/prefer-default-export
export enum DEPARTMENT {
    PLASTICS = 'Kunststoffabteilung',
    STEEL = 'Stahlabteilung',
    DOORS = 'Türenabteilung',
    FACADES = 'Fassadenabteilung',
    WINDOWS = 'Fensterabteilung',
    TIN = 'Blechabteilung',
    ASSEMBLY = 'Montage',
    EASSEMBLY = 'E-Montage'
}
