// eslint-disable-next-line import/no-cycle
import Api from '@/assets/ts/Api';
import { ROLE } from '@/assets/ts/enum/role';

export default async function getRole(): Promise<ROLE> {
    const currentValue = localStorage.getItem('OMB_ROLE');
    if (['', null].includes(currentValue)) {
        try {
            const role = await Api.omb.getRoleGet();
            localStorage.setItem('OMB_ROLE', role as string);
        } catch {
            localStorage.setItem('OMB_ROLE', ROLE.GUEST);
        }
    }

    const toReturn: string | null = localStorage.getItem('OMB_ROLE');
    if (toReturn != null) {
        return toReturn as ROLE;
    }

    return ROLE.GUEST;
}
