

import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import TableComponent from '@/components/TableComponent.vue';
import Api from '@/assets/ts/Api';
import { Project, ProjectGroup, User } from '@/clients/omb-api';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import EditProjectDialog from '@/components/dialogs/EditProjectDialog.vue';

interface Legend {
    title: string;
    entries: { title: string; explanation: string; }[];
}

@Component({
    components: {
        EditProjectDialog,
        Loading,
        TableComponent,
    },
})
export default class TableView extends Vue {

    private ROLE = ROLE;
    private availableRoutes = availableRoutes;

    private projectGroups: ProjectGroup[] = [];
    private projects: Project[] = [];

    private title = '';
    private currentWeek = 0;
    private departments: string[] = [];
    private role = ROLE.GUEST;
    private searchTerm = '';
    private filteredProjects: Project[] = [];
    private deletedProjects: Project[] = [];
    private projectManagers: User[] = [];
    private newProjectDialogVisible = false;
    private departmentView = false;
    private assemblyView = false;
    private loading = true;

    private legends: Legend[] = [
        {
            title: 'Fassade',
            entries: [
                {
                    title: 'Fassade (Kat. 1)',
                    explanation: '1-Geschossige Fassaden (Länge bis 10 m)',
                },
                {
                    title: 'Fassade (Kat. 2)',
                    explanation: '1-Geschossige Fassaden (Länge über 10 m)',
                },
                {
                    title: 'Fassade (Kat. 3)',
                    explanation: 'Mehr-Geschossige Fassaden (Länge bis 10 m) ggf. auch mit Verstärkung',
                },
                {
                    title: 'Fassade (Kat. 4)',
                    explanation: 'Mehr-Geschossige Fassaden (Länge über 10 m) ggf. auch mit Verstärkung',
                },
            ],
        },
        {
            title: 'Fenster',
            entries: [
                {
                    title: 'Fenster (Kat. 1)',
                    explanation: '1-fig. Fenster ohne Antrieb oder RC-Klassen',
                },
                {
                    title: 'Fenster (Kat. 2)',
                    explanation: '1-fig. Fenster mit Antrieb oder RC-Klassen',
                },
                {
                    title: 'Fenster (Kat. 3)',
                    explanation: 'Mehr-Teilige Fenster (Flügel+Festfelder) ohne Antrieb oder RC-Klassen',
                },
                {
                    title: 'Fenster (Kat. 4)',
                    explanation: 'Mehr-Teilige Fenster (Flügel+Festfelder) mit Antrieb oder RC-Klassen',
                },
            ],
        },
        {
            title: 'Außen-Türen',
            entries: [
                {
                    title: 'Außen-Türen (Kat. 1)',
                    explanation: '1- oder 2-flg. Türen ohne Seitenteile, Oberlicht , Antriebe, RC-Klasssen',
                },
                {
                    title: 'Außen-Türen (Kat. 2)',
                    explanation: '1- oder 2-flg. Türen mit Seitenteile, Oberlicht, ohne Antriebe, RC-Klasssen',
                },
                {
                    title: 'Außen-Türen (Kat. 3)',
                    explanation: '1- oder 2-flg. Türen ohne Seitenteile, Oberlicht mit Antriebe, o. RC-Klasssen',
                },
                {
                    title: 'Außen-Türen (Kat. 4)',
                    explanation: '1- oder 2-flg. Türen mit Seitenteile, Oberlicht mit Antriebe, o. RC-Klasssen',
                },
            ],
        },
        {
            title: 'Rauchschutz-Türen',
            entries: [
                {
                    title: 'Rauchschutz-Türen (Kat. 1)',
                    explanation: '1- oder 2-flg. Türen ohne Seitenteile, Oberlicht , Antriebe, RC-Klasssen',
                },
                {
                    title: 'Rauchschutz-Türen (Kat. 2)',
                    explanation: '1- oder 2-flg. Türen mit Seitenteile, Oberlicht, ohne Antriebe, RC-Klasssen',
                },
                {
                    title: 'Rauchschutz-Türen (Kat. 3)',
                    explanation: '1- oder 2-flg. Türen ohne Seitenteile, Oberlicht mit Antriebe, o. RC-Klasssen',
                },
                {
                    title: 'Rauchschutz-Türen (Kat. 4)',
                    explanation: '1- oder 2-flg. Türen mit Seitenteile, Oberlicht mit Antriebe, o. RC-Klasssen',
                },
            ],
        },
        {
            title: 'Brandschutz-Türen',
            entries: [
                {
                    title: 'Brandschutz-Türen (Kat. 1)',
                    explanation: '1- oder 2-flg. Türen ohne Seitenteile, Oberlicht , Antriebe, RC-Klasssen',
                },
                {
                    title: 'Brandschutz-Türen (Kat. 2)',
                    explanation: '1- oder 2-flg. Türen mit Seitenteile, Oberlicht, ohne Antriebe, RC-Klasssen',
                },
                {
                    title: 'Brandschutz-Türen (Kat. 3)',
                    explanation: '1- oder 2-flg. Türen ohne Seitenteile, Oberlicht mit Antriebe, o. RC-Klasssen',
                },
                {
                    title: 'Brandschutz-Türen (Kat. 4)',
                    explanation: '1- oder 2-flg. Türen mit Seitenteile, Oberlicht mit Antriebe, o. RC-Klasssen',
                },
            ],
        },
    ];

    private get projectManagersStrings(): string[] {
        return this.projectManagers
            .sort((a, b) => ((a.abbreviation?.toLowerCase() || '') < (b.abbreviation?.toLowerCase() || '') ? -1 : 1))
            .filter((m) => m.abbreviation !== undefined)
            .map((m) => `${m.abbreviation} = ${m.firstName} ${m.name}`);
    }

    private get isAtLeastPlanner(): boolean {
        return this.role === ROLE.ADMINISTRATOR
            || this.role === ROLE.PLANNING;
    }

    private async created() {
        if (this.$route.meta?.label) this.title = this.$route.meta.label;
        if (this.$route.name === availableRoutes.projects) {
            this.departmentView = false;
        } else if ([availableRoutes.assembly, availableRoutes.eassembly].includes(this.$route.name || '')) {
            this.assemblyView = true;
        } else {
            this.departmentView = true;
        }

        if (this.$route.meta?.departments) {
            this.departments = this.$route.meta.departments;
        }

        this.currentWeek = moment()
            .isoWeek();
        this.role = await getRole();
        await this.loadProjects();
        await this.loadProjectManagers();
        this.loading = false;
    }

    private mounted() {
        this.$root.$on('print', this.print);
        this.$root.$on('openNewProjectDialog', this.showNewProjectDialog);
    }

    private destroyed() {
        this.$root.$off('print', this.print);
        this.$root.$off('openNewProjectDialog', this.showNewProjectDialog);
    }

    private getProjectGroupName(project: Project) {
        if (!this.projectGroups) return `${project.projectGroupId} (ID)`;

        const projectGroup = this.projectGroups.find((g) => g.id === project.projectGroupId);
        return projectGroup ? projectGroup.name || '' : `${project.projectGroupId} (ID)`;
    }

    private async loadProjectManagers() {
        try {
            this.projectManagers = await Api.omb.userProjectManagersGet();
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private async loadProjects(projects?: Project[]) {
        try {
            this.projectGroups = await Api.omb.getAllProjectGroups();
            const concatedDepartments = this.departments.join(',');
            const prjs = projects || await Api.omb.projectDepartmentGet(concatedDepartments);

            if (this.isAtLeastPlanner) {
                this.projects = prjs.filter((p) => !p.archivedAt);
            } else if (!this.assemblyView) {
                this.projects = prjs.filter((p) => !p.completed);
            } else {
                this.projects = prjs.filter((p) => !p.assemblyComplete || (p.departments && p.departments.toLowerCase()
                    .includes('e-montage') && !p.operating));
            }

            this.projects.forEach((project, i) => {
                if (project.categories === '') {
                    this.projects[i].categories = 'Keine';
                }
                if (!this.projects[i].number) {
                    this.projects[i].number = `${i + 1}`;
                }
            });

            this.filteredProjects = this.projects;
            this.search();
            this.$forceUpdate();
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        }
    }

    private print() {
        const printer = window.open('', '', 'left=0,top=0,width=0,height=0,toolbar=0,scrollbars=0,status=0');
        if (printer !== null) {
            printer.document.write('<table><thead><th>Nr.</th><th>Name des Bauvorhabens</th><th>Beginn Fertigung</th><th>Dauer Fertigung</th><th>Start Montage</th><th>Dauer Montage</th><th>Umfang des Bauabschnittes</th><th>Kategorien</th><th>Abteilungen</th><th>Fertigung abgeschlossen</th><th>Farbton</th></thead><tbody>');
            this.projects.forEach((project) => {
                printer.document.write(`<tr><td class="centered-text">${project.number}</td><td>${this.getProjectGroupName(project)}</td><td class="centered-text">${project.beginProduction}</td><td class="centered-text">${project.durationProduction}</td><td class="centered-text">${project.beginInstallationNew}</td><td class="centered-text">${project.durationInstallation}</td><td>${project.scope}</td><td class="centered-text">${project.categories}</td><td>${project.departments}</td><td class="centered-text">${this.booleanToGerman(project.completed)}</td><td>${project.colour}</td></tr>`);
            });
            printer.document.write('</tbody></table><style>\n'
                + 'table {font-family: "PT Sans"; border-collapse: collapse} \n'
                + 'tbody tr:first-child {border-top: solid 1px;} \n'
                + 'tbody tr:nth-child(odd) {background: #EAEAEA; -webkit-print-color-adjust: exact; box-shadow: inset 0 0 0 10000px #EAEAEA}\n'
                + 'tbody tr:last-child {border-bottom: solid 1px}\n'
                + '.centered-text {text-align: center}\n'
                + 'th {font-weight: normal; border-right: solid 1px; border-left: solid 1px; border-top: solid 1px}\n'
                + 'td {border-right: solid 1px; border-left: solid 1px;}\n'
                + '</style>');
            printer.document.close();
            printer.focus();
            printer.print();
        }
    }

    private search() {
        this.filteredProjects = [];
        this.projects.forEach((project) => {
            if (this.getProjectGroupName(project)
                .toLocaleLowerCase()
                .includes(this.searchTerm.toLocaleLowerCase()) || this.searchTerm === '') {
                this.filteredProjects.push(project);
            }
        });
        this.$forceUpdate();
    }

    private showNewProjectDialog() {
        this.newProjectDialogVisible = true;
    }

    private hideNewProjectDialog() {
        this.newProjectDialogVisible = false;
    }

    private handleAssemblyViewCompletion(project: Project) {
        if (!this.isAtLeastPlanner && this.assemblyView && project.assemblyComplete && (project.departments && (!project.departments.toLowerCase()
            .includes('e-montage') || project.operating))) {
            this.loadProjects(this.projects.filter((p) => p.id !== project.id));
        }
    }

    private handleArchive(project: Project) {
        this.loadProjects(this.projects.filter((p) => p.id !== project.id));
    }

    private handleDelete(projectId: number) {
        let project: Project | undefined;
        this.projects.forEach((aProject) => {
            if (aProject.id === projectId) {
                project = aProject;
                const position: number = this.projects.indexOf(project);
                this.projects.splice(position, 1);
                this.search();
            }
        });
        if (project !== undefined) {
            this.deletedProjects.push(project);
            this.$forceUpdate();
        }
    }

    private handleRecover(project: Project) {
        const position: number = this.deletedProjects.indexOf(project);
        this.deletedProjects.splice(position, 1);
        this.loadProjects();
    }

    private loadProjectsAndHideDialog() {
        this.loadProjects();
        this.hideNewProjectDialog();
    }

    private booleanToGerman(value: boolean | undefined): string {
        if (value) {
            return 'ja';
        }
        return 'nein';
    }
}
