
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class BaseDialog extends Vue {

    @Prop({ default: '' })
    private title!: string;

    @Prop({ default: 'normal' })
    private size!: 'auto' | 'small' | 'normal' | 'big';

    private close() {
        this.$emit('close');
    }
}
