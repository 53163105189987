

import { Component, Prop, Vue } from 'vue-property-decorator';
import InputButton from '@/components/input/InputButton.vue';
import UploadManager from '@/components/UploadManager.vue';
import { Upload } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import AlertError from '@/components/AlertError.vue';
import BaseDialog from './BaseDialog.vue';

@Component({
    components: {
        AlertError,
        UploadManager,
        InputButton,
        BaseDialog,
    },
})
export default class AddUploadsDialog extends Vue {

    @Prop({ required: true })
    private week!: number;

    @Prop({ required: true })
    private year!: number;

    private supportedEndings = ['pdf'];

    private uploads: Upload[] = [];
    private saveError = false;

    private get uploadQuery(): Map<string, number> {
        const map = new Map<string, number>();
        map.set('week', this.week);
        map.set('year', this.year);
        return map;
    }

    private close() {
        this.$emit('close');
    }

    private submit() {
        if (!this.uploads.length) return;

        this.saveError = false;
        const toSave = this.uploads[0];
        Api.omb.saveAssemblerList(toSave.id || '')
            .then((saved) => {
                this.$emit('success', saved);
                this.close();
            })
            .catch(() => {
                this.saveError = true;
            });
    }
}
