

import {
    Component,
    Prop,
    Vue,
    Watch,
} from 'vue-property-decorator';
import { DailyWageReportLine } from '@/clients/omb-api';
import InputTextarea from '@/components/input/InputTextarea.vue';

@Component({
    components: { InputTextarea },
})
export default class DailyWageReportTableLine extends Vue {

    @Prop({ required: true })
    private value!: DailyWageReportLine;

    @Prop({ default: false })
    private disabled!: boolean;

    private line: DailyWageReportLine = this.value;

    @Watch('value', { deep: true })
    private onValueChange() {
        this.line = this.value;
    }

    @Watch('line', { deep: true })
    private onLineChange() {
        this.$emit('input', this.value);
    }

    private get totalHours(): number {
        const mo = parseFloat(`${this.line.monday || 0}`);
        const tu = parseFloat(`${this.line.tuesday || 0}`);
        const we = parseFloat(`${this.line.wednesday || 0}`);
        const th = parseFloat(`${this.line.thursday || 0}`);
        const fr = parseFloat(`${this.line.friday || 0}`);
        const sum = mo + tu + we + th + fr;
        return parseFloat(`${sum.toFixed(2)}`);
    }

    private deleteLine() {
        this.$emit('remove');
    }

    private onInputChange() {
        this.$emit('update');
    }

}
