/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserPage
 */
export interface UserPage {
    /**
     * 
     * @type {number}
     * @memberof UserPage
     */
    totalPages?: number;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserPage
     */
    content?: Array<User>;
}

export function UserPageFromJSON(json: any): UserPage {
    return UserPageFromJSONTyped(json, false);
}

export function UserPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(UserFromJSON)),
    };
}

export function UserPageToJSON(value?: UserPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(UserToJSON)),
    };
}


