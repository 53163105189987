import Api from '@/assets/ts/Api';
import { UPLOAD_ERROR } from '@/assets/ts/enum/upload_error';
import { upload, UploadResponse } from 'upload';
import { Upload } from '@/clients/omb-api';
import JSZip from 'jszip';

export default class Uploader {

    private sessionToken = '';
    private static uploadSizeLimit = 0;
    private supportedEndings = ['pdf', 'png', 'jpg', 'jpeg'];

    public static async create(): Promise<Uploader> {
        const newUploader = new Uploader();
        newUploader.sessionToken = Api.getToken();
        if (!Uploader.uploadSizeLimit) Uploader.uploadSizeLimit = parseInt(`${await Api.omb.getUploadSizeLimit()}`, 10);
        return newUploader;
    }

    public upload(file: File, onProgress?: (progress: number) => void, query?: Map<string, number | string>, type?: 'default' | 'assembler-list'): Promise<UploadResponse> {
        if (!this.sessionToken || !Uploader.uploadSizeLimit) {
            this.error();
        }

        let apiUrl = Api.getApiUrl();
        if (type === 'assembler-list') {
            apiUrl += '/upload/assembler-list';
        } else {
            apiUrl += '/upload';
        }
        apiUrl = apiUrl.replaceAll('//upload', '/upload');

        if (query) {
            apiUrl += '?';
            query.forEach((value, key) => {
                apiUrl += `${key}=${value}&`;
            });
        }

        return upload(apiUrl,
            { file: file },
            {
                onProgress: onProgress,
                headers: { Authorization: `Bearer ${this.sessionToken}` },
            });
    }

    public checkFile(file: File): UPLOAD_ERROR[] {
        const errors = [];

        if (file.size > Uploader.uploadSizeLimit) {
            errors.push(UPLOAD_ERROR.FILESIZE);
        }

        return errors;
    }

    public getSupportedEndings(): string[] {
        return this.supportedEndings;
    }

    public getUploadSizeLimit(): number {
        if (!this.sessionToken || !Uploader.uploadSizeLimit) {
            this.error();
        }

        return Uploader.uploadSizeLimit;
    }

    public async download(id: string, type: string, name: string): Promise<void> {
        const blob = await Api.omb.getUploadFile(id);
        const newBlob = new Blob([blob], { type: type });
        this.downloadBlob(newBlob, name);
    }

    public async downloadMultiple(uploads: Upload[], archiveName = 'Downloads'): Promise<void> {
        const zip = new JSZip();

        for (let i = 0; i < uploads.length; i += 1) {
            const u = uploads[i];
            // eslint-disable-next-line no-await-in-loop
            const blob = await Api.omb.getUploadFile(u.id || '');
            // eslint-disable-next-line no-await-in-loop
            await zip.file(u.name || '', blob);
        }

        zip.generateAsync({ type: 'blob' })
            .then((content) => {
                this.downloadBlob(content, archiveName);
            });
    }

    private downloadBlob(blob: Blob, filename: string) {
        const element = document.createElement('a');
        element.setAttribute('href', window.URL.createObjectURL(blob));
        element.setAttribute('download', filename);
        element.setAttribute('style', 'display: none;');

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    private error() {
        throw new Error('Uploader has not been initialized properly.');
    }

}
