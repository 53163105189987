

import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import TableComponent from '@/components/TableComponent.vue';
import Api from '@/assets/ts/Api';
import { Project, ProjectGroup, User } from '@/clients/omb-api';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import InputPage from '@/components/input/InputPage.vue';
import InputButton from '@/components/input/InputButton.vue';
import EditProjectGroupDialog from '@/components/dialogs/EditProjectGroupDialog.vue';
import clone from 'clone';
import ProjectGroupsTable from '@/views/projectgroups/ProjectGroupsTable.vue';
import ProjectGroupsAndRepairsMap from '@/components/ProjectGroupsAndRepairsMap.vue';

interface ProjectGroupPage {
    projectGroups: ProjectGroup[];
    projects: Project[];
}

@Component({
    components: {
        ProjectGroupsAndRepairsMap,
        ProjectGroupsTable,
        EditProjectGroupDialog,
        InputButton,
        InputPage,
        Loading,
        TableComponent,
    },
})
export default class ProjectGroups extends Vue {

    private ROLE = ROLE;

    private page = 0;
    private totalPages = 0;
    private self = this.$route.query.self === '1';
    private searchTerm: string = '';

    private projectGroupPages: ProjectGroupPage[] = [];
    private projectGroupsWithGeodata: ProjectGroup[] = [];
    private projects: Project[] = [];

    private role = ROLE.GUEST;
    private currentUser: User | null = null;

    private title: string = '';
    private currentWeek: number = 0;
    private projectGroupToEdit?: ProjectGroup;
    private editDialogVisible: boolean = false;
    private loading = true;
    private loadingMore = true;
    private mapView = false;

    @Watch('page')
    private onPageChange(): void {
        if (!this.projectGroupPages[this.page]) {
            this.loadProjectGroups();
        }
    }

    @Watch('self')
    private onSelfFilterChange(): void {
        this.search();
    }

    @Watch('mapView')
    private onMapViewChange(): void {
        this.loadMap();
    }

    private async created() {
        if (this.$route.meta?.label) this.title = this.$route.meta.label;

        this.currentWeek = moment()
            .isoWeek();
        await this.loadUserData();
        this.loading = false;
        await this.loadProjectGroups();
    }

    private mounted() {
        this.$root.$on('openNewProjectGroupDialog', this.showEditDialog);

        this.$nextTick(() => {
            this.$root.$once('duplicateProjectGroup', this.duplicateProjectGroup);
            this.$root.$emit('projectGroupsReady');
        });
    }

    private destroyed() {
        this.$root.$off('openNewProjectGroupDialog', this.showEditDialog);
    }

    private async loadUserData() {
        this.role = await getRole();
        if (this.role !== ROLE.GUEST) {
            this.currentUser = await Api.omb.getCurrentUser();
        }
    }

    private search() {
        this.page = 0;
        this.projectGroupPages = [];
        this.totalPages = 0;
        this.loadProjectGroups();
    }

    private async loadProjectGroups() {
        this.loadingMore = true;

        try {
            const searchResult = await Api.omb.searchProjectGroups(this.self, this.searchTerm, this.page);
            this.projectGroupPages[this.page] = {
                projectGroups: searchResult.projectGroups,
                projects: await Api.omb.getProjectsByGroups(searchResult.projectGroups.map((pg) => pg.id || 0)),
            };
            this.totalPages = searchResult.totalPages;
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            }
        } finally {
            this.loadingMore = false;
        }
    }

    private async loadMap(): Promise<void> {
        this.loadingMore = true;
        this.page = 0;
        this.searchTerm = '';
        this.self = false;
        if (!this.projectGroupsWithGeodata.length) {
            const allProjectGroups = await Api.omb.getAllProjectGroups();
            this.projectGroupsWithGeodata = allProjectGroups.filter((pg) => pg.lat && pg.lng);
        }
        this.loadingMore = false;
    }

    private onProjectGroupUpdate(projectGroup: ProjectGroup): void {
        this.projectGroupPages = this.projectGroupPages.map((page) => {
            page.projectGroups = page.projectGroups.map((pg) => (pg.id === projectGroup.id ? projectGroup : pg));
            return page;
        });
    }

    private duplicateProjectGroup(projectGroup: ProjectGroup) {
        const newProjectGroup = clone(projectGroup);
        newProjectGroup.id = undefined;

        if (newProjectGroup.uploads) {
            const newUploadPromises = newProjectGroup.uploads.map((u) => Api.omb.duplicateUpload(u.id || ''));
            Promise.all(newUploadPromises)
                .then((uploads) => {
                    newProjectGroup.uploads = uploads;
                    this.showEditDialog(newProjectGroup);
                });
        } else {
            this.showEditDialog(newProjectGroup);
        }

    }

    private showEditDialog(projectGroup?: ProjectGroup) {
        this.projectGroupToEdit = projectGroup;
        this.editDialogVisible = true;
    }

    private hideEditDialog() {
        this.editDialogVisible = false;
        this.projectGroupToEdit = undefined;
    }

    private toggleSelfFilter() {
        this.page = 0;
        this.self = !this.self;
        this.$router.push({
            name: this.$route.name || '',
            query: { self: this.self ? '1' : '0' },
        });
    }

}
