/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadAssemblerList
 */
export interface UploadAssemblerList {
    /**
     * 
     * @type {string}
     * @memberof UploadAssemblerList
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadAssemblerList
     */
    saved?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UploadAssemblerList
     */
    week?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadAssemblerList
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadAssemblerList
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadAssemblerList
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadAssemblerList
     */
    size?: number;
    /**
     * 
     * @type {Date}
     * @memberof UploadAssemblerList
     */
    uploadedAt?: Date;
}

export function UploadAssemblerListFromJSON(json: any): UploadAssemblerList {
    return UploadAssemblerListFromJSONTyped(json, false);
}

export function UploadAssemblerListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadAssemblerList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'saved': !exists(json, 'saved') ? undefined : json['saved'],
        'week': !exists(json, 'week') ? undefined : json['week'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'uploadedAt': !exists(json, 'uploaded_at') ? undefined : (new Date(json['uploaded_at'])),
    };
}

export function UploadAssemblerListToJSON(value?: UploadAssemblerList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'saved': value.saved,
        'week': value.week,
        'year': value.year,
        'name': value.name,
        'type': value.type,
        'size': value.size,
        'uploaded_at': value.uploadedAt === undefined ? undefined : (value.uploadedAt.toISOString()),
    };
}


