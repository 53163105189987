

import {
    Component,
    Prop,
    Vue,
} from 'vue-property-decorator';
import {
    ProjectGroup,
    User,
    WeeklyReport,
    WeeklyReportLine,
} from '@/clients/omb-api';
import Loading from '@/components/Loading.vue';
import InputButton from '@/components/input/InputButton.vue';
import InputSelect from '@/components/input/InputSelect.vue';
import clone from 'clone';
import InputDefault from '@/components/input/InputDefault.vue';
import moment from 'moment';
import { Weekday } from '@/assets/ts/enum/weekday';
import WeeklyReportTableLine from '@/components/reports/WeeklyReportTableLine.vue';
import Api from '@/assets/ts/Api';
import ExportReportDialog from '@/components/dialogs/ExportReportDialog.vue';
import Uploader from '@/assets/ts/uploader';
import { ROLE } from '@/assets/ts/enum/role';
import AlertErrorDetails from '@/components/AlertErrorDetails.vue';
import ErrorUtils from '@/assets/ts/error_utils';
import BaseDialog from './BaseDialog.vue';

@Component({
    computed: {
        ROLE() {
            return ROLE;
        },
    },
    components: {
        AlertErrorDetails,
        ExportReportDialog,
        WeeklyReportTableLine,
        InputDefault,
        InputSelect,
        InputButton,
        Loading,
        BaseDialog,
    },
})
export default class EditWeeklyReportDialog extends Vue {

    private Weekday = Weekday;

    @Prop({ default: null })
    private existing?: WeeklyReport | null;

    @Prop({ required: true })
    private projectGroup!: ProjectGroup;

    @Prop({ required: true })
    private currentUser!: User;

    private ready = false;
    private report: WeeklyReport = {};

    private showErrors: boolean = false;
    private errors: { [key: string]: string } = {};
    private generalError: any | null = null;
    private deleting: boolean = false;
    private submitting: boolean = false;

    private title = 'Wochenbericht';

    private lineToRemove: number | null = null;
    private exportDialogVisible = false;
    private deleteDialogVisible = false;

    private autosaveTimeout?: any;
    private lastSave: Date | null = null;

    private uploader!: Uploader;

    private get lastSaveString(): string | undefined {
        if (!this.lastSave) return undefined;

        const hours = this.lastSave.getHours() < 10 ? `0${this.lastSave.getHours()}` : `${this.lastSave.getHours()}`;
        const minutes = this.lastSave.getMinutes() < 10 ? `0${this.lastSave.getMinutes()}` : `${this.lastSave.getMinutes()}`;

        return `${hours}:${minutes}`;
    }

    private async created() {
        this.uploader = await Uploader.create();

        if (this.existing) {
            this.report = clone(this.existing);
        }
        if (!this.report.id) {
            await this.setInitialValues();
        } else {
            this.correctDateTypes();
        }

        this.setTitle();
        this.ready = true;
    }

    private setTitle(): void {
        if (this.report.fileId) {
            this.title = 'Wochenbericht';
        } else if (this.report.id) {
            this.title = 'Wochenbericht bearbeiten';
        } else {
            this.title = 'Neuen Wochenbericht erstellen';
        }
    }

    private correctDateTypes() {
        this.report.start = this.report.start ? new Date(this.report.start) : undefined;
        this.report.end = this.report.end ? new Date(this.report.end) : undefined;
    }

    private async setInitialValues() {
        const currentUser = await Api.omb.getCurrentUser();
        const now = moment()
            .hour(0)
            .minute(0)
            .second(0);

        this.report.name = this.projectGroup.name;
        this.report.week = `${now.isoWeek()}/${now.year()}`;
        this.report.week = this.report.week.length === 6 ? `0${this.report.week}` : this.report.week;
        this.report.start = now.isoWeekday(1)
            .toDate();
        this.report.end = now.isoWeekday(5)
            .toDate();
        this.report.department = 'Metallbauarbeiten';
        this.report.foreman = `${currentUser.firstName} ${currentUser.name}`;
        this.$forceUpdate();
    }

    private close() {
        this.$emit('close');
    }

    private async submit(close?: boolean, callback?: () => void) {
        if (!this.projectGroup || !this.projectGroup.id) return;

        this.submitting = true;
        this.generalError = null;
        this.showErrors = false;

        this.report.projectGroupId = this.projectGroup.id;

        Api.omb.saveWeeklyReport(this.report)
            .then((report) => {
                this.lastSave = new Date();
                this.submitting = false;
                this.emitUpdate(report);
                if (callback) {
                    callback();
                } else if (close) {
                    this.close();
                }
            })
            .catch((e) => {
                console.error(e);
                this.submitting = false;
                ErrorUtils.wrapError('Wochenbericht speichern', this.report, e)
                    .then((wrapped) => this.generalError = wrapped);
                this.showErrors = true;
            });
    }

    private getLines(day: Weekday): WeeklyReportLine[] {
        if (!this.report.lines) return [];
        return this.report.lines.filter((l) => l.day === day);
    }

    private addLine(day: Weekday): void {
        this.report.lines = this.report.lines || [];

        if (!this.report.lines.find((l) => l.day === day)) {
            const dayBefore = this.getDayBefore(day);
            const dayBeforeLines = dayBefore ? this.getLines(dayBefore) : undefined;
            if (dayBeforeLines && dayBeforeLines.length) {
                const linesCopy = clone(dayBeforeLines);
                this.report.lines = this.report.lines.concat(linesCopy.map((l) => {
                    l.id = this.getTempLineId();
                    l.day = day;
                    return l;
                }));
            } else {
                this.report.lines.push({
                    id: this.getTempLineId(),
                    day: day,
                });
            }
        } else {
            const lines = this.report.lines.filter((l) => l.day === day);
            const latestLine = lines[lines.length - 1];
            const latestLineCopy = clone(latestLine);
            latestLineCopy.id = this.getTempLineId();
            latestLineCopy.day = day;
            latestLineCopy.names = '';
            this.report.lines.push(latestLineCopy);
        }

        this.onReportChange(true);
        this.$forceUpdate();
    }

    private removeLine(id: number | null | undefined): void {
        this.lineToRemove = null;
        if (!this.report.lines) return;

        this.report.lines = this.report.lines.filter((l) => l.id !== id);
        this.onReportChange(true);
        this.$forceUpdate();
    }

    private getTempLineId(): number {
        let tempId = Math.floor(-999999999 * Math.random());
        // eslint-disable-next-line no-loop-func
        while (this.report.lines && this.report.lines.find((l) => l.id === tempId)) {
            tempId = Math.floor(-999999999 * Math.random());
        }

        return tempId;
    }

    private getDayBefore(day: Weekday): Weekday | undefined {
        switch (day) {
            case Weekday.Tuesday:
                return Weekday.Monday;
            case Weekday.Wednesday:
                return Weekday.Tuesday;
            case Weekday.Thursday:
                return Weekday.Wednesday;
            case Weekday.Friday:
                return Weekday.Thursday;
            default:
                return undefined;
        }
    }

    private exportReport(): void {
        this.submit(false, () => {
            this.deleteDialogVisible = false;
            this.exportDialogVisible = true;
        });
    }

    private downloadReport(): void {
        if (!this.report.fileId) return;
        const name = `Wochenbericht_${this.report.name || '?'}_${this.report.week || '?'}`;
        this.uploader.download(this.report.fileId || '', 'application/pdf', name);
    }

    private showDeleteDialog(): void {
        this.exportDialogVisible = false;
        this.deleteDialogVisible = true;
    }

    private async deleteReport(): Promise<void> {
        this.deleting = true;
        this.showErrors = false;

        Api.omb.deleteWeeklyReport(this.report.id || 0)
            .then(() => {
                this.submitting = false;
                this.$emit('delete', this.report.id);
                this.close();
            })
            .catch((e) => {
                console.error(e);
                this.deleting = false;
                ErrorUtils.wrapError('Wochenbericht löschen', this.report, e)
                    .then((wrapped) => this.generalError = wrapped);
                this.showErrors = true;
            });
    }

    private emitUpdate(report: WeeklyReport): void {
        this.report = report;
        this.$emit('update', report);
    }

    private onReportChange(instantSave?: boolean) {
        if (!this.ready || this.report.fileId) return;
        clearTimeout(this.autosaveTimeout);
        this.autosaveTimeout = setTimeout(this.submit, instantSave ? 0 : 500);
    }

}
