

import { Component, Prop, Vue } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';

@Component({
    components: { Loading },
})
export default class InputButton extends Vue {

    @Prop({ default: 'solid' })
    private type!: 'solid' | 'outline';

    @Prop({ default: false })
    private submit!: boolean;

    @Prop({ default: 'primary' })
    private color!: string;

    @Prop()
    private textColor!: string;

    @Prop({ default: 8 })
    private px!: string | number;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: true })
    private staticHeight!: boolean;

    @Prop({ default: false })
    private success!: boolean;

    @Prop({ default: false })
    private loading!: boolean;

    private get classes(): string {
        let classes: string[];

        switch (this.type) {
            case 'solid':
                classes = [
                    `text-${this.textColor || 'white'}`,
                    `bg-${this.color}`,
                    'hover:bg-opacity-75',
                ];
                break;
            case 'outline':
                classes = [
                    `text-${this.textColor || this.color}`,
                    `border-${this.color}`,
                    'border-1',
                    `hover:bg-${this.color}`,
                    'hover:bg-opacity-10',
                ];
                break;
            default:
                classes = [];
                break;
        }

        if (this.disabled || this.loading || this.success) {
            classes.push('pointer-events-none');
            classes.push('opacity-25');
        }

        if (this.loading || this.success) {
            classes.push(`px-${Math.max(parseInt(`${this.px}`, 10) - 4, 0)}`);
        } else {
            classes.push(`px-${this.px}`);
        }

        classes.push(this.staticHeight ? 'h-12' : 'min-h-12');

        return classes.join(' ');
    }
}
