/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadCategory
 */
export interface UploadCategory {
    /**
     * 
     * @type {number}
     * @memberof UploadCategory
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadCategory
     */
    name?: string;
}

export function UploadCategoryFromJSON(json: any): UploadCategory {
    return UploadCategoryFromJSONTyped(json, false);
}

export function UploadCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function UploadCategoryToJSON(value?: UploadCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}


