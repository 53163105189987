

import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import {
    InputResponse,
    ProjectGroup,
    Repair,
    User,
} from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Loading from '@/components/Loading.vue';
import InputButton from '@/components/input/InputButton.vue';
import InputSelect from '@/components/input/InputSelect.vue';
import InputMultiSelect from '@/components/input/InputMultiSelect.vue';
import UploadManager from '@/components/UploadManager.vue';
import EditProjectGroupDialog from '@/components/dialogs/EditProjectGroupDialog.vue';
import AlertError from '@/components/AlertError.vue';
import InputPriority from '@/components/input/InputPriority.vue';
import clone from 'clone';
import InputDefault from '@/components/input/InputDefault.vue';
import BaseDialog from './BaseDialog.vue';

@Component({
    components: {
        InputDefault,
        InputPriority,
        AlertError,
        EditProjectGroupDialog,
        UploadManager,
        InputMultiSelect,
        InputSelect,
        InputButton,
        Loading,
        BaseDialog,
    },
})
export default class EditRepairDialog extends Vue {

    @Prop()
    private projectGroups?: ProjectGroup[];

    @Prop({ default: null })
    private existing?: Repair | null;

    private groups: ProjectGroup[] = [];
    private repair: Repair = { name: '' };

    private managers: User[] = [];
    private assemblers: User[] = [];
    private selectedManagers: number[] = [];

    private ready = false;

    private showErrors: boolean = false;
    private errors: { [key: string]: string } = {};
    private submitted: boolean = false;
    private success: boolean = false;
    private generalError = false;

    private newProjectGroupDialogVisible = false;

    @Watch('selectedManagers')
    private onSelectedManagersChange() {
        this.repair.managers = this.managers.filter((m) => this.selectedManagers.includes(m.id || 0));
    }

    @Watch('repair.projectGroupId')
    private onProjectGroupChange() {
        const projectGroup = this.groups.find((pg) => pg.id === this.repair.projectGroupId);
        if (!projectGroup) return;

        this.repair.name = projectGroup.name || '';
        this.repair.street = projectGroup.street;
        this.repair.zipCode = projectGroup.zipCode;
        this.repair.city = projectGroup.city;
    }

    private get title(): string {
        if (this.repair.id) return 'Reparatur bearbeiten';
        return 'Neue Reparatur erstellen';
    }

    private async created() {
        if (this.existing) {
            this.repair = clone(this.existing);
        }
        this.repair.uploads = this.repair.uploads || [];
        this.selectedManagers = this.repair.managers ? this.repair.managers.map((m) => m.id || 0) : [];

        if (this.projectGroups) {
            this.groups = this.projectGroups;
        } else {
            await this.loadProjectGroups();
        }

        this.managers = await Api.omb.userProjectManagersGet();
        this.assemblers = await Api.omb.getAllAssemblers();

        this.ready = true;
    }

    private async loadProjectGroups() {
        this.groups = await Api.omb.getAllProjectGroups();
    }

    private close() {
        this.$emit('close');
    }

    private async submit() {
        this.submitted = true;
        this.showErrors = false;

        Api.omb.saveRepair(this.repair)
            .then((response) => {
                if (!response.error) {
                    this.success = true;
                    this.$emit('update', this.repair);
                    this.close();
                } else if (response.problems !== undefined) {
                    this.submitted = false;
                    this.errors = response.problems;
                    this.showErrors = true;
                    this.scrollUp();
                }
            })
            .catch(() => {
                this.submitted = false;
                this.success = false;
                this.generalError = true;
            });
    }

    private duplicateRepair() {
        this.$emit('duplicate', this.repair);
    }

    private scrollUp() {
        const element = document.getElementById('scrollContainer');
        if (element) {
            element.scrollTo(0, 0);
        }
    }

    private showNewProjectGroupDialog() {
        this.newProjectGroupDialogVisible = true;
    }

    private hideNewProjectGroupDialog() {
        this.newProjectGroupDialogVisible = false;
    }

}
