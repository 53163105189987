

import { Component, Prop, Vue } from 'vue-property-decorator';
import InputButton from '@/components/input/InputButton.vue';
import UploadManager from '@/components/UploadManager.vue';
import { Upload, UploadCategory } from '@/clients/omb-api';
import BaseDialog from './BaseDialog.vue';

@Component({
    components: {
        UploadManager,
        InputButton,
        BaseDialog,
    },
})
export default class AddUploadsDialog extends Vue {

    @Prop({ default: undefined })
    private supportedEndings!: string[] | undefined;

    @Prop({ default: undefined })
    private uploadCategories!: UploadCategory[] | undefined;

    @Prop({ default: false })
    private categorySelect!: boolean;

    @Prop({ default: false })
    private categoryRequired!: boolean;

    private uploads: Upload[] = [];

    private get isSubmitDisabled(): boolean {
        return !this.uploads.length || (this.categoryRequired && !!this.uploads.find((u) => u.categoryId === undefined));
    }

    private close() {
        this.$emit('close');
    }

    private submit() {
        this.$emit('submit', this.uploads);
        this.close();
    }
}
