

import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import Api from '@/assets/ts/Api';
import { Upload, User } from '@/clients/omb-api';
import Loading from '@/components/Loading.vue';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import InputButton from '@/components/input/InputButton.vue';
import UploadCard from '@/components/UploadCard.vue';
import { UPLOAD_ERROR } from '@/assets/ts/enum/upload_error';
import Uploader from '@/assets/ts/uploader';
import BaseDialog from '@/components/dialogs/BaseDialog.vue';
import AlertError from '@/components/AlertError.vue';
import AddUploadsDialog from '@/components/dialogs/AddUploadsDialog.vue';
import AddUploadAssemblerListDialog from '@/components/dialogs/AddUploadAssemblerListDialog.vue';

interface AssemblerListEntry {
    week: number;
    year: number;
    upload?: Upload;
}

@Component({
    components: {
        AddUploadAssemblerListDialog,
        AddUploadsDialog,
        AlertError,
        BaseDialog,
        UploadCard,
        InputButton,
        Loading,
    },
})
export default class AssemblerLists extends Vue {

    private ROLE = ROLE;

    private role = ROLE.GUEST;
    private currentUser: User | null = null;

    private title: string = '';
    private currentWeek: number = 0;
    private loading = true;

    private uploadWeek = 0;
    private uploadYear = 0;

    private removeId: string | null = null;
    private removeError = false;

    private assemblerListEntries: AssemblerListEntry[] = [];

    private get hasWritePermissions(): boolean {
        return [ROLE.ADMINISTRATOR, ROLE.PLANNING].includes(this.currentUser?.role as ROLE);
    }

    private async created() {
        if (this.$route.meta?.label) this.title = this.$route.meta.label;

        this.currentWeek = moment()
            .isoWeek();
        await this.loadUserData();
        await this.loadAssemblerLists();
        this.loading = false;
    }

    private async loadUserData() {
        this.role = await getRole();
        if (this.role !== ROLE.GUEST) {
            this.currentUser = await Api.omb.getCurrentUser();
        }
    }

    private async loadAssemblerLists() {
        this.assemblerListEntries = [];
        const lists = await Api.omb.getUploadAssemblerLists();
        let lastWeekMoment = moment()
            .subtract(1, 'week');
        while (this.assemblerListEntries.length < 4) {
            this.assemblerListEntries.push({
                week: lastWeekMoment.isoWeek(),
                year: lastWeekMoment.year(),
                // eslint-disable-next-line no-loop-func
                upload: lists.find((u) => u.week === lastWeekMoment.isoWeek() && u.year === lastWeekMoment.year()),
            });
            lastWeekMoment = lastWeekMoment.add(1, 'week');
        }
    }

    private showUploadDialog(week: number, year: number): void {
        this.uploadWeek = week;
        this.uploadYear = year;
    }

    private onUploadSuccess(upload: Upload) {
        this.assemblerListEntries = this.assemblerListEntries.map((entry) => {
            if (entry.week === this.uploadWeek && entry.year === this.uploadYear) {
                entry.upload = upload;
            }
            return entry;
        });
        this.hideUploadDialog();
    }

    private hideUploadDialog() {
        this.uploadWeek = 0;
        this.uploadYear = 0;
    }

    private showRemoveDialog(upload?: Upload): void {
        if (!upload || !upload.id) return;
        this.removeError = false;
        this.removeId = upload.id;
    }

    private async removeList(): Promise<void> {
        if (!this.removeId) return;

        Api.omb.deleteUploadAssemblerList(this.removeId)
            .then(() => {
                this.assemblerListEntries = this.assemblerListEntries.map((entry) => {
                    entry.upload = entry.upload?.id === this.removeId ? undefined : entry.upload;
                    return entry;
                });
                this.removeId = null;
            })
            .catch((e) => {
                console.error(e);
                this.removeError = true;
            });
    }

}
