
import {
    Vue,
    Component,
    Prop,
} from 'vue-property-decorator';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import { Upload } from '@/clients/omb-api';
import Uploader from '@/assets/ts/uploader';

@Component({
    components: { InputCheckbox },
})
export default class UploadCard extends Vue {

    @Prop({ required: true })
    private upload!: Upload;

    @Prop({ default: false })
    private value!: boolean;

    @Prop({ default: true })
    private selectable!: boolean;

    private uploader!: Uploader;

    private onSelectedChange(value: boolean) {
        this.$emit('input', value);
    }

    private download(event: Event, upload: Upload) {
        const target = event.target as HTMLElement;
        if (target && (target.tagName.toLowerCase() === 'input' || target.tagName.toLowerCase() === 'label')) return;

        this.uploader.download(upload.id || '', upload.type || '', upload.name || '');
    }

    private async created() {
        this.uploader = await Uploader.create();
    }
}
