/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface HistoryEntry
 */
export interface HistoryEntry {
    /**
     * 
     * @type {number}
     * @memberof HistoryEntry
     */
    id?: number;
    /**
     * 
     * @type {User}
     * @memberof HistoryEntry
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof HistoryEntry
     */
    event?: string;
    /**
     * 
     * @type {Date}
     * @memberof HistoryEntry
     */
    createdAt?: Date;
}

export function HistoryEntryFromJSON(json: any): HistoryEntry {
    return HistoryEntryFromJSONTyped(json, false);
}

export function HistoryEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HistoryEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'event': !exists(json, 'event') ? undefined : json['event'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
    };
}

export function HistoryEntryToJSON(value?: HistoryEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': UserToJSON(value.user),
        'event': value.event,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}


