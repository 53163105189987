import Vue from 'vue';
import App from './App.vue';
import router from './plugins/router';

import './plugins/icons';

Vue.config.productionTip = false;

// Google Maps ---------------------------------------------------
declare global {
    interface Window {
        initMap: () => void;
    }
}
window.initMap = () => {
    console.info('🗺️ Google Maps API JS initialized.');
};

function loadGoogleMapsApiJs() {
    const apiKey = process.env.VUE_APP_GOOGLE_API_KEY;
    const script = document.createElement('script');
    script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap&libraries=marker&v=beta`);
    document.body.append(script);
}
// ---------------------------------------------------------------

async function createNewVue() {
    new Vue({
        router,
        render: (h) => h(App),
    }).$mount('#app');
}

loadGoogleMapsApiJs();
createNewVue();

Vue.use(require('vue-moment'));
