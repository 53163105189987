import { WrappedError } from '@/types/WrappedError';

export default class ErrorUtils {

    public static async wrapError(label: string, data: any, error: any): Promise<WrappedError> {
        if (error.status) {
            const body = await error.body.getReader()
                .read();
            return {
                label: label,
                data: data,
                error: {
                    status: error.status,
                    statusText: error.statusText,
                    type: error.type,
                    url: error.url,
                    body: body,
                },
            };
        }

        return {
            label: label,
            data: data,
            error: error,
        };
    }

}
