

import { Component, Prop, Vue } from 'vue-property-decorator';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import InputButton from '@/components/input/InputButton.vue';
import Loading from '@/components/Loading.vue';
import Collapsable from '@/components/Collapsable.vue';
import { Upload } from '@/clients/omb-api';
import UploadCard from '@/components/UploadCard.vue';
import { v4 as uuidv4 } from 'uuid';

interface SelectableUpload {
    selected: boolean;
    upload: Upload;
}

interface CategorizedSelectableUploads {
    category: string;
    selectableUploads: SelectableUpload[];
    subcategories?: CategorizedSelectableUploads[];
}

@Component({
    name: 'TutorialsCollapsible',
    components: {
        // eslint-disable-next-line no-use-before-define
        TutorialsCollapsible,
        UploadCard,
        Collapsable,
        Loading,
        InputButton,
        InputCheckbox,
    },
})
export default class TutorialsCollapsible extends Vue {

    @Prop({ required: true })
    private categorizedSelectableUploads!: CategorizedSelectableUploads;

    @Prop({ default: 0 })
    private depth!: number;

    private id = uuidv4();

}
