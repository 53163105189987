

import { Component, Vue } from 'vue-property-decorator';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import InputButton from '@/components/input/InputButton.vue';
import Loading from '@/components/Loading.vue';
import { Upload, UploadCategory } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import Uploader from '@/assets/ts/uploader';
import UploadCard from '@/components/UploadCard.vue';
import TutorialsCollapsible from '@/views/tutorials/TutorialsCollapsible.vue';
import getRole from '@/plugins/roleHandler';
import AddUploadsDialog from '@/components/dialogs/AddUploadsDialog.vue';
import { ROLE } from '@/assets/ts/enum/role';
import BaseDialog from '@/components/dialogs/BaseDialog.vue';
import AlertError from '@/components/AlertError.vue';

interface SelectableUpload {
    selected: boolean;
    upload: Upload;
}

interface CategorizedSelectableUploads {
    category: string;
    selectableUploads: SelectableUpload[];
    subcategories?: CategorizedSelectableUploads[];
}

@Component({
    components: {
        AlertError,
        BaseDialog,
        AddUploadsDialog,
        TutorialsCollapsible,
        UploadCard,
        Loading,
        InputButton,
        InputCheckbox,
    },
})
export default class ProjectGroupDetailsDownloads extends Vue {

    private role = ROLE.GUEST;
    private ready = false;

    private categories: UploadCategory[] = [];
    private uploads: Upload[] = [];

    private uploader!: Uploader;

    private uploadDialogVisible = false;
    private supportedUploadEndings = ['mp4', 'pdf', 'docx', 'txt', 'png', 'jpg', 'jpeg'];

    private categorizedUploads: CategorizedSelectableUploads[] = [];
    private generatingZip = false;
    private deleting = false;
    private deleteDialogVisible = false;
    private failedToDelete = 0;

    private get isAtLeastPlanner(): boolean {
        return this.role === ROLE.ADMINISTRATOR
            || this.role === ROLE.PLANNING;
    }

    private get selectedUploads(): Upload[] {
        let selected: Upload[] = [];
        this.categorizedUploads.forEach((cu) => {
            selected = selected.concat(this.getSelectedUploadsFromCategorized(cu));
        });
        return selected;
    }

    private openFileUpload() {
        this.uploadDialogVisible = true;
    }

    private uploadFiles(uploads: Upload[]) {
        if (!this.uploadDialogVisible) return;

        Api.omb.addTutorialsUploads(uploads)
            .then((newUploads) => {
                this.uploads = this.uploads.concat(newUploads); // TODO: Add new uploads without selections resetting
                this.processUploads();
            });
    }

    private async loadUserData() {
        this.role = await getRole();
    }

    private getSelectedUploadsFromCategorized(catUploads: CategorizedSelectableUploads): Upload[] {
        let selected: Upload[] = [];
        selected = selected.concat(catUploads.selectableUploads.filter((cu) => cu.selected)
            .map((cu) => cu.upload));
        if (catUploads.subcategories) {
            catUploads.subcategories.forEach((sc) => selected = selected.concat(this.getSelectedUploadsFromCategorized(sc)));
        }
        return selected;
    }

    private async created() {
        await this.loadUserData();

        this.uploader = await Uploader.create();
        this.categories = await Api.omb.getAllTutorialCategories();
        this.uploads = await Api.omb.getAllTutorialUploads();
        await this.processUploads();
        this.ready = true;
    }

    private async processUploads() {
        const categoriesWithUploads: { catName: string; uploads: SelectableUpload[] }[] = [];
        this.uploads.forEach((upload) => {
            if (!upload.id) return;

            const category = this.categories.find((cat) => cat.id === upload.categoryId);
            if (!category || !category.name) return;

            const catUpload = categoriesWithUploads.find((cu) => cu.catName === category.name);
            if (catUpload) {
                categoriesWithUploads[categoriesWithUploads.indexOf(catUpload)].uploads.push({
                    selected: false,
                    upload: upload,
                });
            } else {
                categoriesWithUploads.push({
                    catName: category.name,
                    uploads: [{
                        selected: false,
                        upload: upload,
                    }],
                });
            }
        });

        const catUploads: CategorizedSelectableUploads[] = [];
        categoriesWithUploads.forEach((cu) => {
            catUploads.push(this.categorize(cu.catName, cu.uploads));
        });
        this.categorizedUploads = this.mergeCategorizedUploads(catUploads)
            .sort((a, b) => (a.category.toLowerCase() < b.category.toLowerCase() ? -1 : 1));
    }

    private categorize(name: string, uploads: SelectableUpload[]): CategorizedSelectableUploads {
        const splits = name.split('|');
        if (splits.length > 1) {
            return {
                category: splits[0],
                selectableUploads: [],
                subcategories: [this.categorize(splits.splice(1)
                    .join('|'), uploads)],
            };
        }

        return {
            category: name,
            selectableUploads: uploads,
        };
    }

    private mergeCategorizedUploads(catUploads: CategorizedSelectableUploads[]): CategorizedSelectableUploads[] {
        const merged: CategorizedSelectableUploads[] = [];
        catUploads.forEach((cu) => {
            const alreadyMerged = merged.find((m) => m.category === cu.category);
            if (alreadyMerged) {
                const i = merged.indexOf(alreadyMerged);
                merged[i].subcategories = merged[i].subcategories || [];
                merged[i].selectableUploads = merged[i].selectableUploads.concat(cu.selectableUploads)
                    .sort(this.sortSelectableUploads);
                if (cu.subcategories) merged[i].subcategories = merged[i].subcategories!.concat(cu.subcategories);
                merged[i].subcategories = this.mergeCategorizedUploads(merged[i].subcategories!.sort((a, b) => (a.category.toLowerCase() < b.category.toLowerCase() ? -1 : 1)));
            } else {
                merged.push({
                    category: cu.category,
                    selectableUploads: cu.selectableUploads.sort(this.sortSelectableUploads),
                    subcategories: cu.subcategories,
                });
            }
        });

        return merged;
    }

    private sortSelectableUploads(a: SelectableUpload, b: SelectableUpload): number {
        return (a.upload.name || '').toLowerCase() < (b.upload.name || '').toLowerCase() ? -1 : 1;
    }

    private downloadSelected() {
        this.generatingZip = true;
        this.uploader.downloadMultiple(this.selectedUploads);
        this.unselectAllDownloads();
        this.generatingZip = false;
    }

    private showDeleteDialog() {
        if (this.selectedUploads.length) {
            this.deleteDialogVisible = true;
        }
    }

    private hideDeleteDialog() {
        this.deleteDialogVisible = false;
        this.failedToDelete = 0;
    }

    private deleteSelected() {
        this.deleting = true;
        this.failedToDelete = 0;

        const deleted: string[] = [];

        const promises: Promise<void>[] = [];
        this.selectedUploads.forEach((u) => {
            promises.push(
                Api.omb.deleteTutorialEntry(u.id || '')
                    .then(() => {
                        deleted.push(u.id || '');
                    })
                    .catch(() => {
                        this.failedToDelete += 1;
                    }),
            );
        });

        Promise.all(promises)
            .then(this.hideDeleteDialog)
            .finally(() => {
                this.deleting = false;
                this.uploads = this.uploads.filter((u) => !deleted.includes(u.id || ''));
                this.categorizedUploads = this.categorizedUploads
                    .map((cu) => this.catUploadsDeletedMapFunc(cu, deleted))
                    .filter(this.catUploadsRemoveDeletedFilter);
            });
    }

    // Used to remove uploads, that have been deleted
    private catUploadsDeletedMapFunc(cu: CategorizedSelectableUploads, deleted: string[]): CategorizedSelectableUploads {
        cu.selectableUploads = cu.selectableUploads.filter((su) => !deleted.includes(su.upload.id || ''));
        if (cu.subcategories) {
            cu.subcategories = cu.subcategories.map((sc) => this.catUploadsDeletedMapFunc(sc, deleted));
        }

        return cu;
    }

    // Used to remove categories without uploads
    private catUploadsRemoveDeletedFilter(cu: CategorizedSelectableUploads): boolean {
        if (cu.selectableUploads.length) return true;

        if (cu.subcategories && cu.subcategories.length) {
            cu.subcategories = cu.subcategories.filter(this.catUploadsRemoveDeletedFilter);
            if (!cu.subcategories.length) return false;

            for (let i = 0; i < cu.subcategories.length; i += 1) {
                const sc = cu.subcategories[i];
                if (this.catUploadsRemoveDeletedFilter(sc)) return true;
            }
        }

        return false;
    }

    private selectAllDownloads() {
        this.categorizedUploads.forEach(this.selectAllFromCategorized);
    }

    private selectAllFromCategorized(catUploads: CategorizedSelectableUploads): void {
        catUploads.selectableUploads.forEach((su) => su.selected = true);
        if (catUploads.subcategories) {
            catUploads.subcategories.forEach(this.selectAllFromCategorized);
        }
    }

    private unselectAllDownloads() {
        this.categorizedUploads.forEach(this.unselectAllFromCategorized);
    }

    private unselectAllFromCategorized(catUploads: CategorizedSelectableUploads): void {
        catUploads.selectableUploads.forEach((su) => su.selected = false);
        if (catUploads.subcategories) {
            catUploads.subcategories.forEach(this.unselectAllFromCategorized);
        }
    }

}
