

import { Component, Prop, Vue } from 'vue-property-decorator';
import clone from 'clone';

interface MultiSelectOption {
    label: string;
    value: string | number | boolean;
}

@Component({
    components: {},
})
export default class InputMultiSelect extends Vue {

    @Prop({ default: '' })
    private placeholder!: string;

    @Prop({ default: null })
    private value!: (string | number | boolean)[] | null;

    @Prop({ required: true })
    private options!: MultiSelectOption[];

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: false })
    private error!: boolean;

    @Prop()
    private errorMessage!: string;

    private val: any[] | null = null;
    private open = false;

    private get valueSnippet(): string {
        if (!this.val) return '';

        return this.options
            .filter((o) => this.val && this.val.includes(o.value))
            .map((o) => o.label)
            .join(', ');
    }

    private created() {
        this.val = clone(this.value);
    }

    private toggleOption(option: MultiSelectOption) {
        if (!this.val) this.val = [];
        if (this.val.includes(option.value)) {
            this.val = this.val.filter((v) => v !== option.value);
        } else {
            this.val.push(option.value);
        }
        this.emit();
    }

    private emit() {
        this.$emit('input', this.val);
    }

}
