
import {
    Component,
    Prop,
    Vue,
    Watch,
} from 'vue-property-decorator';
import Api from '@/assets/ts/Api';
import bytes from 'bytes';
import InputSelect from '@/components/input/InputSelect.vue';
import { Upload, UploadCategory } from '@/clients/omb-api';
import MobileDetect from 'mobile-detect';
import { UPLOAD_ERROR } from '@/assets/ts/enum/upload_error';
import Uploader from '@/assets/ts/uploader';

interface UploadEntry {
    tempId: string;
    tempName: string;
    tempCategoryId?: number;
    progress: number;
    errors: UPLOAD_ERROR[];
    upload?: Upload;
}

@Component({
    components: { InputSelect },
})
export default class UploadManager extends Vue {

    @Prop()
    private value!: Upload[];

    @Prop({ default: true })
    private categorySelect!: boolean;

    @Prop({ default: false })
    private categoryRequired!: boolean;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: undefined })
    private supportedEndings!: string[] | undefined;

    @Prop({ default: true })
    private multiple!: boolean;

    @Prop({ default: undefined })
    private uploadCategories!: UploadCategory[] | undefined;

    @Prop({ default: 'default' })
    private uploadType!: 'default' | 'assembler-list';

    @Prop()
    private uploadQuery?: Map<string, number | string>;

    private uploader!: Uploader;

    private ERROR = UPLOAD_ERROR;

    private sessionToken = '';
    private inputKey = 0;
    private isCameraDevice = true;

    private entries: UploadEntry[] = [];
    private categories: UploadCategory[] = [];

    @Watch('entries')
    private onEntriesChange() {
        this.emit();
    }

    private get categoriesOptions() {
        return this.categories
            .map((c) => ({
                label: c.name?.replaceAll('|', ' | '),
                value: c.id,
            }));
    }

    private get acceptedFileExtensionsSnippet(): string {
        return (this.supportedEndings || this.uploader.getSupportedEndings())
            .map((e) => `.${e.toLowerCase()}`)
            .join(',');
    }

    private get supportedFilesSnippet(): string {
        const endings = (this.supportedEndings || this.uploader.getSupportedEndings())
            .filter((e) => e.toLowerCase() !== 'jpeg')
            .map((e) => e.toUpperCase());
        return `(${endings.join(', ')}, max. ${bytes(this.uploader.getUploadSizeLimit(), {
            decimalPlaces: 0,
        })})`;
    }

    private async created() {
        this.uploader = await Uploader.create();
        this.detectDevice();
        this.sessionToken = Api.getToken();
        this.uploadsToEntries();
        if (this.uploadCategories) {
            this.categories = this.uploadCategories;
        } else {
            Api.omb.getAllUploadCategories()
                .then((categories) => this.categories = categories);
        }
    }

    private detectDevice() {
        const mobileDetect = new MobileDetect(window.navigator.userAgent);
        this.isCameraDevice = !!mobileDetect.mobile();
    }

    private uploadsToEntries() {
        if (this.value) {
            this.entries = this.value.map((u) => ({
                tempId: '',
                tempName: '',
                tempCategoryId: u.categoryId || undefined,
                progress: 1,
                errors: [],
                name: u.name || '',
                upload: u,
            }));
        }
    }

    private onFileChange(event: Event) {
        const input = event.target as HTMLInputElement;
        const { files } = input;

        const supportedEndings = this.supportedEndings || this.uploader.getSupportedEndings();

        if (files) {
            for (let i = 0; i < files.length; i += 1) {
                const file = files.item(i);
                const ending = file?.name.split('.')
                    .pop()
                    ?.toLowerCase() || '';
                if (supportedEndings.includes(ending)) {
                    this.upload(files.item(i) as File);
                }
            }
        }
    }

    private upload(file: File) {
        const tempId = `${Math.random()}`.replace('.', '');
        if (!this.multiple) this.entries = [];
        this.emit();

        const errors = this.uploader.checkFile(file);
        if (errors.length) {
            this.entries.push({
                tempId: tempId,
                tempName: file.name,
                progress: 1,
                errors: errors,
            });
        } else {
            const entry: UploadEntry = {
                tempId: tempId,
                tempName: file.name,
                progress: 0,
                errors: [],
            };
            this.entries.push(entry);

            this.uploader.upload(file, (progress) => {
                entry.progress = progress;
            }, this.uploadQuery, this.uploadType)
                .then((response) => {
                    entry.upload = JSON.parse(response.data as string);
                    this.emit();
                })
                .catch((error) => {
                    console.error(error);
                    entry.errors = [UPLOAD_ERROR.OTHER];
                });
        }
    }

    private deleteEntry(entry: UploadEntry) {
        this.entries = this.entries.filter((e) => JSON.stringify(e) !== JSON.stringify(entry));
    }

    private onCategoryChange() {
        this.emit();
    }

    private emit() {
        this.entries.forEach((e) => {
            if (e.upload) e.upload.categoryId = e.tempCategoryId;
        });
        const uploads = this.entries
            .filter((e) => e.upload && !e.errors.length)
            .map((e) => e.upload);
        this.$emit('input', uploads);
        this.inputKey += 1;
    }

}
