

import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { ProjectGroup } from '@/clients/omb-api';
import InputButton from '@/components/input/InputButton.vue';
import AlertError from '@/components/AlertError.vue';
import Api from '@/assets/ts/Api';
import BaseDialog from './BaseDialog.vue';

@Component({
    components: {
        AlertError,
        InputButton,
        BaseDialog,
    },
})
export default class EditProjectGroupDialog extends Vue {

    @Prop({ required: true })
    private projectGroup!: ProjectGroup;

    private error: boolean = false;
    private deleting = false;

    private close() {
        this.$emit('close');
    }

    private async deleteProjectGroup() {
        try {
            this.deleting = true;
            await Api.omb.deleteProjectGroup(this.projectGroup.slug || '');
            this.$emit('delete');
        } catch (e) {
            this.error = true;
        }
    }

    private reloadPage() {
        this.$router.go(0);
    }

}
