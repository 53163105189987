/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WeeklyReportLine,
    WeeklyReportLineFromJSON,
    WeeklyReportLineFromJSONTyped,
    WeeklyReportLineToJSON,
} from './';

/**
 * 
 * @export
 * @interface WeeklyReport
 */
export interface WeeklyReport {
    /**
     * 
     * @type {number}
     * @memberof WeeklyReport
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReport
     */
    projectGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReport
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReport
     */
    week?: string;
    /**
     * 
     * @type {Date}
     * @memberof WeeklyReport
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WeeklyReport
     */
    end?: Date;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReport
     */
    department?: string;
    /**
     * 
     * @type {Array<WeeklyReportLine>}
     * @memberof WeeklyReport
     */
    lines?: Array<WeeklyReportLine>;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReport
     */
    foreman?: string;
    /**
     * 
     * @type {string}
     * @memberof WeeklyReport
     */
    fileId?: string;
    /**
     * 
     * @type {number}
     * @memberof WeeklyReport
     */
    createdBy?: number;
}

export function WeeklyReportFromJSON(json: any): WeeklyReport {
    return WeeklyReportFromJSONTyped(json, false);
}

export function WeeklyReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeeklyReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectGroupId': !exists(json, 'projectGroupId') ? undefined : json['projectGroupId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'week': !exists(json, 'week') ? undefined : json['week'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'department': !exists(json, 'department') ? undefined : json['department'],
        'lines': !exists(json, 'lines') ? undefined : ((json['lines'] as Array<any>).map(WeeklyReportLineFromJSON)),
        'foreman': !exists(json, 'foreman') ? undefined : json['foreman'],
        'fileId': !exists(json, 'fileId') ? undefined : json['fileId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
    };
}

export function WeeklyReportToJSON(value?: WeeklyReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectGroupId': value.projectGroupId,
        'name': value.name,
        'week': value.week,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'department': value.department,
        'lines': value.lines === undefined ? undefined : ((value.lines as Array<any>).map(WeeklyReportLineToJSON)),
        'foreman': value.foreman,
        'fileId': value.fileId,
        'createdBy': value.createdBy,
    };
}


