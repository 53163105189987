

import { Component, Prop, Vue } from 'vue-property-decorator';
import { Upload } from '@/clients/omb-api';
import Uploader from '@/assets/ts/uploader';

@Component({
    components: {},
})
export default class SimpleUploadDownloader extends Vue {

    @Prop({ default: [] })
    private uploads!: Upload[];

    @Prop({ default: true })
    private showTitle!: boolean;

    private uploader!: Uploader;

    private async created() {
        this.uploader = await Uploader.create();
    }

    private download(upload: Upload) {
        this.uploader.download(upload.id || '', upload.type || '', upload.name || '');
    }

}
