

import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import Api from '@/assets/ts/Api';
import { Project, ProjectGroup, Repair } from '@/clients/omb-api';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import getRole from '@/plugins/roleHandler';
import { ROLE } from '@/assets/ts/enum/role';
import EditRepairDialog from '@/components/dialogs/EditRepairDialog.vue';
import RepairsTable from '@/views/repairs/RepairsTable.vue';
import AlertError from '@/components/AlertError.vue';
import InputButton from '@/components/input/InputButton.vue';
import ProjectGroupsTable from '@/views/projectgroups/ProjectGroupsTable.vue';
import ProjectGroupsAndRepairsMap from '@/components/ProjectGroupsAndRepairsMap.vue';

@Component({
    components: {
        ProjectGroupsAndRepairsMap,
        ProjectGroupsTable,
        InputButton,
        AlertError,
        RepairsTable,
        EditRepairDialog,
        Loading,
    },
})
export default class RepairsAndProjectsNear extends Vue {

    private ROLE = ROLE;
    private role = ROLE.GUEST;

    private baseRepair: Repair | null = null;
    private baseProjectGroup: ProjectGroup | null = null;

    private repairs: Repair[] = [];
    private projectGroups: ProjectGroup[] = [];

    private allProjectGroups: ProjectGroup[] = [];
    private allProjects: Project[] = [];

    private title = '';
    private currentWeek = 0;
    private error = false;
    private loading = true;
    private mapView = false;

    @Watch('$route.query')
    private onQueryChange() {
        this.loadRepairsAndProjectGroups(true);
    }

    private get currentLocation(): { lat: number; lng: number; } | undefined {
        const base = this.baseProjectGroup || this.baseRepair;
        if (!base || !base.lat || !base.lng) return undefined;

        return {
            lat: base.lat,
            lng: base.lng,
        };
    }

    private get distanceFromLat(): number {
        if (this.baseProjectGroup) return this.baseProjectGroup.lat || 0;
        if (this.baseRepair) return this.baseRepair.lat || 0;
        return 0;
    }

    private get distanceFromLng(): number {
        if (this.baseProjectGroup) return this.baseProjectGroup.lng || 0;
        if (this.baseRepair) return this.baseRepair.lng || 0;
        return 0;
    }

    private async created() {
        await this.init();
    }

    private async init() {
        this.loading = true;

        if (this.$route.meta?.label) this.title = this.$route.meta.label;

        this.currentWeek = moment()
            .isoWeek();
        await this.loadUserData();
        await this.loadAllProjectGroupsAndProjects();
        await this.loadRepairsAndProjectGroups();

        this.loading = false;
    }

    private async loadUserData() {
        this.role = await getRole();
    }

    private async loadAllProjectGroupsAndProjects() {
        this.allProjectGroups = await Api.omb.getAllProjectGroups();
        this.allProjects = await Api.omb.projectGet();
    }

    private async loadRepairsAndProjectGroups(handleLoading = false) {
        if (handleLoading) {
            this.loading = true;
        }

        try {
            this.baseRepair = null;
            this.baseProjectGroup = null;

            let lat = 0;
            let lng = 0;

            const repairId = parseInt(`${this.$route.query.repair}`, 10);
            const projectGroupSlug = `${this.$route.query.projectGroup}`;

            if (repairId) {
                this.baseRepair = await Api.omb.getRepair(repairId);
                lat = this.baseRepair.lat || 0;
                lng = this.baseRepair.lng || 0;
            } else if (projectGroupSlug) {
                this.baseProjectGroup = await Api.omb.getProjectGroupBySlug(projectGroupSlug);
                lat = this.baseProjectGroup.lat || 0;
                lng = this.baseProjectGroup.lng || 0;
            }

            if (!lat || !lng) {
                this.$router.push({ name: availableRoutes.features });
            }

            let projectGroups = await Api.omb.getProjectGroupsNear(lat, lng);
            projectGroups = projectGroups.filter((pg) => pg.slug !== projectGroupSlug);
            projectGroups = projectGroups.filter((pg) => !!this.allProjects.find((p) => {
                const belongsToProjectGroup = p.projectGroupId === pg.id;
                const readyForAssembly = p.completed;
                const isAssembly = p.departments && p.departments.toLowerCase()
                    .includes('montage');
                const assemblyComplete = p.departments && p.departments.toLowerCase()
                    .includes('montage') && p.assemblyComplete;
                const eassemblyComplete = p.departments && (!p.departments.toLowerCase()
                    .includes('e-montage' || p.operating));
                return belongsToProjectGroup && readyForAssembly && isAssembly && (!assemblyComplete || !eassemblyComplete);
            }));
            this.projectGroups = projectGroups.sort(this.sortByDistanceToBase);
            this.repairs = (await Api.omb.getRepairsNear(lat, lng)).filter((r) => r.id !== repairId)
                .sort(this.sortByDistanceToBase);
        } catch (e: any) {
            if (e.status === 401) {
                Api.initServices('');
                this.$router.push({ name: availableRoutes.login });
            } else {
                this.error = true;
            }
        } finally {
            if (handleLoading) {
                this.loading = false;
            }
        }
    }

    private sortByDistanceToBase(a: ProjectGroup | Repair, b: ProjectGroup | Repair): number {
        const base: ProjectGroup | Repair | null = this.baseProjectGroup || this.baseRepair;
        if (!base) return 0;

        base.lat = base.lat || 0;
        base.lng = base.lng || 0;
        a.lat = a.lat || 0;
        a.lng = a.lng || 0;
        b.lat = b.lat || 0;
        b.lng = b.lng || 0;

        const distanceA = Math.sqrt((a.lng - base.lng) ** 2 + (a.lat - base.lat) ** 2);
        const distanceB = Math.sqrt((b.lng - base.lng) ** 2 + (b.lat - base.lat) ** 2);

        return distanceA > distanceB ? 1 : -1;
    }

}
