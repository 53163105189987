

import { Component, Prop, Vue } from 'vue-property-decorator';

interface PagerEntry {
    value?: number;
    label: string | number;
}

@Component({
    components: {},
})
export default class InputPage extends Vue {

    @Prop({ default: 0 })
    private value!: number;

    @Prop({ default: 0 })
    private min!: number;

    @Prop({ default: 0 })
    private max!: number;

    private page = 0;

    private get pageEntries(): PagerEntry[] {
        const entries: PagerEntry[] = [];

        if (this.max <= 4) {
            for (let i = 0; i <= this.max; i += 1) {
                entries.push({
                    value: i,
                    label: i + 1,
                });
            }

            return entries;
        }

        if (this.page > 0) {
            entries.push({
                value: 0,
                label: 1,
            });

            if (this.page > 1) {
                entries.push({
                    label: '...',
                });
            }
        }

        entries.push({
            value: this.page,
            label: this.page + 1,
        });

        if (this.page < this.max - 1) {
            entries.push({
                label: '...',
            });
        }

        if (this.page < this.max) {
            entries.push({
                value: this.max,
                label: this.max + 1,
            });
        }

        return entries;
    }

    private created() {
        this.page = this.value;
    }

    private setPage(page?: number) {
        if (page === undefined || page < 0 || page > this.max) return;

        this.page = page;
        this.$emit('input', this.page);
    }

}
