

import { Component, Vue } from 'vue-property-decorator';
import Loading from '@/components/Loading.vue';
import availableRoutes from '@/plugins/availableRoutes';
import { ROLE } from '@/assets/ts/enum/role';
import moment from 'moment/moment';
import { ProjectGroup, User } from '@/clients/omb-api';
import Api from '@/assets/ts/Api';
import InputButton from '@/components/input/InputButton.vue';
import EditProjectGroupDialog from '@/components/dialogs/EditProjectGroupDialog.vue';
import Collapsable from '@/components/Collapsable.vue';
import InputCheckbox from '@/components/input/InputCheckbox.vue';
import ProjectGroupDetailsDocuments from '@/views/projectgroupdetails/ProjectGroupDetailsDocuments.vue';
import ProjectGroupDetailsTopInfo from '@/views/projectgroupdetails/ProjectGroupDetailsTopInfo.vue';
import ProjectGroupDetailsAssembly from '@/views/projectgroupdetails/ProjectGroupDetailsAssembly.vue';
import ProjectGroupDetailsProduction from '@/views/projectgroupdetails/ProjectGroupDetailsProduction.vue';
import ProjectGroupDetailsArchive from '@/views/projectgroupdetails/ProjectGroupDetailsArchive.vue';
import getRole from '@/plugins/roleHandler';
import EditProjectDialog from '@/components/dialogs/EditProjectDialog.vue';
import DeleteProjectGroupDialog from '@/components/dialogs/DeleteProjectGroupDialog.vue';
import ProjectGroupDetailsReports from '@/views/projectgroupdetails/ProjectGroupDetailsReports.vue';

@Component({
    components: {
        ProjectGroupDetailsReports,
        DeleteProjectGroupDialog,
        EditProjectDialog,
        ProjectGroupDetailsArchive,
        ProjectGroupDetailsProduction,
        ProjectGroupDetailsAssembly,
        ProjectGroupDetailsTopInfo,
        ProjectGroupDetailsDocuments,
        InputCheckbox,
        Collapsable,
        EditProjectGroupDialog,
        InputButton,
        Loading,
    },
})
export default class ProjectGroupDetails extends Vue {

    private ROLE = ROLE;
    private availableRoutes = availableRoutes;

    private loading: boolean = true;

    private projectGroups: ProjectGroup[] = [];
    private projectGroup: ProjectGroup | null = null;
    private currentUser: User | null = null;
    private role: ROLE = ROLE.GUEST;
    private currentWeek: number = 0;
    private deleteDialogVisible = false;
    private editDialogVisible = false;
    private newProjectDialogVisible = false;
    private tablesLoaded = false;

    private get isAtLeastPlanner(): boolean {
        return this.role === ROLE.ADMINISTRATOR || this.role === ROLE.PLANNING;
    }

    private get canReadWriteReports(): boolean {
        return this.isAtLeastPlanner || this.role === ROLE.ASSEMBLY || this.role === ROLE.EASSEMBLY;
    }

    private async created() {
        this.$root.$on('openNewProjectDialog', this.openNewProjectDialog);

        this.currentUser = await Api.omb.getCurrentUser();
        this.role = await getRole();
        this.loadData();
    }

    private destroyed() {
        this.$root.$off('openNewProjectDialog', this.openNewProjectDialog);
    }

    private async loadData() {
        this.loading = true;

        this.currentWeek = moment()
            .isoWeek();
        this.projectGroups = await Api.omb.getAllProjectGroups();
        await this.loadProjectGroup();

        this.loading = false;
    }

    private async loadProjectGroup() {
        try {
            this.projectGroup = await Api.omb.getProjectGroupBySlug(this.$route.params.slug);
        } catch (error) {
            console.error(error);
            this.$router.push({ name: availableRoutes.projectGroups });
        }
    }

    private showDeleteDialog() {
        this.deleteDialogVisible = true;
    }

    private hideDeleteDialog() {
        this.deleteDialogVisible = false;
    }

    private onDeleted() {
        this.$router.push({ name: availableRoutes.projectGroups });
    }

    private showEditDialog() {
        this.editDialogVisible = true;
    }

    private hideEditDialog() {
        this.editDialogVisible = false;
    }

    private onTableLoad() {
        this.tablesLoaded = true;
    }

    private openNewProjectDialog() {
        this.newProjectDialogVisible = true;
    }

    private showRepairsAndProjectsNear(projectGroup: ProjectGroup) {
        this.$router.push({
            name: availableRoutes.repairsAndProjectsNear,
            query: { projectGroup: `${projectGroup.slug}` },
        });
    }

}
