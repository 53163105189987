/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DailyWageReportLine
 */
export interface DailyWageReportLine {
    /**
     * 
     * @type {number}
     * @memberof DailyWageReportLine
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReportLine
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReportLine
     */
    monday?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReportLine
     */
    tuesday?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReportLine
     */
    wednesday?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReportLine
     */
    thursday?: number;
    /**
     * 
     * @type {number}
     * @memberof DailyWageReportLine
     */
    friday?: number;
    /**
     * 
     * @type {string}
     * @memberof DailyWageReportLine
     */
    description?: string;
}

export function DailyWageReportLineFromJSON(json: any): DailyWageReportLine {
    return DailyWageReportLineFromJSONTyped(json, false);
}

export function DailyWageReportLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyWageReportLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'monday': !exists(json, 'monday') ? undefined : json['monday'],
        'tuesday': !exists(json, 'tuesday') ? undefined : json['tuesday'],
        'wednesday': !exists(json, 'wednesday') ? undefined : json['wednesday'],
        'thursday': !exists(json, 'thursday') ? undefined : json['thursday'],
        'friday': !exists(json, 'friday') ? undefined : json['friday'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function DailyWageReportLineToJSON(value?: DailyWageReportLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'monday': value.monday,
        'tuesday': value.tuesday,
        'wednesday': value.wednesday,
        'thursday': value.thursday,
        'friday': value.friday,
        'description': value.description,
    };
}


