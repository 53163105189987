export default class LocationUtils {

    public static getDistance(fromLat: number, fromLng: number, toLat: number, toLng: number): number {
        const R = 6371e3; // metres
        const φ1 = fromLat * (Math.PI / 180); // φ, λ in radians
        const φ2 = toLat * (Math.PI / 180);
        const Δφ = (toLat - fromLat) * (Math.PI / 180);
        const Δλ = (toLng - fromLng) * (Math.PI / 180);

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return (R * c) / 1000;
    }

}
