

import { Component, Vue } from 'vue-property-decorator';
import InputButton from '@/components/input/InputButton.vue';

@Component({
    components: { InputButton },
})
export default class CookieMessage extends Vue {

    private consent() {
        localStorage.setItem('OMB_COOKIE_CONSENT', 'TRUE');
        this.$emit('consented');
    }
}
