

import {
    Component,
    Prop,
    Vue,
    Watch,
} from 'vue-property-decorator';

@Component({
    components: {},
})
export default class InputCheckbox extends Vue {

    @Prop({ default: false })
    private value!: boolean;

    @Prop({ default: false })
    private disabled!: boolean;

    @Prop({ default: 'primary' })
    private color!: string;

    private val = false;
    private initialized = false;

    @Watch('value')
    private onValueChange() {
        this.initialized = false;
        this.val = this.value;
        this.$nextTick(() => this.initialized = true);
    }

    @Watch('val')
    private onValChange() {
        if (this.initialized) {
            this.$emit('input', this.val);
        }
    }

    private created() {
        this.val = this.value;
        this.$nextTick(() => this.initialized = true);
    }

}
