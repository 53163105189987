/* tslint:disable */
/* eslint-disable */
/**
 * OMB Api
 * This file contains the information needed to generate all Api calls for the project.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    projectGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    beginProduction?: string;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    durationProduction?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    beginInstallation?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    beginInstallationNew?: string;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    durationInstallation?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    categories?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    departments?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    completed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    colour?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    comment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    noMaterial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    purchasedMaterial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    haveMaterial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    toCoater?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    fromCoater?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    storagePlace?: string;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    number?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    cut?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    connectionsComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    glasComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    assemblyComplete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    operating?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Project
     */
    archivedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    planned?: boolean;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectGroupId': !exists(json, 'projectGroupId') ? undefined : json['projectGroupId'],
        'beginProduction': !exists(json, 'beginProduction') ? undefined : json['beginProduction'],
        'durationProduction': !exists(json, 'durationProduction') ? undefined : json['durationProduction'],
        'beginInstallation': !exists(json, 'beginInstallation') ? undefined : json['beginInstallation'],
        'beginInstallationNew': !exists(json, 'beginInstallationNew') ? undefined : json['beginInstallationNew'],
        'durationInstallation': !exists(json, 'durationInstallation') ? undefined : json['durationInstallation'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'departments': !exists(json, 'departments') ? undefined : json['departments'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'colour': !exists(json, 'colour') ? undefined : json['colour'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'noMaterial': !exists(json, 'noMaterial') ? undefined : json['noMaterial'],
        'purchasedMaterial': !exists(json, 'purchasedMaterial') ? undefined : json['purchasedMaterial'],
        'haveMaterial': !exists(json, 'haveMaterial') ? undefined : json['haveMaterial'],
        'toCoater': !exists(json, 'toCoater') ? undefined : json['toCoater'],
        'fromCoater': !exists(json, 'fromCoater') ? undefined : json['fromCoater'],
        'storagePlace': !exists(json, 'storagePlace') ? undefined : json['storagePlace'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'cut': !exists(json, 'cut') ? undefined : json['cut'],
        'connectionsComplete': !exists(json, 'connections_complete') ? undefined : json['connections_complete'],
        'glasComplete': !exists(json, 'glas_complete') ? undefined : json['glas_complete'],
        'assemblyComplete': !exists(json, 'assembly_complete') ? undefined : json['assembly_complete'],
        'operating': !exists(json, 'operating') ? undefined : json['operating'],
        'archivedAt': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'planned': !exists(json, 'planned') ? undefined : json['planned'],
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectGroupId': value.projectGroupId,
        'beginProduction': value.beginProduction,
        'durationProduction': value.durationProduction,
        'beginInstallation': value.beginInstallation,
        'beginInstallationNew': value.beginInstallationNew,
        'durationInstallation': value.durationInstallation,
        'scope': value.scope,
        'categories': value.categories,
        'departments': value.departments,
        'completed': value.completed,
        'colour': value.colour,
        'comment': value.comment,
        'noMaterial': value.noMaterial,
        'purchasedMaterial': value.purchasedMaterial,
        'haveMaterial': value.haveMaterial,
        'toCoater': value.toCoater,
        'fromCoater': value.fromCoater,
        'storagePlace': value.storagePlace,
        'id': value.id,
        'number': value.number,
        'cut': value.cut,
        'connections_complete': value.connectionsComplete,
        'glas_complete': value.glasComplete,
        'assembly_complete': value.assemblyComplete,
        'operating': value.operating,
        'archived_at': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'planned': value.planned,
    };
}


